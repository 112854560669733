import React, { useRef } from 'react';
import './uploadimg.scss';
import { converterBiteTo } from '@shared';

const UploadImage = ({
  wasUploaded = () => {},
  children = () => {},
  maxSize = 20000000, // 20mb
  errorCallback = () => {},
  accept = 'image/*',
  multiple = false,
  className = ''
}) => {
  const fileInput = useRef(null);

  const classes = ["user-project__img-wrapper", ...className.split(" ")];

  const uploadImage = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);

    if (filesArray.length) {
      for (const iterator of filesArray) {
        if (iterator.size > maxSize) {
          const mB = converterBiteTo(maxSize);
          return errorCallback({ uploadImage: `Maximum file size - ${mB}` });
        }
      }

      wasUploaded(files);
    }
  };

  return (
    <div
      onClick={() => fileInput.current.click()}
      className={classes.join(" ")}
    >
      <input
        type="file"
        accept={accept}
        ref={fileInput}
        multiple={multiple}
        onChange={(event) => uploadImage(event)}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      {children}
    </div>
  );
};

export default UploadImage;
