import {
  SET_EXECUTORS,
  SET_CHAT_MESSAGE,
  SET_PROJECT_COST,
  SET_PROJECT_IMAGE,
  SET_CHAT_MESSAGES,
  SET_CURRENT_PROJECT,
  SET_PROJECT_EXECUTORS,
  SET_PROJECT_PROPERTIE,
  SET_IS_PROJECT_CHANGED,
  SET_CURRENT_STEPS_HOURS,
  SET_IS_UNSAVED_PROJECT_SHOW,
  SET_PRODUCT_TO_PROJECT,
} from './project.constants';
import { convertObjectValue } from '@lib/utils';
import projectService from '../api';
import {
  Bot,
  Snackbar,
  setAlertAC,
  projectInstance,
  removeProjectFromListAC,
  setProjectToProjectsListAC,
} from '@entities';

export const getUserProjectTC = (projectId) => {
  return async (dispatch, getState) => {
    const isFake = typeof projectId !== 'number' && projectId?.includes('-');

    try {
      if (isFake) {
        const emptyProject = projectInstance.create();
        const product = getState().projectData.productToProject;
        const bot = new Bot({product: product});
        const messages = bot.getAllMessages();
        dispatch(setChatMessagesAC(messages));
        return dispatch(setCurrentProjectAC(emptyProject));
      }

      const data = await projectService.getUserProject(projectId);

      if (!data) {
        return;
      }

      const { userData } = getState();

      let modifiedProject = {};
      for (const [key, value] of Object.entries(data.data)) {
        modifiedProject[key] = convertObjectValue(value);
      }

      modifiedProject['user'] = userData.user;
      const newProject = projectInstance.create(modifiedProject, 'userProject');

      dispatch(setCurrentProjectAC(newProject));
      dispatch(setProjectCostAC());
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const getAdminProjectTC = (projectId) => {
  return async (dispatch) => {
    try {
      const data = await projectService.getAdminProject(projectId);

      if (!data) {
        return;
      }

      let modifiedProject = {};
      for (const [key, value] of Object.entries(data.data)) {
        modifiedProject[key] = convertObjectValue(value);
      }

      const newProject = projectInstance.create(
        modifiedProject,
        'adminProject'
      );

      dispatch(setCurrentProjectAC(newProject));
      //dispatch(setisProjectChangeAC(false));
      dispatch(setProjectCostAC());
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const saveAdminProjectTC = (project) => {
  return async (dispatch, getState) => {
    try {
      delete project['price'];
      delete project['sketchImage'];

      const state = getState().projectData; // for currentProjectImage
      const newRender = state.currentProjectImage;
      const formData = new FormData();
      const objectKeys = Object.keys(project);

      objectKeys.forEach((key) => {
        formData.append(key, project[key]);
      });

      formData.set('filesInstance', null);
      formData.set('archive', project['archive']);
      formData.set('rate', project['rate'].value);
      formData.set('rate_type', project['rate'].type);
      formData.set('user', JSON.stringify(project['user']));
      formData.set('executors', JSON.stringify(project['executors']));
      formData.set('followingData', JSON.stringify(project['followingData']));
      formData.set(
        'currentStepsHours',
        JSON.stringify(project['currentStepsHours'])
      );

      if (typeof newRender === 'object' && newRender?.size) {
        formData.set('renderImage', newRender);
      }

      const data = await projectService.updateAdminProject(
        project.id,
        formData
      );

      if (!data.data) {
        return;
      }

      if (data.status === 200) {
        project.notificationsToUser = JSON.parse(project.notificationsToUser);

        //request only ids
        const executors = project?.executors || [];
        if (executors.length) {
          project.executors = executors.map(
            (executor) => state.executors[executor]
          );
        }

        const responseData = data.data;
        project.renderImage =
          responseData.renderImage !== 'null' ? responseData.renderImage : null;

        const snackbar = new Snackbar({
          status: 200,
          message: (
            <div className="">
              <h5 className="snack__header">
                Project was updated successfully!
              </h5>
            </div>
          ),
        });

        dispatch(setAlertAC(snackbar));
        dispatch(setProjectToProjectsListAC(project));
        dispatch(setisProjectChangeAC(false));
        dispatch(setProjectCostAC());
      }
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const updateProjectPropertiesTC = () => {
  return async (dispatch, getState) => {
    const { projectData } = getState();
    const { currentProject } = projectData;

    const project = {
      id: currentProject.id,
      followingId: currentProject['followingId'],
      workingHoursSum: currentProject['workingHoursSum'],
      followingData: JSON.stringify(currentProject['followingData']),
      currentStepsHours: JSON.stringify(currentProject['currentStepsHours']),
    };

    await projectService.updateProjectProperties(project);

    const snackbar = new Snackbar({
      status: 200,
      message: (
        <div className="">
          <h5 className="snack__header">Property was published successfully</h5>
        </div>
      ),
    });

    dispatch(setAlertAC(snackbar));
    try {
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const updateAdminProjectPropertyTC = (property) => {
  return async (dispatch, getState) => {
    const { projectData } = getState();
    const { currentProject } = projectData;
    const { name, value, alertText, callback } = property;

    if (!currentProject.id) {
      return;
    }

    try {
      await projectService.updateAdminProjectProperty(
        currentProject.id,
        property
      );

      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            <h5 className="snack__header">
              {alertText ? alertText : `${name} was published successfully`}
            </h5>
          </div>
        ),
      });

      if (name !== 'executorContent') {
        dispatch(setAlertAC(snackbar));
      }

      switch (name) {
        case 'notificationsToUser':
          dispatch(updateNotificationTC(value));
          break;

        case 'workingHoursSum':
          dispatch(updateWorkingHoursSumTC(value));
          break;

        case 'followingData':
          dispatch(updateFollowingDataTC(value));
          break;

          case 'archive':
            dispatch(updateArchiveTC(value));
            break;

        default: {
        }
      }

      if (callback) {
        callback();
      }
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const updateAdminProjectBinaryPropertyTC = (property) => {
  return async (dispatch, getState) => {
    const { projectData } = getState();
    const { currentProject } = projectData;
    const { name, value, callback, alertText } = property;

    const formData = new FormData();
    try {
      formData.set(name, value);
      formData.set('dir', currentProject.dir);

      const response = await projectService.updateAdminBinaryProjectProperty(
        currentProject.id,
        formData
      );

      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            {alertText ? alertText : `${name} was published successfully`}
          </div>
        ),
      });

      dispatch(setAlertAC(snackbar));

      const archiveName = response.data.archive;

      switch (name) {
        case 'archive':
          dispatch(updateArchiveTC(archiveName));
          break;

        default:
          break;
      }

      if (callback) {
        callback();
      }
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const saveUserProjectTC = (project) => {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      const objectKeys = Object.keys(project);

      objectKeys.forEach((key) => {
        formData.append(key, project[key]);
      });

      formData.set('rate', project['rate'].value);
      formData.set('rate_type', project['rate'].type);
      formData.set('followingData', JSON.stringify(project['followingData']));
      formData.set('executors', JSON.stringify(project['executors']));
      formData.set('executorContent', JSON.stringify(project['executorContent']));
      formData.set(
        'currentStepsHours',
        JSON.stringify(project['currentStepsHours'])
      );
      formData.set(
        'notificationsToUser',
        JSON.stringify(project['notificationsToUser'])
      );
      formData.set('user', JSON.stringify(project['user']));

      formData.delete('id');
      formData.delete('price');
      formData.delete('defaultExecutors');

      let data;

      try {

        data = await projectService.createProject(formData);

      } catch (e) {
        const snackbar = new Snackbar({
          status: 500,
          duration: 7000,
          message: (
            <div className="">
              <h5 className="snack__header">
                {e.response.data?.message}
              </h5>
              <div className="snack__text">
                {e.response.data?.errors.join('\\n')}
              </div>
            </div>
          ),
        });
        return dispatch(setAlertAC(snackbar));
      }

      
      const response = data?.data;

      if (response) {
        const userProject = projectInstance.create(response, 'userProject');
        
        // Тут из-за сокетов уже перезагружается весь список
        // const { id } = userProject;

        // dispatch(setNewProjectsClearEmptyAC(userProject));
        // dispatch(getUserProjectTC(id));
        // dispatch(setSelectedProjectAC(id));

        const snackbar = new Snackbar({
          status: 200,
          message: (
            <div className="">
              <h5 className="snack__header">
                Project was created successfully!
              </h5>
            </div>
          ),
        });

        dispatch(setAlertAC(snackbar));
        return resolve(userProject);
      }

      reject(data);
    });
  };
};

export const updateNotificationTC = (notificationsArrayString) => {
  return async (dispatch, getState) => {
    try {
      const { currentProject } = getState().projectData;
      const notificationsArray = JSON.parse(notificationsArrayString);

      currentProject.notificationsToUser = notificationsArray;
      dispatch(setProjectToProjectsListAC(currentProject));

      dispatch(
        setProjectPropertieAC({
          key: 'notificationsToUser',
          value: notificationsArray,
        })
      );
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const updateWorkingHoursSumTC = (value) => {
  return (dispatch) => {
    dispatch(
      setProjectPropertieAC({
        key: 'workingHoursSum',
        value,
      })
    );

    dispatch(setProjectCostAC());
  };
};

export const updateFollowingDataTC = (value) => {
  return (dispatch) => {
    const followingData = JSON.parse(value);

    dispatch(
      setProjectPropertieAC({
        key: 'followingData',
        value: followingData,
      })
    );
  };
};

export const updateArchiveTC = (value) => {
  return (dispatch) => {
    dispatch(
      setProjectPropertieAC({
        key: 'archive',
        value,
      })
    );
  };
};

export const removeNotificationTC = (projectId) => {
  return async (dispatch, getState) => {
    try {
      await projectService.updateAdminProjectProperty(projectId, {
        name: 'notificationsToUser',
        value: JSON.stringify([]),
      });

      const { currentProject } = getState().projectData;

      currentProject.notificationsToUser = [];
      dispatch(setProjectToProjectsListAC(currentProject));

      dispatch(
        setProjectPropertieAC({
          key: 'notificationsToUser',
          value: [],
        })
      );
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const uploadBinaryFileTC = (options) => {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await projectService.uploadBinaryArchive(options);

        const body = response?.data?.Body;
  
        const blob = new Blob([new Uint8Array(body.data)], {
          type: 'application/octet-stream',
        });

        const file = new File([blob], options.name, { type: '' });
  
        resolve(file);
      } catch(error) {
        const snackbar = new Snackbar({
          status: 500,
          message: (
            <div className="">
              <h5 className="snack__header">
                Failed to download file!
              </h5>
            </div>
          ),
        });

        dispatch(setAlertAC(snackbar));
        console.log('[Error]', error);
      }
    });
   
  }
}

export const removeUserProjectTC = (property, callback) => {
  return async (dispatch) => {
    try {
      await projectService.removeUserProject(property.projectId, property);

      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            <h5 className="snack__header">Project was removed successfully!</h5>
          </div>
        ),
      });

      dispatch(setAlertAC(snackbar));
      dispatch(removeProjectFromListAC(property.projectId));

      if (callback) {
        callback();
      }
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const setCurrentProjectAC = (data) => {
  return {
    type: SET_CURRENT_PROJECT,
    payload: data,
  };
};

export const setChatMessagesAC = (data) => {
  return {
    type: SET_CHAT_MESSAGES,
    payload: data,
  };
};

export const setChatMessageAC = (data) => {
  return {
    type: SET_CHAT_MESSAGE,
    payload: data,
  };
};

export const setExecutorsAC = (payload) => {
  return {
    type: SET_EXECUTORS,
    payload,
  };
};

export const setProjectExecutorsAC = (payload) => {
  return {
    type: SET_PROJECT_EXECUTORS,
    payload,
  };
};

export const setProjectPropertieAC = (propertyValue) => {
  return {
    type: SET_PROJECT_PROPERTIE,
    payload: propertyValue,
  };
};

export const setCurrentStepsHoursAC = (object) => {
  return {
    type: SET_CURRENT_STEPS_HOURS,
    payload: object,
  };
};

export const setCurrentProjectImageAC = (file) => {
  return {
    type: SET_PROJECT_IMAGE,
    payload: file,
  };
};

export const setProjectCostAC = () => {
  return {
    type: SET_PROJECT_COST,
  };
};

export const setisProjectChangeAC = (payload = true) => {
  return {
    type: SET_IS_PROJECT_CHANGED,
    payload,
  };
};

export const setIsUnsavedProjectShowAC = (payload) => {
  return {
    type: SET_IS_UNSAVED_PROJECT_SHOW,
    payload,
  };
};

export const setProductToProjectAC = (object) => {
  return {
    type: SET_PRODUCT_TO_PROJECT,
    payload: object,
  };
};
