import React from 'react';

const RadioItem = (props) => {
  const { 
    value, 
    title, 
    isActive = false,
    isDisabled = false,
    checkItem 
  } = props;

  const classesContainer = ['radio__container', 'd-flex', 'ai-center'];

  if (isDisabled) {
    classesContainer.push('disabled');
  }

  if (isActive) {
    classesContainer.push('active');
  }

  return (
    <section
      className={classesContainer.join(' ')}
      onClick={() => checkItem(value)}
    >
      <div className="radio mr-12 d-flex ai-center jc-center">
        <div className="radio__mark"></div>
      </div>
     <div className='radio__content'>{title}</div>
    </section>
  );
};

export default RadioItem;

/*  {/*  <input 
      type="radio"
      name={name}
      id={value}
      checked={isActive}
      onChange={()=>checkItem(value)}
    />
    <label htmlFor={value}>{title}</label>  */
