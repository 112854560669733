import React from 'react';
import './cart-list.scss';
import { CartItem } from '@widgets';

const CartList = ({ 
  items = [],
  showBacket = true,
  removeProductFromCart = ()=>{},
  selectOneCartsItem = ()=>{}
}) => {
  return (
    <>
      {items.map((item, index) => (
        <CartItem
          product={item}
          showBacket={showBacket}
          key={`cart-item-${index}`}
          removeProduct={removeProductFromCart}
          onSelect={selectOneCartsItem}
        />
      ))}
    </>
  );
};

export default CartList;
