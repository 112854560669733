export const HEADER_BANNER_OPTIONS = [
    {
        title: 'Only 2 types of licenses'
    },
    {
        title: 'Understandable and accessible'
    },
    {
        title: 'Any suitable for business and private person'
    }
]