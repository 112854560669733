import { combineReducers } from 'redux';
import {
  userReducer,
  usersReducer,
  productsReducer,
  productReducer,
  projectsReducer,
  projectReducer,
  settingsReducer,
  notificationsReducer,
} from '@entities';

export default combineReducers({
  userData         : userReducer,
  usersData        : usersReducer,
  productsData     : productsReducer,
  productData      : productReducer,
  projectsData     : projectsReducer,
  projectData      : projectReducer,
  settingsData     : settingsReducer,
  notificationsData: notificationsReducer,
});
