import React from "react";
import './accordion.scss';
import AccordionItem from './accordionItem';

const Accordion= ({items, onClick, size='md'})=>{
    return (
        <div>
            {items.map((item, index) => (
                <div key={`accordion-${item.value}`} className="accordion__container" onClick={() => onClick(item.value)}>
                    <AccordionItem item={item} size={size}/>
                </div>
            ))}
        </div>
    )
}

export default Accordion;