import React from 'react';
import './trust.scss';
import { Text, Link } from '@ui-kit';
import { ADMIN_PROJECT_CREATE_URL } from "@app/routes";

const Trust = () => {
  return (
    <div className="trust__container">
      <div className="main__container trust__content d-flex jc-sb">
        <div className="trust__wrapper">
          <Text as="h1">Entrust your project to BriefStock</Text>
          <Text as="h3">
          Your ideal illustration doesn't exist yet? 
          Our digital artists are here to create a perfect product at a reasonable cost without 
          prepayment.
          </Text>
        </div>
        <Link to={ADMIN_PROJECT_CREATE_URL} size="xxl">
          Create an Illustration
          </Link>
      </div>
    </div>
  );
};

export default Trust;
