import { Text } from '@ui-kit';

export let RADIO_ITEMS = [
  {
    value: 'hourlyRate',
    mount: '$23/hour',
    title: <Text as="h6">Extended commercial license</Text>,
    isActive: false,
    isDisabled: false,
  },
  {
    value: 'hourlyWithoutLicense',
    title:  <Text as="h6">Exclusive license</Text>,
    isActive: false,
    isDisabled: false,
    tooltipText: (
      <>
        <Text as="h6" className="rates-tooltip__title">
          Option not available
        </Text>
        <Text as="h6" className="rates-tooltip__subtitle">
          An exclusive license is only <br />
          available for a new project
        </Text>
      </>
    ),
  },
];
