import React from "react";
import './licenses-provide.scss';
import { Text, Link, IconFeatured } from '@ui-kit';
import InfoGray from '@ui-kit/icons/info-gray600.svg';

const LicensesProvide = () => {
    const zipFile = process.env.REACT_APP_FILES_URL + '/License_agreement_template.zip'

    return (
        <div className="main__container license_provide">
            <div className="license_provide__content d-flex">
                <IconFeatured src={InfoGray} theme="gray" size="lg" />
                <div className="license_provide__description d-flex fd-column">
                    <Text as="h3">License Agreement Templates</Text>
                    <div>
                        <Text as="h4">
                            You can read the license agreements via 
                        </Text>
                        <Link to={zipFile} theme="text" target="_blank" rel="noreferrer"> this link</Link>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default LicensesProvide;
