import { v4 as uuidv4 } from 'uuid';

export class Snackbar {
  id;
  message;
  status;
  showClose;
  ico;
  duration;

  constructor(option) {
    this.id = option?.id || uuidv4();
    this.message = option.message;
    this.status = option.status;
    this.showClose = option?.showClose || true;
    this.ico = option?.ico || null;
    this.duration = option?.duration || null;
  }
}
