import React from 'react';
import './switcher.scss';

const Switcher = ({
  className = '',
  condition = 0,
  size = 'md',
  theme = 'dark',
  disabled = '',
  onSwitcherClick = () => {}
}) => {
  const classes = [
    'switcher__container',
    'pr',
    size,
    theme,
    ...className.split(' '),
  ];

  if (condition) {
    classes.push('checked');
  }

  if (disabled === 'disabled') {
    classes.push('disabled');
  }

  return (
    <div
      className={classes.join(' ')}
      onClick={() => onSwitcherClick(!condition)}
    >
      <div className="switcher__bubble"></div>
    </div>
  );
};

export default Switcher;

/**
     <Switcher 
        condition={checked}
        onSwitcherClick={()=>{}}
        size="sm"
        theme="light"
        disabled="disabled"
        className="mt-12"
    />
 */
