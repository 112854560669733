import React from 'react';
import './cart-billing.scss';

import { Text } from '@ui-kit';
import BillingForm from '../forms/billing-form';

const Billing = ({ confirmBillingData, setConfirmData }) => {
  return (
    <div className="cart__billing__wrapper">
      <Text as="h4" className="billing-information">
        Billing information
      </Text>
      <BillingForm 
        confirmBillingData={confirmBillingData}
        setConfirmData={setConfirmData}
      />
    </div>
  );
};

export default Billing;
