import React from 'react';
import './buttons.scss';
import { Icon } from '@ui-kit';

const Button = (props) => {
  const {
    children,
    iconLeft,
    iconRight,
    className = '',
    isDisabled = false,
    theme = 'primary',
    size = 'xl',
    styles: propsStyles,
    ...options
  } = props;
  const rootClasses = ['button', theme, size, className];

  if (isDisabled) {
    rootClasses.push('disabled');
  }

  return (
    <button {...options} className={rootClasses.join(' ')}>
      {iconLeft && (
        <div className="button__container-icon d-flex mr-15 button__container-icon-left">
          <Icon src={iconLeft} className="button__img button__img-left"></Icon>
        </div>
      )}
      {children}
      {iconRight && (
        <div className="button__container-icon d-flex ml-15 button__container-icon-right">
          <Icon src={iconRight} className="button__img-right"></Icon>
        </div>
      )}
    </button>
  );
};

export default Button;

/* <Button variant="accent" onPress={() => alert('Hey there!')}>
  <Icon styles={{ paddingRight: '15px' }} src={Temp} alt="React Logo" />
  <Text as="span">Icon + Label</Text>
  <Icon styles={{ paddingLeft: '15px' }} src={Temp} alt="React Logo" />
</Button>; */
