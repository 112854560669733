import { ApiService } from '@shared/api';
class UserService extends ApiService {
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.baseUrl = '/user';
    this.basketUrl = `${this.baseUrl}/basket`;
    this.passwordUrl = `${this.baseUrl}/password`;
    this.baseProductsUrl = this.baseUrl + '/products';
    this.baseProjectsUrl = this.baseUrl + '/projects';
  }

  async getToketCsrf() {
    return await this.instance.get('/token/');
  }

  async checkAuth() {
    return await this.instance.get(`${this.baseUrl}/auth/`);
  }

  async refreshToken() {
    const data = await this.instance.get(`${this.baseUrl}/refresh/`);

    if (data) {
      const { accessToken } = data.data;
      localStorage.setItem('token', accessToken);
    }
  }

  async login(formData) {
    return await this.instance.post(`${this.baseUrl}/login/`, formData);
  }

  async logout() {
    return await this.instance.post(`${this.baseUrl}/logout/`);
  }

  async recoveryPassword(formData) {
    return await this.instance.post(`${this.passwordUrl}/recovery`, formData);
  }

  async resetPassword(formData) {
    return await this.instance.post(`${this.passwordUrl}/reset`, formData);
  }

  async updatePassword(formData) {
    return await this.instance.post(`${this.passwordUrl}/update`, formData);
  }

  async confirmEmail(userEmail) {
    return await this.instance.post(`${this.baseUrl}/email/confirm`, userEmail);
  };

  async createUser(formData) {
    return await this.instance.post(`${this.baseUrl}/registration/`, formData);
  }

  async getUser(id) {
    return await this.instance.get(`${this.baseUrl}/${id}`);
  }

  async updateUserSettings(formData) {
    return await this.instance.put(`${this.baseUrl}/settings/`, formData);
  }

  async updateUserSettingsCountry(formData) {
    return await this.instance.put(`${this.baseUrl}/settings/update-country`, formData);
  }

  async updateUser(id, user) {
    return await this.instance.put(`${this.baseUrl}/update/${id}`, user);
  }

  async updateUserAva(avatar, user) {
    const formData = new FormData();
    formData.append("avatar", avatar[ 0 ]);

    return await this.instance.post(`${this.baseUrl}/${user.id}/avatar`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async deleteUser(id) {
    return await this.instance.delete(`${this.baseUrl}/${id}`);
  }

  async getCart(basketId, productId) {
    return await this.instance.get(`${this.basketUrl}/${basketId}`);
  }

  async addProductToCart(basketId, productId) {
    return await this.instance.post(`${this.basketUrl}/${basketId}`, {
      productId,
    });
  }

  async deleteProductFromCart(basketId, productId) {
    return await this.instance.delete(
      `${this.basketUrl}/${basketId}?productId=${productId}`
    );
  }

  async resetCart(basketId) {
    return await this.instance.delete(`${this.basketUrl}/${basketId}`);
  }

  async getPublickStripeSettings() {
    return await this.instance.get(`${this.baseUrl}/payment/settings`);
  }

  async getInvoice(data) {
    return await this.instance.post(
      `${this.baseUrl}/payment/create-invoice`,
      data
    );
  }

  async payInvoice(invoiceId, data) {
    return await this.instance.post(
      `${this.baseUrl}/payment/pay/${invoiceId}`,
      data
    );
  }

  async getBills(params) {
    return await this.instance.get(
      `${this.baseUrl}/payment/receipts?${this.generateQueryString(params)}`);
  }

  async getPaidProducts(params) {
    return await this.instance.get(
      `${this.baseProductsUrl}/paid?${this.generateQueryString(params)}`
    );
  }

  async getPaidProjects(params) {
    return await this.instance.get(
      `${this.baseProjectsUrl}/paid?${this.generateQueryString(params)}`
    );
  }

  async sendJobForm(formData) {
    return await this.instance.post(`${this.baseUrl}/applications/`, formData);
  }

  async sendSubscribeForm(formData) {
    return await this.instance.post(`${this.baseUrl}/subscribe/`, formData);
  }
}

const userService = new UserService();

export default userService;
