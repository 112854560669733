import { v4 as uuidv4 } from 'uuid';

export class BotMessageDTO {
  id;
  projectId;
  roomId;
  text;
  creatorId;
  createdAt;
  fromId;
  recipientId;
  status;
  isRead;
  isDeleted;
  attachments;
  toId;
  type;
  userId;
  edited;

  constructor(options) {
    this.id          = uuidv4();
    const ava        = options.ava && options.ava === "combine" ? undefined : '/images/botsAva.png';
    const date       = new Date();

    this.projectId   = options?.projectId || null;
    this.roomId      = options?.roomId || null;
    this.creatorId   = options?.creatorId || {
      id: uuidv4(),
      ava: ava,
    };

    this.fromId      = options?.fromId || null;
    this.recipientId = options?.recipientId || {};
    this.isRead      = options?.isRead || 0;
    this.isDeleted   = options?.isDeleted || 0;
    this.attachments = options?.attachments || [];
    this.text        = options?.text || '';
    this.toId        = options?.toId || null;
    this.type        = options?.type || 'text';
    this.userId      = options?.userId || null;
    this.edited      = options?.edited || 0;
    this.createdAt   = options?.createdAt || date.toISOString();
    this.isAvaCombine= options?.isAvaCombine

    if (!options?.status || options.status === '0') {
      this.status   = 0;
    }
  }
}
