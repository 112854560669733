import React from "react";
import './headerBunner.scss';

import { Text} from '@ui-kit';

const HeaderBaner = () => {
 return (
   <div className="header__bunner">
     <div className="main__container">
       <Text as="h1" className="banner__text">
        Get closer to an illustrator. <br /> Create together!
       </Text>
       <div className="banner__info d-flex jc-center">
         <div className="banner__info-item">
           <Text as="h3">Stop</Text>
           <Text as="h4">paying corporations</Text>
         </div>
         <div className="banner__info-item">
           <Text as="h3">80%</Text>
           <Text as="h4">goes to the illustrator</Text>
         </div>
       </div>
     </div>
   </div>
 );
};

export default HeaderBaner;