import { ApiService } from '@shared/api';

class ProductService extends ApiService {
  baseUrl;
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.baseUrl = '/products'
  }

  async getProduct(id) {
    return await this.instance.get(`${this.baseUrl}/public/${id}`);
  }

  async getEditProduct(id) {
    return await this.instance.get(`${this.baseUrl}/${id}`);
  }
}

const productService = new ProductService();

export default productService;
