import React from 'react';
import './modalUnsavedChanges.scss';

import { Text, Button, IconFeatured } from '@ui-kit';
import WarningIcon from '@ui-kit/icons/alert-triangle-warning600.svg';

const ModalUnsavedChanges = ({cancelChanges, saveChanges}) => {

  const onBackgroundClick = (e) => {
    if (e.target.id === 'unsaved-changes-background') {
      return cancelChanges();
    }
  };

  const onCancel = () => {
    return cancelChanges();
  }

  const onClickSave = () => {
    saveChanges();
  }

  return (
    <div
      id="unsaved-changes-background"
      className="background-default"
      onMouseDown={onBackgroundClick}
    >
      <div className="unsaved-changes-popup__wrapper">
        <IconFeatured src={WarningIcon} theme="warning" size="lg" />

        <div className="unsaved-changes-popup__content">
          <Text as="h4" className="unsaved-changes-popup__title">
            Unsaved changes
          </Text>
          <Text as="h6" className="unsaved-changes-popup__subtitle">
            Do you want to save or discard changes?
          </Text>
        
          <div className="unsaved-changes-popu__actions">
            <Button
              //className="unsaved-changes-popu__btn"
              theme="secondary-gray"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              //className="unsaved-changes-popu__btn"
              onClick={onClickSave}
            >
              Save changes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUnsavedChanges;
