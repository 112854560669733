import { Validation } from '@shared/lib/utils';

const onRecoveryFormValid = (formData) => {
  let errorsState = {};

  const validationEmail = new Validation({
    field: formData.email,
    title: 'Email',
  })
    .isRequired()
    .isEmail()
    .maxLenght(64)
    .getErrors();

  if (validationEmail.length) {
    errorsState = {
      ...errorsState,
      email: validationEmail[0],
    };
  }

  return errorsState;
};

export default onRecoveryFormValid;
