import { SET_ALERT, REMOVE_ALERT } from './notifications.constants';

const initialState = {
  alert: [],
};

const notificationsReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case SET_ALERT: {
      return {
        ...state,
        alert: [payload],
      };
    }

    case REMOVE_ALERT: {
      return {
        ...state,
        alert: state.alert.filter((alert) => alert.id !== payload),
      };
    }

    default:
      return state;
  }
};

export default notificationsReducer;
