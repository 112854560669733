import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { deviceType } from '@lib/utils';
import { HeaderFull } from '@widgets';
import { Button } from '@ui-kit';
import { Snackbar } from '@features';
import {
  isAuth,
  isOnline,
  getCurrentProject,
  getCustomer,
  getCart,
  getLocale,
  setNetworkAC,
  getExecutorsTC,
  getSettingsTC,
  getStripeSettingsTC,
  setModalLoginShowAC,
  setLocaleAC,
  setAlertAC,
  Snackbar as SnackbarClass,
  setDeviceTypeAC,
  setCurrentProjectAC,
  projectInstance,
} from '@entities';
import {
  STATIC_ROUTES,
  ADMIN_ROUTES,
  EXECUTOR_ROUTES,
  USERS_ROUTES,
  AUTH_ROUTES,
} from '@app/routes';
import { Socket } from '@shared';
import { UserContext } from '@shared/hooks';
import { ROLES } from '@entities/user';
import rusTranslation from '@shared/lib/languages/russian.json';
import engTranslation from '@shared/lib/languages/english.json';

import { setUserAC } from '@entities/user/model/user.actions';

import ProtectedRouteComponent from '@app/routes/protected-route';

const AuthSplitter = () => {
  const isUserAuth = useSelector(isAuth);
  const isUserOnline = useSelector(isOnline);
  const user = useSelector(getCustomer);
  const currentProject = useSelector(getCurrentProject);
  const cart = useSelector(getCart);
  const locale = useSelector(getLocale);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const urlLang = searchParams.get("lang");

  useEffect(() => {
    dispatch(setDeviceTypeAC(deviceType()));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (urlLang) {
      dispatch(setLocaleAC(urlLang));
    }
    // eslint-disable-next-line
  },[urlLang]);

  const language = locale === 'ru' ? rusTranslation : engTranslation;

  useEffect(() => {
    if (isUserAuth) {
      dispatch(setModalLoginShowAC(false));
    }
    // eslint-disable-next-line
  }, [isUserAuth]);

  const setSuccessConnection = () => {
    dispatch(setNetworkAC(true));
  };

  const setFailConnection = () => {
    dispatch(setNetworkAC(false));
  };

  const setCloseConnection = () => {
    dispatch(setNetworkAC(false));
  };

  /* SOCKET CONNECTION */
  useEffect(() => {
    if (!user.id) {
      return;
    }

    if (checkRole(ROLES.ADMIN)) {
      dispatch(getExecutorsTC());
      dispatch(getSettingsTC());
    };

    if (checkRole(ROLES.EXECUTOR)) {
      dispatch(getExecutorsTC());
    };

    if (window.Stripe) {
      dispatch(getStripeSettingsTC());
    }

    const notifyUserError = (header, message) => {
      const snackbar = new SnackbarClass({
        status: 205,
        duration: Infinity,
        message: (
          <div className="">
            <h5 className="snack__header">
              {header}
            </h5>
            <div className="snack__text">
              {message}
            </div>
            <div className="snack__actions">
              <Button 
                theme="link-color"
                size="sm"
                onClick={() => window.location.reload()}
              >
                Update
              </Button>
            </div>
          </div>
        ),
      });

      dispatch(setAlertAC(snackbar));
    };

    window.socket = new Socket(user.id, user.role);
    window.socket.initListeners(setSuccessConnection, setFailConnection, setCloseConnection);
    const socketCloseEventListener = () => {
      const cb = () => notifyUserError('Failed to reconnect', 'Please refresh the page to restore your connection.');
      window.socket.send = cb;
      cb();
    };
    window.socket.onReconnectError = socketCloseEventListener;

    const listener = (event) => {
      const data = event?.data;

      if (!data) return;

      const toJson = JSON.parse(data);
      const customEvent = toJson.event;

      if (customEvent === `model.user.afterSave`) {
        const savedUser = toJson?.data;

        if (savedUser?.id === user.id) {
          dispatch(setUserAC(Object.assign({}, user, savedUser)));
        }
      }

      if (customEvent === `model.project.afterSave`) {
        const projectType = user.role === ROLES.USER ? 'userProject' : 'adminProject';
        const updatedProjectDTO = projectInstance.create(toJson?.data, projectType);

        const updatedProject = Object.keys(toJson?.data)
          .reduce((result, key) => {
            result[ key ] = updatedProjectDTO[ key ];

            return result;
          }, {});
        
        dispatch(setCurrentProjectAC(Object.assign({}, currentProject, updatedProject)));
      }
    };

    window.socket.socket.addEventListener("message", listener);

    return () => {

      window.socket
        .socket
        .removeEventListener("message", listener);

      // window.socket.socket.removeEventListener('close', socketCloseEventListener);
    };

    // eslint-disable-next-line
  }, [user.id]);


  const renderRout = (route, index) => {
    if (route.internalsRouts) {
      return (
        <Route key={`route-${index}`} path={route.path.pathname}>
          {route.internalsRouts.map((internalRoute, index) => (
            <Route
              key={`internalsRouts-${index}`}
              path={internalRoute.path.pathname}
              element={internalRoute.element}
            />
          ))}
        </Route>
      );
    }

    return (
      <Route
        key={index}
        path={route.path.pathname}
        element={route.element}
        exact={route.exact}
        sitemapIndex={route.sitemapIndex}
      />
    );
  };

  const checkRole = (role) => {
    return user && user.role === role;
  };

  let ROUTER_BUILDER = [...STATIC_ROUTES];

  if (isUserAuth) {

    if (checkRole(ROLES.USER)) {
      ROUTER_BUILDER = [...ADMIN_ROUTES, ...USERS_ROUTES, ...ROUTER_BUILDER];
    } else {
      ROUTER_BUILDER = [
        ...ADMIN_ROUTES,
        ...EXECUTOR_ROUTES,
        ...ROUTER_BUILDER,
      ];
    }
  } else {

    const PROTECTED_ROUTES = [
      ...ADMIN_ROUTES,
      ...USERS_ROUTES,
      ...EXECUTOR_ROUTES,
    ];

    PROTECTED_ROUTES.forEach(route => {
      [ route, ]
        .concat(route.internalsRouts || [])
        .forEach(_route => {
          _route.element = (<ProtectedRouteComponent>{_route.element}</ProtectedRouteComponent>);
        });
    });

    ROUTER_BUILDER = [...PROTECTED_ROUTES, ...ROUTER_BUILDER, ...AUTH_ROUTES];
  }

  const broadenUser = {
    ...user,
    isUserAuth,
    isUserOnline,
    cart,
  };
  // notifications,

  return (
    <>
      <Helmet
        defaultTitle="Stock Images, Vectors, Illustrations | BriefStock"
        meta={[
          {
            name: 'keywords',
            content: 'image, illustration, vector, order, brief, stock,',
          },
          {
            name: 'description',
            content: 'BriefStock is here instead of searching',
          },
        ]}
        link={[
          {
            rel: 'icon',
            href: '/favicon.ico',
            type: 'image/x-icon',
            sizes: 'any',
          },
          { rel: 'icon', href: '/logo.svg', type: 'image/svg+xml' },
          { rel: 'apple-touch-icon', href: '/apple-touch-icon.png' },
          { rel: 'manifest', href: '/manifest.json' },
        ]}
      />

      <div className="main__wrapper">
        <UserContext.Provider value={broadenUser}>
          <Snackbar />
          <HeaderFull />
        </UserContext.Provider>
        <div className="main__content" id="main__content">
          <IntlProvider messages={language} locale={locale} defaultLocale="en">
            <Routes>
              {ROUTER_BUILDER.map((route, index) => renderRout(route, index))}
            </Routes>
          </IntlProvider>
        </div>
      </div>
    </>
  );
};

export default AuthSplitter;
