import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isAuth } from '@entities';
import { ADMIN_LOGIN_URL } from '@app/routes';

const ProtectedRouteComponent = ({children}) => {
  const isUserAuth = useSelector(isAuth);

  if (!isUserAuth) {
    localStorage.setItem('fullpath', window.location.pathname + window.location.search);
  }

  return isUserAuth ? children : (<Navigate to={ ADMIN_LOGIN_URL.pathname } replace />);
};

export default ProtectedRouteComponent;
