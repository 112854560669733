import React from "react";
import { useLocation } from 'react-router-dom';
import './rights-to-product.scss';

import { Text } from '@ui-kit';
import { Rights } from '@features';

const RightsToProduct = () => {
    const location = useLocation();
    const hash = location.hash;
    const id = hash.replace('#', '');
    
    if (id === "rights") {
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 500);
    }

 return (
    <div className="rights__main-container" id="rights">
        <div className="main__container rights__container d-flex">
            <div className="rights__description">
                <Text as="h5" className="subtitle mb-12">
                    For every license
                </Text>
                <Text as="h1" className="title mb-20">
                    Rights to any digital product
                </Text>
            </div>
            <div className="rights__content fw-wrap d-flex">
                <Rights />
            </div>
        </div>
    </div>
 );
};

export default RightsToProduct;