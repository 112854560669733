import React from 'react';
import { useDispatch } from 'react-redux';
import './delete-product.scss';

import { deleteProductsTC } from '@entities';
import { Text, Button, IconFeatured } from '@ui-kit';
import ErrorIcon from '@ui-kit/icons/alert-circle-error600.svg';

const ModalDeleteProduct = ({productsId = [], setModalDeleteProduct, onUpdateData = ()=>{}}) => {
    const dispatch = useDispatch();

    const onBackgroundClick = (e) => {
        if (e.target.id === 'delete-product-background') {
          return setModalDeleteProduct({status:false});
        }
      };

    const onClickDelete = () => {
        if(!productsId.length) return;
        
        dispatch(deleteProductsTC(productsId));
        setModalDeleteProduct({status:false});
        return onUpdateData();
    }

    const onCancel = () => {
        return setModalDeleteProduct({status:false});
      }

    return (
        <div
            id="delete-product-background"
            className="background-default delete-product-background"
            onMouseDown={onBackgroundClick}
        >
        <div className="delete-product-popup__wrapper">
            <IconFeatured src={ErrorIcon} theme="error" size="lg" />

            <div className="delete-product-popup__content">
                <Text as="h4" className="delete-product-popup__title">
                    Delete product
                </Text>
                <Text as="h6" className="delete-product-popup__subtitle">
                    Are you sure you want to delete selected product?
                </Text>
                <Text as="h6" className="delete-product-popup__subtitle">
                    This action cannot be undone.
                </Text>
                
                <div className="delete-product-popu__actions">
                    <Button
                        theme="primary-danger"
                        onClick={onClickDelete}
                    >
                        Delete
                    </Button>
                    <Button
                        theme="secondary-gray"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ModalDeleteProduct;