import React, {useState, useEffect} from 'react';
import { Popover } from 'antd';
import { useSearchParams } from 'react-router-dom';
import './filters-select.scss';

import { Text,  Button, CheckBox } from '@ui-kit';
import FilterIcon from '@ui-kit/icons/filter-gray700.svg';

const FiltersSelect = ({
    isArrow = false,
    placement = "bottomRight",
    trigger = "click",
    filters = [], 
    onChangeFilter = ()=>{}, 
    className = ''
}) => {
    const [selectedFilters, setSelectedFilters] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const classes = ["filters-checked__container", className];

    if (!filters.length) {
        classes.push("empty-filters")
    }

    useEffect(() => {
        if (!filters.length) return;

        const newSelected = {}

        filters?.map((list, index) => {
            const searchValues = searchParams.get(list.order) || '';
            if (!searchParams) return list;
            
            list?.list.map((filter, i) => { 
                const id = `id${index}.${i}`
                if (searchValues.includes(filter.value)) {
                    newSelected[id] = {order: list.order, value: filter.value}
                };

                return filter;
            });

            return list;
        });
        
        setSelectedFilters(newSelected);

        // eslint-disable-next-line
    }, []);

    const onChange = (event, order, filter, id) => {
        event.stopPropagation();
        const newSelectedFilters = { ...selectedFilters };
        const values = searchParams.get(order) || '';
        const arrValues = values.split(' ');

        if (newSelectedFilters[id]) {
            delete newSelectedFilters[id];
            setSelectedFilters(newSelectedFilters);

            const newValues = arrValues.filter((item) => item !== filter?.value)

            if (!newValues.length) {
               searchParams.delete(order); 
            } else {
                const clearValues = newValues.join(' ').trim();
                searchParams.set(order, clearValues); 
            };
        } else {
            newSelectedFilters[id] = {order: order, value: filter?.value};
            setSelectedFilters(newSelectedFilters);

            arrValues.push(filter?.value);
            const clearValues = arrValues.join(' ').trim();
            searchParams.set(order, clearValues);
        };

        setSearchParams(searchParams, {replace: true});

        setTimeout(() => onChangeFilter(), 500);
    };

    const onReset = (list, index) => {
        searchParams.delete(list.order)
        setSearchParams(searchParams, {replace: true});

        const array = Object.keys(selectedFilters);
        const newSelectedFilters = {...selectedFilters}

        array.map((item) => {
            if(item.includes(`id${index}.`)) {
                delete newSelectedFilters[item];
            };

            return item;
        });
        setSelectedFilters(newSelectedFilters);

        setTimeout(() => {
            onChangeFilter();
        }, 500);
    }

    const renderItem = (order, filter, listIndex, itemIndex) => {
        const filterClasses = ['checkbox__item'];
        const id = `id${listIndex}.${itemIndex}`;
        const checked = !!selectedFilters[id];

        if (checked) {
            filterClasses.push('active');
        } 
    
        return (
            <CheckBox
                key={`checkbox-${itemIndex}`}
                label={filter.name}
                className={filterClasses.join(' ')}
                checked={checked}
                onChange={(event) => onChange(event, order, filter, id)}
            />
        )
    };

    const renderList = (list, listIndex) => {
        return (
            <ul className="filter-select__list" key={`list-${listIndex}`}>
                {list?.title && <Text as="h6" className="list-title">{list?.title}</Text>}
                {list?.list?.map((filter, i) => renderItem(list?.order, filter, listIndex, i))}
                <div className="list-actions">
                    <Button
                        theme="link-gray"
                        size="md"
                        onClick={() => onReset(list, listIndex)}
                    >
                        Reset
                    </Button>
                </div>
            </ul>
        )
    };

    const content = (
        <div className="filter-select__wrapper">
            {filters.map((list, index) => 
                renderList(list, index)
            )}
        </div>
    );
    
    return (
        <div className="filters-select__container">
            <Popover 
                content={content}
                trigger={trigger}
                arrow={isArrow}
                placement={placement}
                overlayClassName="filters-select__popover"
            >
                <>
                    <Button
                        theme="secondary-gray"
                        size="md"
                        iconLeft={FilterIcon}
                    />
                </>
            </Popover>
        </div>
    );
}

export default FiltersSelect;