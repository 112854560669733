import React from "react";
import './howwework.scss';

import { Text, Link, Icon } from '@ui-kit';
import { ADMIN_PROJECT_CREATE_URL } from "@app/routes";

const HowWeWork = () => {
 return (
   <div className="how_we_work__container">
     <div className="main__container how_we_work__content d-flex ai-center">
       <div className="how_we_work__left">
         <Text as="h1" className="mb-20">
           How do we create custom illustrations?
         </Text>
         <Text as="h3" className="mb-40">
         BriefStock unites the best digital artists. They are ready to bring your ideas to life! 
         So you'll be amazed with the result every time working with us.
         </Text>
         <Link to={ADMIN_PROJECT_CREATE_URL} theme="text" className="arrow-right">
           Fill out the brief
         </Link>
       </div>
       <div className="how_we_work__right pr">
        <Icon src={'/images/banner_how_we_work.png'} className="how_we_work__banner" alt="banner_how_we_work"/>
       </div>
     </div>
   </div>
 );
};

export default HowWeWork;