export const deepArrayCopy = (array) => {
  return array.map((item) => Object.assign({}, item));
};

export const deepObjectCopy = (obj) => {
  return Object.assign({}, obj);
};

export const converterBiteTo = (bytes) =>{
  const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  let l = 0, n = parseInt(bytes, 10) || 0;

  while(n >= 1024 && ++l){
    n = n/1024;
  } 

  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export const geShortCut = (string1, string2) => {
  if (!string1 && !string2) return "DF";

  if (string1 && !string2) {
    return (string1.charAt(0)+string1.charAt(1)).toUpperCase();
  }

  return (string1.charAt(0)+string2.charAt(0)).toUpperCase();
}
