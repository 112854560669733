import React, { useState } from 'react';
import './short-info.scss';

import {Icon, Text} from '@ui-kit';
import { deepObjectCopy } from '@shared';
import { ModalProject } from '@widgets';
import Dollar from '@ui-kit/icons/dollar-green500.svg';
import Inprogress from '@ui-kit/icons/edit-3-blueLight500.svg';
import Wait from '@ui-kit/icons/clock-orange500.svg';
import Crown from '@ui-kit/icons/crown-pink500.svg';
import Download from '@ui-kit/icons/download-blueGray500.svg';
import Check from '@ui-kit/icons/check-green500.svg';
import Unpaid from '@ui-kit/icons/divide-circle-warning500.svg';
  
const ShortInfo = ({project, removeNotification }) => {
  const [isViewNotification, setIsViewNotification] = useState(false);
  
  const {id, followingId, isPaid, notificationsToUser} = project;
  const followingClass = ['short-info__stage'];

  if (!followingId) {
    followingClass.push('disabled')
  }

  const onViewNotification = () => {
    setIsViewNotification(!isViewNotification);
  };

  const setRemove = () => {
    setIsViewNotification(false);
    removeNotification();
  }
  
  const getContent = () => {
    const notification = notificationsToUser[0];
    const copy = deepObjectCopy(notification);
    const classView = isViewNotification ? 'view' : '';
    
    return (
      <ModalProject
        className={classView}
        notification={copy}
        isUserRole={false}
        removeNotification={setRemove}
        projectId={id}
      />
    )
  };

  const showNotificationLabel = ({ markType, id }) => {
      switch (
        markType //ready, wait, in_progress ,download, switch_license
      ) {
        case 'sketchReady':
        case 'illustrationReady':
          return (
            <div className="short-item__content" key={`notification-${id}`}>
              <div className="icon__shape ready" onClick={onViewNotification}>
                <Icon src={Dollar} />
              </div>
              {getContent()}
            </div>
          );
  
        case 'waitingResponse':
          return (
            <div className="short-item__content" key={`notification-${id}`}>
              <div key={`notification-${id}`} className="icon__shape wait" onClick={onViewNotification}>
                <Icon src={Wait} />
              </div>
              {getContent()}
            </div>
          );
  
        case 'sourceReady':
          return (
            <div className="short-item__content" key={`notification-${id}`}>
              <div key={`notification-${id}`} className="icon__shape download" onClick={onViewNotification}>
                <Icon src={Download} />
              </div>
              {getContent()}
            </div>
          );
  
        case 'switchingToExclusive':
          return (
            <div className="short-item__content" key={`notification-${id}`} >
              <div
                key={`notification-${id}`}
                className="icon__shape switch_license"
                onClick={onViewNotification}
              >
                <Icon src={Crown} />
              </div>
              {getContent()}
            </div>
          );
  
        default:
          //'workProgress'
          return (
            <div className="short-item__content" key={`notification-${id}`}>
              <div key={`notification-${id}`} className="icon__shape in_progress" onClick={onViewNotification}>
                <Icon src={Inprogress} />
              </div>
              {getContent()}
            </div>
          );
      }
  };
  
  const getStage = () => {
    if (!followingId) return ' ';

    if (followingId >= 7) return 'Completed';
    if (followingId >= 5) return 'Illustrating';
    if (followingId >= 3) return 'Sketching';
    if (followingId >= 0) return 'Brief';

    return 'Brief';    
  }

  const getPaid = () => {
    if (+isPaid) {
        return (
        <div className="short-info__paid paid">
            <Icon src={Check} /> 
            <Text as="h6">Paid</Text>
            </div>
        );
    };

    if (+isPaid === 0) {
      return (
          <div className="short-info__paid unpaid">
              <Icon src={Unpaid} /> 
              <Text as="h6">Unpaid</Text>
          </div>
      );
    }

    return;
  };

  return (
    <div className="short-info">
      <div className="short-info__wrapper">
        <div className="short-info__status">
          {notificationsToUser?.map(item => showNotificationLabel(item))}
        </div>
        <div className={followingClass.join(' ')}>{getStage()}</div>
      </div>
        {getPaid()}
    </div>
  );
};

export default ShortInfo;