import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './footer.scss';

import { Text, Icon, FooterLink } from '@ui-kit';
import { 
  PRIVACY_POLICY_URL,
  TERMS_CONDITIONS_URL,
  COOKIE_POLICY_URL,
  RETURN_POLICY_URL,
  LICENSES_URL,
  CONTACTS_URL,
  ABOUT_US_URL,
  CAREER_URL,
  BROWSE_SEARCH_URL,
  ADMIN_PROJECT_CREATE_URL,
} from "@app/routes";
import { Copyright } from '@widgets';

const FOOTER_MENU = [
  {
    title: 'Product',
    links: [
      { title: 'Gallery', path: BROWSE_SEARCH_URL },
      { title: 'License', path: LICENSES_URL },
      { title: 'Order illustration', path: ADMIN_PROJECT_CREATE_URL },
      { title: 'Rates', path: "/#rates" }
    ],
  },
  {
    title: 'Company',
    links: [
      { title: 'About Us', path: ABOUT_US_URL },
      { title: 'Career', path: CAREER_URL },
      { title: 'Contact', path: CONTACTS_URL },
    ],
  },
  {
    title: 'Legal',
    links: [
      { title: 'Privacy Policy', path: PRIVACY_POLICY_URL },
      { title: 'Cookie Policy', path: COOKIE_POLICY_URL },
      { title: 'Return Policy', path: RETURN_POLICY_URL },
      { title: 'Terms & Conditions', path: TERMS_CONDITIONS_URL },
    ],
  },
];

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;

    if (hash && hash !== '#rates') {
      const id = hash.substring(1);
      const domNode = document.getElementById(id);
      domNode.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const renderMenuList = (menuItem) => {
    if (menuItem.links.length) {
      return menuItem.links.map((link, index) => (
        <li key={`footer__link-${index}`}>
          <FooterLink to={link.path} theme="dark-gray" title={link.title} isBadge={link?.badge}/>
        </li>
      ));
    }
  };
  
  return (
    <footer className="footer">
      <div className="main__container">
        <div className="footer__container d-flex">
          <div className="footer__main d-flex">
            <div className="footer__main-banner">
              <Icon src="/images/banner-footer.png" />
            </div>
            <div className="footer__main-desc">
              <Text as="h5">Free vectors are coming soon!</Text>
              <Text as="h6">
                We are preparing a great update: free illustrations. We will 
                select a collection of vectors for you to use under an extended 
                commercial license without payment. Stay tuned for details in the 
                upcoming release!
              </Text>
            </div>
          </div>
          <div className="footer__menu d-flex">
            {FOOTER_MENU.map((item, index) => (
              <div key={`footer-${index}`} className="footer__menu-item">
                <Text as="span">{item.title}</Text>
                {renderMenuList(item)}
              </div>
            ))}
          </div>
        </div>
        <Copyright/>
      </div>
    </footer>
  );
};

export default Footer;
