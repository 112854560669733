import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Recapcha, } from '@shared';
import './signup.scss';

import { useForm } from '@shared';
import { Button, Input, Text, Icon, Link, CheckBox } from '@ui-kit';
import { 
  createUserTC, 
  getServerError, 
  setServerErrorsAC, 
  setModalSignupShowAC,
  setModalLoginShowAC, 
} from '@entities';
import onSignUpFormValid from './signup-validation.hook';
import onCaptchaValid from '../security-form/captcha-validation.hook';
import { TERMS_CONDITIONS_URL, PRIVACY_POLICY_URL } from '@app/routes';
import { LoadingAdmin } from '@shared/ui';
import EyeOnIcon from '@ui-kit/icons/eye-gray400.svg'
import EyeOffIcon from '@ui-kit/icons/eye-off-gray400.svg'

//Autofill call onChangeInput form 2 time
const SignupForm = () => {
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const serverError = useSelector(getServerError);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [isAcceptTermsConditions, setAcceptTermsConditions] = useState(false);
  const [isPasswordShow, setPasswordShow] = useState(false);

  const SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const eyeIcon = isPasswordShow ? EyeOnIcon : EyeOffIcon;
  const inputType = isPasswordShow ? 'text' : 'password';
  const user = {
    firstName: '',
    email: '',
    password: '',
    isConfirmAgreement: 0,
  };

  const { formData, onChangeInput, onSubmitForm } = useForm(
    user,
    handlerBeforeSubmitingForm
  );

  useEffect(() => {
    return () => dispatch(setServerErrorsAC([]));
  }, [dispatch]);

  useEffect(() => {
    const errorsArr = Object.keys(errors)
    if (errorsArr.length) {
        const inputElement = document.getElementsByName(errorsArr[0])[0];
        inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };
}, [errors]);

  /*
   * 'function declaration' is necessary to call after describing the hook
   */
  async function handlerBeforeSubmitingForm() {
    if (isAcceptTermsConditions) {
      formData.isConfirmAgreement = 1;
    }

    const formErrors = onSignUpFormValid(formData);

    if (Object.keys(formErrors).length) {
      return setErrors(formErrors);
    }

    setErrors({});
    // recaptchaRef.current.execute();
    
    await Recapcha.start(recaptchaRef);
  }

  const onSecuritySuccess = async (token) => {
    formData.captchToken = token;
    recaptchaRef.current.reset();

    if(Object.keys(errors).length) {
      return;
    }

    setIsLoading(true);

    const isResult = await dispatch(createUserTC(formData));

    if (!isResult) {
      setIsLoading(false);
      return;
    }

    localStorage.setItem('accept_cookie', true);
    
    dispatch(setModalSignupShowAC(false));
    return dispatch(setModalLoginShowAC(true));
  };

  const onSecurityError = () => {
    const captchaError = onCaptchaValid({ captchToken: null });

    setErrors({ ...errors, ...captchaError });
  };

  const onRecaptchaChange = (token) => {
    setCaptcha(token);
    
    if (!token) {
      onSecurityError();
    }

    return  onSecuritySuccess(token);
  } 

  const onChangeTermsAndConditions = () => {
    const nextValue = !isAcceptTermsConditions ? 'checked' : false;
    setAcceptTermsConditions(nextValue);
  };

  const onIconClick = () => {
    if (isPasswordShow) {
      setPasswordShow(false);
      return;
    }
    setPasswordShow(true);
  };

  const goToLogin = () => {
    dispatch(setModalSignupShowAC(false));
    dispatch(setModalLoginShowAC(true));
  };

  const termsLabel = () => {
    return(
      <>
        <Text as="span">Accept</Text>{' '}
        <Link 
          to={TERMS_CONDITIONS_URL.pathname} 
          theme="link-color"
          size="sm"
        >
          Terms & Conditions
        </Link>{' '}
        <Text as="span">and</Text>{' '}
        <Link 
          to={PRIVACY_POLICY_URL.pathname} 
          theme="link-color"
          size="sm"
        >
          Privacy Policy
        </Link>
      </>
    )
  }

  return (
    <div className={`form__container login__form-container ${isLoading && 'pr'}`}>
      {isLoading && <LoadingAdmin />}
      <div className="form__header">
        <Icon src="\logo.svg" />
        <Text as="h2" className="form__header-title">
          Create an account
        </Text>
        <Text as="h5" className="login__header-subtitle">
          Get closer to an illustrator. Сreate together!
        </Text>
      </div>
      <div className="form__content">
        <form onSubmit={onSubmitForm} className="login__form">
          <Input
            placeholder="Enter your name"
            autoComplete="given-name"
            name="firstName"
            type="text"
            required
            label="Name *"
            value={formData?.firstName || ''}
            onChange={onChangeInput}
            className={errors.firstName && 'form-item__error'}
          />
          {errors.firstName && (
            <Text as="span" className="error">
              {errors.firstName}
            </Text>
          )}

          <Input
            placeholder="Enter your email"
            autoComplete="email"
            name="email"
            type="email"
            required
            label="Email *"
            value={formData?.email || ''}
            onChange={onChangeInput}
            className={errors.email && 'form-item__error'}
          />
          {errors.email && (
            <Text as="span" className="error">
              {errors.email}
            </Text>
          )}
          <Input
            placeholder="Create a password"
            name="password"
            type={inputType}
            autoComplete="new-password"
            required
            label="Password *"
            value={formData?.password || ''}
            onChange={onChangeInput}
            className={errors.password && 'form-item__error'}
            iconRight={eyeIcon}
            onIconClick={() => onIconClick()}
          />
          {errors.password && (
            <Text as="span" className="error">
              {errors.password}
            </Text>
          )}

          {!errors.password && (
            <Text as="h6" className="form-helper__text">
              Must be at least 8 characters including letters and numbers.
            </Text>
          )}

          <div className="d-flex jc-sb ai-center mt-24 signup__terms">
            <CheckBox
              label={termsLabel()}
              className="checkbox__item"
              checked={isAcceptTermsConditions}
              onChange={onChangeTermsAndConditions}
              name="isConfirmAgreement"
            />
            {/*<Icon src="/images/captch-img.png" />*/}
          </div>
          {errors.isConfirmAgreement && (
            <Text as="span" className="error">
              {errors.isConfirmAgreement}
            </Text>
          )}

          <Button type="submit" className="mt-24" size="lg">
            <Text as="span">Get started</Text>
          </Button>

          <Recapcha
            recaptchaRef={recaptchaRef}
            className="g-recaptcha"
            sitekey={SITE_KEY}
            onChange={onRecaptchaChange}
            onErrored={onSecurityError}
            size= "invisible"
            hidden={captcha !== null}
            //badge="inline"
          />
        </form>
        {errors.captcha && (
          <Text as="span" className="error">
            {errors.captcha}
          </Text>
        )}
        {/* server errors block */}
        {!!serverError.length && (
          <div className="server__error">
            {serverError.map((error) => (
              <Text as="span" key={error.param} className="error">
                {error.msg}
              </Text>
            ))}
          </div>
        )}
      </div>
      <div className="signup__footer">
        <Text as="span">Already have an account? </Text>
        <Button onClick={goToLogin} size="md" theme="link-color">
          Log in
        </Button>
      </div>
    </div>
  );
};

export default SignupForm;
