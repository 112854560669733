import React, { useState, /*useEffect,*/ useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './free-product-popup.scss';

import { useForm } from '@shared';
import onFreeProductValid from './free-product-validation.hook';
import { Message } from '@features';
import { Text, Button, Icon, /*Badge,*/ TextArea } from '@ui-kit';
import { ProductInfo, /*HoverProductCard,*/ WaitingPreloader } from '@widgets';
import { 
    Bot, 
    getCustomer,
    setModalGetFreeProductAC, 
    getCurrentFreeProduct,
    getFreeProductTC,
} from '@entities';
//import productService from '@entities/products/api';
import { ADMIN_PROJECTS_URL, ADMIN_USER_PROJECTS_URL } from '@app/routes';
import { ROLES } from '@entities/user';
import CloseIcon from "@ui-kit/icons/x-gray700.svg";
import InfoIcon from "@ui-kit/icons/alert-circle-gray400.svg";
import DownloadIcon from "@ui-kit/icons/download-white.svg";
import DownloadBlueIcon from "@ui-kit/icons/download-accent700.svg";
import CheckIcon from "@ui-kit/icons/check-accent500-md.svg";
//const { REACT_APP_PRODUCTS_URL } = process.env;

const GetFreePopup = () => {
    const dispatch = useDispatch();
    //const imageRef = useRef();
    const preloaderKeyRef = useRef();
    const customer = useSelector(getCustomer);
    const currentFreeProduct = useSelector(getCurrentFreeProduct)
    const [errors, setErrors] = useState({});
    const [isApplyProject, setIsApplyProject] = useState(false);
    const [isDownload, setIsDownload] = useState(false);

    const botMessages = new Bot().getFreeProductMessages('benefits');
    const { formData, onChangeInput, onSubmitForm } = useForm(
        {'projectName': ''}, 
        handlerBeforeSubmitingForm
    );
    const {
        id,
        name,
        /*pathToPreview,
        firstName,
        lastName,
        ava,
        correctWidth,
        correctHeight,
        isFree = false,
        //user = {},*/
    } = currentFreeProduct;
    //const src = `${REACT_APP_PRODUCTS_URL}/${pathToPreview}`
    //const executor = ava ? { firstName, lastName, ava } : user;

    /*useEffect(() => {
        if (!imageRef.current) return;

        if (correctHeight >= correctWidth) {
            imageRef.current.style = "height: 100%;";
        };
        // eslint-disable-next-line
    }, [])*/

    const onBackgroundClick = (e) => {
        if (e.target.id === 'free-product-background') {
          return onCancel();
        }
    };

    const onCancel = () => {
        return dispatch(setModalGetFreeProductAC({show: false, product: {}}));
    };

    const download = async () => {
        setIsDownload(true);
        preloaderKeyRef.current = 'downloadArchive';

        try {
            const product =  await dispatch(getFreeProductTC({ 
                id: id, projectName: formData.projectName 
            }));

            const ourLink = URL.createObjectURL(product);
            const link = document.createElement('a');

            link.href = ourLink;
            link.download = `${name}.zip`;
            link.click();
        } catch (error) {
            console.log('[ERROR]', error)
            return false;
        }

        setIsDownload(false);
        return true;
    };

    async function handlerBeforeSubmitingForm() {
        formData.projectName = 
            formData.projectName.trim().replaceAll('  ', ' ');

        const formErrors = onFreeProductValid(formData);

        if (Object.keys(formErrors).length) {
            return setErrors(formErrors);
        }
        
        setErrors({});
        const responce = await download();

        if(responce) {
            setIsApplyProject(true)
            formData.projectName = '';
        }
    };

    const checkRole = (role) => {
        return customer && customer?.role === role;
    };
    
    const createProject = (product) => {
        if (checkRole(ROLES.USER)) {
            localStorage.setItem('productToNewProject', JSON.stringify({
                id: product.id, 
                title: product.title, 
                pathToBrowse: product.pathToBrowse
            }))

            return window.open(`${ADMIN_USER_PROJECTS_URL.pathname}?create-new=${product?.id}`, '_blank');
        }

        return window.open(ADMIN_PROJECTS_URL.pathname, '_blank');
    };

    const renderModalInfo = () => {
        if (isApplyProject) {
            return (
                <>
                    <ProductInfo
                        product={currentFreeProduct}
                        linkPreview={true}
                        isGallery={true}
                        callback={createProject}
                    />
                    {isDownload && <WaitingPreloader keyText={preloaderKeyRef.current} />}
                    <div className="free-product-popup__purchases">
                        <Icon src={CheckIcon}/>
                        <Text as="h5">Product in your purchases</Text>
                    </div>
                    <div className="free-product-popup__actions">
                        <Button 
                            iconLeft={DownloadBlueIcon}
                            theme="secondary"
                            onClick={() => setIsApplyProject(false)}
                        >
                            Download again
                        </Button>
                    </div>
                </>
            )
        }

        return (
            <>
                {botMessages.map((message, index) => (
                    <Message
                        isUser={true}
                        ownerId={null}
                        message={message}
                        key={`message-${index}`}
                    />
                ))}
                <form className="free-popup__form">
                    <TextArea
                        value={formData?.projectName || ''}
                        label="Project name"
                        onChange={onChangeInput}
                        maxLengthCustom={100}
                        className="free-popup__typing-form"
                        placeholder="Please enter the name of the project for which the product is licensed"
                        name="projectName"
                    />
                    {errors.projectName && (
                        <Text as="span" className="error">
                            {errors.projectName}
                        </Text>
                    )}

                    {isDownload && <WaitingPreloader keyText={preloaderKeyRef.current} />}
                    <div className="free-product-popup__actions">
                        <Button 
                            type="button" 
                            iconLeft={DownloadIcon} 
                            onClick={onSubmitForm}
                        >
                            Download
                        </Button>
                    </div>
                </form>

                <div className="free-popup__info">
                    <Icon src={InfoIcon} className="free-popup__info-icon"/>
                    <Text as="h5">
                        If you want to license the product for multiple projects, 
                        download it as many times as you need.
                    </Text>
                </div>
            </>
            
        );
    };

    return (
        <div 
            id="free-product-background" 
            className="background-default free-product-popup__bkg"
            onMouseDown={onBackgroundClick}
        >
            {/*<div className="free-popup__illustration-card" ref={imageRef} >
                <Badge size="sm" theme="warning" text="Free"/>
                <Icon src={src} className="product__img" />
                <HoverProductCard
                    author={executor}
                    productId={id}
                    isFree={isFree}
                />
            </div>*/}
            <div className={`free-popup__wrapper${isApplyProject ? " apply":""}`}>
                <div className="free-popup__actions">
                    <div className="free-popup__close" onClick={onCancel}>
                            <Icon src={CloseIcon} />
                    </div>
                </div>

                {renderModalInfo()}

            </div>
        </div>
    );
};

export default GetFreePopup;
