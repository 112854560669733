import {Text} from "@ui-kit";

export const UPLOAD_LINK = "uploadlink";
export const UPLOAD_FILE = "uploadfile";

export const RADIO_ITEM_LINK = [
    {
      value    : UPLOAD_LINK,
      title    :  <Text as="h5">Share link</Text>, 
      isActive : true,
      isDisabled: false,
    }
  ]

  export const RADIO_ITEM_FILE = [
    {
      value    : UPLOAD_FILE,
      title    : <Text as="h5">Attach project archive</Text>,
      isActive : false,
      isDisabled: false,
    }
  ]