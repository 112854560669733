import country from './countries.json';

const combinePath = (code) => {
  return require(`@ui-kit/icons/countries/${code}.svg`);
};

const countriesCode = [
  "AF","AX","AL","DZ","AS","AD","AO","AI","AQ","AG","AR","AM",
  "AW","AU","AT","AZ","BS","BH","BD","BB","BY","BE","BZ","BJ",
  "BM","BT","BO","BQ","BA","BW","BV","BR","IO","UM","VG","VI",
  "BN","BG","BF","BI","KH","CM","CA","CV","KY","CF","TD","CL",
  "CN","CX","CC","CO","KM","CG","CD","CK","CR","HR","CU","CW",
  "CY","CZ","DK","DJ","DM","DO","EC","EG","SV","GQ","ER","EE",
  "ET","FK","FO","FJ","FI","FR","GF","PF","TF","GA","GM","GE",
  "DE","GH","GI","GR","GL","GD","GP","GU","GT","GG","GN","GW",
  "GY","HT","HM","VA","HN","HU","HK","IS","IN","ID","CI","IR",
  "IQ","IE","IM","IL","IT","JM","JP","JE","JO","KZ","KE","KI",
  "KW","KG","LA","LV","LB","LS","LR","LY","LI","LT","LU","MO",
  "MK","MG","MW","MY","MV","ML","MT","MH","MQ","MR","MU","YT",
  "MX","FM","MD","MC","MN","ME","MS","MA","MZ","MM","NA","NR",
  "NP","NL","NC","NZ","NI","NE","NG","NU","NF","KP","MP","NO",
  "OM","PK","PW","PS","PA","PG","PY","PE","PH","PN","PL","PT",
  "PR","QA","XK","RE","RO","RU","RW","BL","SH","KN","LC","MF",
  "PM","VC","WS","SM","ST","SA","SN","RS","SC","SL","SG","SX",
  "SK","SI","SB","SO","ZA","GS","KR","ES","LK","SD","SS","SR",
  "SJ","SZ","SE","CH","SY","TW","TJ","TZ","TH","TL","TG","TK",
  "TO","TT","TN","TR","TM","TC","TV","UG","UA","AE","GB","US",
  "UY","UZ","VU","VE","VN","WF","EH","YE","ZM","ZW"
]

const countriesWithIcons = country.map((country) => {
  const code = country?.code;
  
  if (!code || !countriesCode.includes(code)) {
    country.icon = combinePath('UNDEF');
    return country;
  }

  country.icon = combinePath(code);
  country.value = code;
  return country;
});


export { countriesWithIcons };
