import React, { useEffect } from 'react';
import './card.scss';
import { Text } from '@ui-kit';
import { useSelector } from 'react-redux';
import { getStripeToken } from '@entities';

const Card = ({ 
  name = 'card',
  cardErrors = false,
  setCard = () => {},
  setCardError = () => {},
}) => {
  const stripeToken = useSelector(getStripeToken);

  const STRIPE_STYLING_OPTIONS_ = {
    hidePostalCode: true,
    style: {
      base: {
        fontFamily: 'NotoRegular, sans-serif',
        color: '#667085',
        fontWeight: '400',
        '::placeholder': {
          color: '#adb1b6cf',
          fontSize: '14px',
          fontFamily: '"SoraThin", sans-serif',
        },
      },
      invalid: {
        color: '#FF6969',
      },
    },
  };

  const classes = ['card__container'];
  if (cardErrors) {
    classes.push('error');
  }

  useEffect(() => {
    if (!stripeToken) {
      return;
    }

    const STRIPE = window.Stripe(stripeToken);
    var elements = STRIPE.elements();

    const PAYMENT_ELEMENT = elements.create('card', STRIPE_STYLING_OPTIONS_);

    window.PAYMENT_ELEMENT = PAYMENT_ELEMENT;
    window.STRIPE = STRIPE;

    PAYMENT_ELEMENT.on('change', (event) => {
      if (event.error) {
        return setCardError({ card: event.error.message });
      }

      if (event.complete) {
        setCard(event);
      }

      return setCardError({});
    });

    PAYMENT_ELEMENT.mount('.card__mount');
    // eslint-disable-next-line
  }, [stripeToken]);

  if (!stripeToken) {
    return;
  }

  return (
    <div className="card__wrapper">
      <Text as="h4" className="card__title">
        Payment Information
      </Text>
      <div className={classes.join(' ')} name={name}>
        <label htmlFor="card__mount">Card number *</label>
        <div className={`card__mount `} id="card__mount"></div>
      </div>
    </div>
  );
};

export default Card;
