
import Application from '@app/App';
import reportWebVitals from './reportWebVitals';

import * as ReactDOMClient from 'react-dom/client';
import { hydrateRoot } from 'react-dom/client';

const rootElement = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement);

rootElement.hasChildNodes()
  ? hydrateRoot(rootElement, <Application/>)
  : root.render(<Application/>);

reportWebVitals();
