import { messageInstance} from "@entities";
import { ProductMessage } from '@features';

export class Bot {
    firstName;
    lastName;
    messages;
    cartMessages;
    benefits;

    constructor(option, project) {
        this.firstName  = option?.firstName || "";
        this.lastName   = option?.lastName || "";
        this.benefits = 0;
        this.product = option?.product || {};
        const isAvaCombine = !!this.product?.id;

        this.messages   = [
            messageInstance.create({createdAt: project?.createdAt, ava: "", isAvaCombine: true, text:`<span class="bot__author">Brief</span> Hello ${this.firstName}! <br> Welcome to the illustration order section!`}, 'botMessage'),
            messageInstance.create({createdAt: project?.createdAt, ava: "", isAvaCombine: true, text:"In this chat our manager will help bring your custom illustration to life. To evaluate the project it is important for us to receive your detailed design assignment."}, 'botMessage'),
            messageInstance.create({createdAt: project?.createdAt, ava: "", isAvaCombine: true, text:`On the right you can see the questions on the "Brief" panel <span class="smile__wrapper"><img src="/images/smiles/point_at.svg" class="smile"/></span> <br>To save your time we have developed a list of questions. Please, answer them to speed up the work on your project.`}, 'botMessage'),
            messageInstance.create({createdAt: project?.createdAt, ava: "", isAvaCombine: isAvaCombine, text:`Once you answer the questions our team will review the design assignment and evaluate the project cost and deadlines. We are looking forward to your answers here. <br> Have a nice day <span class="smile__wrapper"><img src="/images/smiles/cute.svg" class="smile cute"/></span>`}, 'botMessage'),
        ]

        if(this.product?.id) {
            this.messages.push(messageInstance.create({ava: "", text: ProductMessage(this.product, 'bot')}, 'botMessage'));
        }
    }

    getAllMessages() {
        return this.messages;
    }


    getCartMessages(amount) {
      return [
        messageInstance.create({
                ava: "",
                text:`<h5 className="bot-cart__subtitle">
                        As illustrators we have developed
                        BriefStock to eliminate the intermediary
                        markups. It is our way to keep creativity
                        and to help clients save money. <br/>
                        And we are grateful for your support
                        <img src="/images/smiles/heart.svg" class="smile"/>
                    </h5>`
            }, 'botMessage')
        ]
    }

    getFreeProductMessages(amount) {
        return [
            messageInstance.create({
                    ava: "",
                    text:`<h5 className="bot-cart__subtitle">
                            Our artists offer you the 
                            opportunity to get this product 
                            under an <a href="/licenses">extended license</a> for 
                            free.
                        </h5>`
                }, 'botMessage')
            ]
    }

    getMessagesById() {}
}