import { useState, useMemo, useEffect } from 'react';

const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    // eslint-disable-next-line
    [ref]
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.disconnect();
    // eslint-disable-next-line
  }, []);

  if (!ref) {
    return;
  }

  return isIntersecting;
};

export default useOnScreen;
