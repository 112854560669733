import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import './adminProjectsWrapper.scss';

import { HorizontalTabsAdmin } from '@widgets';
import { 
  setIsMobileScreenViewAC,
  getIsMobileScreenView,
} from '@entities';


const AdminProjectsWrapper = ({ 
  children = ()=>{}, 
  isContentItemView = false, 
  setContentItemView = ()=>{},
}) => {
  const MIN_WIDTH = 670;
  const dispatch = useDispatch();

  const isMobileScreenView = useSelector(getIsMobileScreenView);
  const [isSmallWidth, setIsSmallWidth] = useState(window.innerWidth <= MIN_WIDTH);
  const [searchParams] = useSearchParams();

  let wrapperStyles = "admin-content d-flex jc-sb"
  const mobileStyles = ["mobile-admin-panel__wrapper"];
  const isHideTabs = isContentItemView && isSmallWidth;

  if(isContentItemView && isSmallWidth) {
    mobileStyles.push("content-open");
  };

  if(isSmallWidth) {
    wrapperStyles = mobileStyles.join(" ");
  }

  useEffect(() => {
    const handleResize = (event) => {
      const eventWidth = event.target.innerWidth;
      if (eventWidth <= MIN_WIDTH) {
        setIsSmallWidth(true);
      } else {
        setIsSmallWidth(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const project_asset_id = searchParams.get('project_asset_id');
    if(project_asset_id && isMobileScreenView) {
      setContentItemView(true)
    };
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    const header = document.querySelector('header');
    const main = document.querySelector('.main__content');

    if(isSmallWidth) {
      dispatch(setIsMobileScreenViewAC(true));
    }

    if(isContentItemView && isSmallWidth) {
      header.style.display = 'none';
      main.style.padding = '0';
    } else {
      header.style.display = '';
      main.style.padding = '';
    };
    // eslint-disable-next-line
  }, [isContentItemView, isSmallWidth]);

  return (
    <div className="admin">
        {!isHideTabs && <HorizontalTabsAdmin />}
        <div className={wrapperStyles}>
          {children}
        </div>
    </div>
  );
};

export default AdminProjectsWrapper;
