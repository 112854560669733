import { Validation } from '@shared/lib/utils';

const onSubscribeFormValid = (formData) => {
  let errorsState = {};

  const validationName = new Validation({
    field: formData.name,
    title: 'Name',
  })
    .isRequired()
    .maxLenght(30)
    .getErrors();

  const validationEmail = new Validation({
    field: formData.email,
    title: 'Email',
  })
    .isRequired()
    .isEmail()
    .maxLenght(64)
    .getErrors();

  if (validationName.length) {
    errorsState = {
      ...errorsState,
      name: validationName[0],
    };
  }

  if (validationEmail.length) {
    errorsState = {
      ...errorsState,
      email: validationEmail[0],
    };
  }

  return errorsState;
};

export default onSubscribeFormValid;
