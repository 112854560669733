import React from "react";
import './status.scss';
import {Icon} from '@ui-kit';

const Status = ({
    size      = 'md',
    className = '',
    ico       = '',
    isUserOnline
}) => {
    const classes = ['status__container', size,  ...className.split(' ')];

    if (!isUserOnline) {
        classes.push("checking");
    }

    if (ico) {
        return <Icon src={ico} alt="ava-ico"/>
    }

    return (
        <div className={classes.join(' ')}></div>
    )
}

export default Status;