import React from 'react';
import './licensesDescriptions.scss';

import { Text } from '@ui-kit';
import { LICENSES } from './licensesDescriptions.consts';

const LicensesDescriptions = () => {
  const extended = LICENSES.filter((item) => item.type === 'extended');
  const exclusive = LICENSES.filter((item) => item.type === 'exclusive');

  return (
    <div className="main__container licenses__container-description">
      <div className="licenses-extended d-flex" id="extended">
        <div className="extended__description">
          <Text as="h5" className="suptitle mb-12">
            License #1
          </Text>
          <Text as="h1" className="title mb-20">
            Extended commercial
          </Text>
          <Text as="h3" className="description">
            Just 4 limitations for this license
          </Text>
        </div>
        <div className="extended__content d-flex fw-wrap">
          {extended.map((item, index) => (
            <div key={`extended-item-${index}`} className="licenses__item">
              <Text as="h3" className="licenses__item-title mb-8">
                {item.title}
              </Text>

              <Text as="h5" className="licenses__item-description">
                {item.description}
              </Text>
            </div>
          ))}
        </div>
      </div>
      <div className="licenses-exclusive d-flex" id="exclusive">
        <div className="exclusive__description">
          <Text as="h5" className="suptitle mb-12">
            License #2
          </Text>
          <Text as="h1" className="title mb-20">
            Exclusive
          </Text>
          <Text as="h3" className="description">
            No restrictions
          </Text>
        </div>
        <div className="exclusive__content d-flex fw-wrap">
          {exclusive.map((item, index) => (
            <div
              key={`exclusive-item-${index}`}
              className="licenses__item exclusive"
            >
              <Text as="h3" className="licenses__item-title mb-8">
                {item.title}
              </Text>

              <Text as="h5" className="licenses__item-description">
                {item.description}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LicensesDescriptions;
