import React from 'react';
import './whatisprofitable.scss';

import { Text, Link, Avatar } from '@ui-kit';

const PROFITABLE_AVATARS = [
  {
    url: '44_avatar.jpg',
    size: 'xl',
  },
  {
    url: '51_avatar.jpg',
    size: 'xxl',
  },
  {
    url: '47_avatar.jpg',
    size: 'xl',
  },
];

const WhatIsProfitable = () => {
  return (
    <div className="profitable__container d-flex fd-column ai-center">
      <div className="profitable__avatars-group pr">
        {PROFITABLE_AVATARS.map((avatar, index) => (
          <Avatar
            key={index}
            src={avatar.url}
            size={avatar.size}
            className="profitable__avatar"
          />
        ))}
      </div>
      <div className="profitable__info d-flex ai-center fd-column">
        <Text as="h3" className="mb-8">What is more profitable for you?</Text>
        <Text as="h4">
          Can’t find the answer you’re looking for? Please chat to our friendly
          team.
        </Text>
      </div>
      <Link to="/admin/projects/create" size="lg">Start project</Link>
    </div>
  );
};

export default WhatIsProfitable;
