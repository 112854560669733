import { Icon } from '@ui-kit';
import DollarIcon from '@ui-kit/icons/dollar-gray700.svg';
import DollarOffIcon from '@ui-kit/icons/dollar-off-gray700.svg';
import EditIcon from '@ui-kit/icons/edit-3-gray700.svg';
import EyeOnIcon from '@ui-kit/icons/eye-gray700.svg';
import EyeOffIcon from '@ui-kit/icons/eye-off-gray700.svg';
import TrashIcon from '@ui-kit/icons/trash-gray700.svg';

export const ITEM_ACTIONS_LIST = [
    {component: (
        <div id="promote" className="link">
            <div className='pd'>
                <Icon src={DollarIcon} className="mr-12" />
                <h6>Promote</h6>
            </div>
        </div>
    )},
    {component: (
        <div id="notPromote" className="link">
            <div className='pd'>
                <Icon src={DollarOffIcon} className="mr-12" />
                <h6>Cancel promote</h6>
            </div>
        </div>
    )},
    {component: (
        <div id="edit" className="link">
            <div className='pd'>
                <Icon src={EditIcon} className="mr-12" />
                <h6>Edit</h6>
            </div>
        </div>
    )},
    {component: (
        <div id="hide" className="link">
            <div className='pd'>
                <Icon src={EyeOffIcon} className="mr-12" />
                <h6>Hide</h6>
            </div>
        </div>
    )},
    {component: (
        <div id="activate" className="link">
            <div className='pd'>
                <Icon src={EyeOnIcon} className="mr-12" />
                <h6>Activate</h6>
            </div>
        </div>
    )},
    {component: (
        <div id="delete" className="link">
            <div className='pd'>
                <Icon src={TrashIcon} className="mr-12" />
                <h6>Delete</h6>
            </div>
        </div>
    )},
  ];

  export const STATUS_ACTIONS_LIST = [
    /*{name: 'Free today', order: 'fieldType', value: 'free'},
    {name: 'Potentially free', order: 'fieldType', value: 'potentially'},*/
    {name: 'Extended commercial', order: 'isPromoted', value: '0'},
    {name: 'Promoted', order: 'isPromoted', value: '1'},
  ];