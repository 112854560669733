import React from 'react';
import './badge.scss';
import { Text, Icon } from '@ui-kit';

const Badge = (props) => {
  const { 
    text, 
    size='md', 
    theme='gray', 
    className='', 
    status, 
    icon 
  } = props;

  const classes = [
    'badge__container',
    'd-flex-inline',
    'ai-center',
    size,
    theme,
    ...className.split(' '),
  ];

  return (
    <div className={classes.join(' ')}>
      {icon && <Icon src={icon}className='badge__icon' alt="icon-badge" />}
      {status && <div className="badge__status"></div>}
      <Text as="span" className="badge__text">
        {text}
      </Text>
    </div>
  );
};

export default Badge;
