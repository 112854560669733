import React from 'react';
import '../profile.scss';

import { Input, Text, Icon, Tooltip } from '@ui-kit';
import { CountriesDropDown } from '@widgets';
import HelpIcon from '@ui-kit/icons/help-circle-gray400.svg';
import AlertRedIcon from '@ui-kit/icons/alert-circle-error500.svg';

const ProfileAddress = ({ formData, errors, onChangeInput, user }) => {

    const onChooseCountry = (country) => {
      const code = country?.code;

      onChangeInput({ target: { name: 'country', value: code } });
    };

    const renderAdressIcons = () => {
        let icon = HelpIcon;

        if (errors?.address) {
            icon = AlertRedIcon;
        }

        return <Icon src={icon} alt="help__icon" className="help__icon" />;
    };

    const addressChangeeTooltipContent = (
        <>
          <Text as="h6" className="tolltipe__title">
            Please specify your full postal address
          </Text>
          <Text as="h6" className="tolltipe__subtitle">
            This is necessary for the correct payment <br />
            of taxes according to the requirements <br />
            of the EU tax service.
          </Text>
        </>
    );

    return (
        <>
        <div className="profile__inputs-drop mb-43 pr">
            <label className="dropdown-label">Country</label>
            <CountriesDropDown
                errors = {errors}
                current={formData?.country || ''}
                onChooseCountry={onChooseCountry}
            />
            <div className="profile__input">
                <Input 
                    placeholder="Postal code"
                    autoComplete="postal-code"
                    name="postCode"
                    type="text"
                    label="Postal code"
                    value={formData?.postCode || ''}
                    onChange={onChangeInput}
                    className={errors.postCode && 'form-item__error'}
                />
                {errors.postCode && (
                    <Text as="span" className="error">
                        {errors.postCode}
                    </Text>
                )}
            </div>
        </div>
        <div
            className={`profile__input ${errors?.address && 'error-row'}`}
          >
            <Input
              type="text"
              name="address"
              autoComplete="address-line1"
              label="Address"
              className="pright"
              onChange={onChangeInput}
              isError={errors?.address}
              errorText={errors?.address}
              value={formData?.address || ''}
              placeholder="Enter your address"
            />

            <Tooltip
              position="top"
              direction="revers"
              ancorPosition="anchor-right-bottom"
              content={addressChangeeTooltipContent}
            >
              {renderAdressIcons()}
            </Tooltip>
          </div>
        </>
    )
};

export default ProfileAddress;