import settingsService from '../api';
import { SET_SETTINGS } from './settings.constants';


export const getSettingsTC = () => {
  return async (dispatch) => {
    try {
      const resp = await settingsService.getSettings();

      if (!resp?.data) {
        return;
      }

      dispatch(setSettingsAC(resp.data));
    } catch (error) {
      console.log(error);
    }
  };
};

//ACTIONS
export const setSettingsAC = (payload) => {
  return {
    type: SET_SETTINGS,
    payload,
  };
};
