import React from "react";
import './tooltip-without-anchor.scss';

const TooltipWithoutAnchor = ({children}) => {
    return (
        <div className="tooltipe-unanchor__container">
            {children}
        </div>
    )
}

export default TooltipWithoutAnchor;