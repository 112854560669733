import React, { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './datePicker.scss';

import { useDispatch } from 'react-redux';
import { DateRangePicker } from 'react-date-range';
import { 
  FollowingDataDTO,
  setProjectPropertieAC,
  setisProjectChangeAC,
} from '@entities';
import { Button } from '@ui-kit';
import { deepArrayCopy } from "@shared";


const ModalDatePicker = ({
  showCalendar = () => {},
  stage = 0,
  followingData = {}
}) => {
  const dispatch = useDispatch();
  const [followingDataLocal, setFollowingDataLocal] = useState(deepArrayCopy(followingData));

  const newFollowingData = new FollowingDataDTO().setState(deepArrayCopy(followingData));

  const startDate = new Date(followingDataLocal[0].date);
  const endDate = new Date(followingDataLocal[7].date);
  let currentStage = stage;
  let nextStep = 1;

  if (stage === 1 || stage === 7) {
    nextStep = stage;
  } else {
    nextStep = stage + 1;
  }
  if (stage === 8) {
    currentStage = stage - 1;
    nextStep = stage - 1;
  }

  const startStep = new Date(followingDataLocal[currentStage].date);
  const endStep = new Date(followingDataLocal[nextStep].date);

  const selectionRange = {
    startDate: startDate,
    endDate: startDate,
    disabled: true,
    color: '#F2F4F7',
    key: 'selection',
  };

  const selection2 = {
    startDate: startStep,
    endDate: endStep,
    color: '#648CE6',
    autoFocus: true,
    key: 'selection1',
  };

  const selectionRange3 = {
    startDate: endDate,
    endDate: endDate,
    disabled: true,
    color: '#F2F4F7',
    key: 'selection',
  };

  const onBackgroundClick = (e) => {
    if (e.target.id === 'picker-background') {
      setFollowingDataLocal(followingData);
      showCalendar(false);
    }
  };

  const handleSelect = ({ selection1 }) => {
    const { startDate, endDate } = selection1;

    if (new Date(startDate).getTime() === new Date(endDate).getTime()) {
      return;
    }

    const followingState = newFollowingData
    .setData(currentStage, startDate, endDate)
    .getState();

    setFollowingDataLocal([...followingState]);
  };

  const onApplyFollowingData = () =>{
    dispatch(setisProjectChangeAC());
    dispatch(
      setProjectPropertieAC({
        key: 'followingData',
        value: followingDataLocal,
        callback: () => {
          showCalendar(false);
        },
      })
    );
  }

  const cancel = () => {
    setFollowingDataLocal(followingData);
    showCalendar(false);
  }

  return (
    <div
      id="picker-background"
      className="background-default"
      onMouseDown={onBackgroundClick}
    >
      <div className="date-range__wrapper">
        <DateRangePicker
          ranges={[selectionRange, selection2, selectionRange3]}
          onChange={handleSelect}
          showMonthAndYearPickers={false}
          editableDateInputs={true}
          dateDisplayFormat={'MMM-dd-yyyy'}
          dayDisplayFormat={'dd'}
          monthPicker={false}
          showSelectionPreview={false}
          className="date-range__wrapper-item"
          color="#973eb4"
        />
        <div className="date-range__wrapper-active">
          <Button
            className="date-range__btn"
            theme="secondary-gray"
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button className="date-range__btn" onClick={onApplyFollowingData}> Apply</Button>
        </div>
      </div>
    </div>
  );
};

export default ModalDatePicker;
