import React, { useState, useCallback } from 'react';
import './faqInfo.scss';

import { Text, Accordion } from "@ui-kit";
import { FAQINFO } from "./faq-info.consts";

const FaqInfoComponent = () => {
  const [accordionState, setAccordionState] = useState(FAQINFO);

  const choosingItem = useCallback(
    (value) => {
      const newState = accordionState.map((item) => {
        item.value === value
          ? (item.active = !item.active)
          : (item.active = false);
        return item;
      });

      setAccordionState(newState);
    },
    [accordionState]
  );

  return (
    <div className="info-panel">
      <Text as="h3" className="faq-info__tittle">
        Questions about projects
      </Text>
      <Text as="h6" className="faq-info__suptittle">
        Here you can find out how we work before you start working with us
      </Text>
      <Accordion size="sm" onClick={choosingItem} items={FAQINFO} />
    </div>
  );
};

export default FaqInfoComponent;
