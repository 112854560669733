import React from 'react';
import './check-box.scss';

const CheckBox = ({ 
  label     = '',
  checked   = '',
  className = '',
  theme     = 'checkbox',
  size      = 'md',
  name      = 'checkbox',
  onChange = () => {} 
}) => {
  const classes = ['check__container', ...className.split(' '), theme, size];

  if (checked) {
    classes.push('checked');
  }

  return (
    <label className={classes.join(' ')} onChange={onChange} name={name}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      {label}
      <span className="checkmark"></span>
    </label>
  );
};

export default CheckBox;
