import React from 'react';
import './footer-link.scss';
import { Link } from 'react-router-dom';

const FooterLink = ({ 
    to      = '',
    title   = '',
    isBadge = false,
    theme   = 'dark-gray'
}) => {
    const classes = ['footer-link__container', theme];
    return (
        <div className={classes.join(' ')}>
            <Link to={to}>{title}</Link>
            {isBadge && <span>New</span>}
        </div>
    )
};

export default FooterLink;