import React, {useState, useEffect, useRef} from 'react';
import { useDispatch } from 'react-redux';

import './modal-recovery.scss';
import { RecoveryPassForm, PassRecovered } from '@widgets';
import { setModalRecoveryShowAC, setModalLoginShowAC } from '@entities';
import { Icon } from '@ui-kit';
import CloseIcon from '@ui-kit/icons/x-gray700.svg';

const ModalRecovery = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [passwordIsRecovered, setPasswordIsRecovered] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if(ref.current) {
      const mainWrapper = ref.current.parentNode ;
      mainWrapper.style = `overflow-y: hidden;`;
    }
  }, []);
  
  const onBackgroundClick = (e) => {
    if (e.target.id === 'recovery-background') {
      closeModal();
    }
  };

  const closeModal = () => {
    dispatch(setModalRecoveryShowAC(false));
    const mainWrapper = ref.current.parentNode ;
    mainWrapper.style = '';
  };

  const goToLogin = () => {
    dispatch(setModalRecoveryShowAC(false));
    dispatch(setModalLoginShowAC(true));
  };

  const recoveryPassword = () => {
    if (passwordIsRecovered) {
      return (
        <PassRecovered
          setPasswordIsRecovered={setPasswordIsRecovered}
          email={email}
          goToLogin={goToLogin}
        />
      );
    }

    return (
      <RecoveryPassForm
        setPasswordIsRecovered={setPasswordIsRecovered}
        setEmail={setEmail}
        goToLogin={goToLogin}
      />
    );
  };

  return (
    <div 
      id="recovery-background" 
      className="recovery-modal"
      ref={ref}
      onMouseDown={onBackgroundClick}
    >
      <div className="recovery-modal__wrapper">
          {recoveryPassword()}
          <div className="modal__close" onClick={closeModal}>
            <Icon src={CloseIcon} alt="close" />
          </div>
      </div>
    </div>
  );
};

export default ModalRecovery;
