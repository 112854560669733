import React from 'react';
import { Text, } from '@ui-kit';


const ExclusiveRate = () => {
  return (
    <div className="rate__wrapper-content d-flex ai-center mb-34">
      <div className='rate__description'>
        <Text as="h3" className="rates__title-tab rates__title-margin">
          New project
        </Text>
        <Text as="span" className="rates__description-tab align">
        Do you have a unique project? We are ready to create a custom illustration, 
        just the way you want it to be! The working time depends on details and 
        complexity. Here are the examples.
        </Text>

        <Text as="h5" className="rates__description-tab">
        *Time indicated in the example is estimated. Fill the brief and our specialist 
          will calculate the time needed to complete your project.
        </Text>
      </div>
    </div>
  );
};

export default ExclusiveRate;
