import React from "react";
import './bringapp.scss';
import Slider from 'react-slick';
import {BRINGS_SRC} from './bring.constants';
import {CarouselArrow, Icon} from '@ui-kit';

const BringApp = () => {
    const isBanners = BRINGS_SRC.length > 1;

    let settings = {
        infinite : true,
        arrows   : isBanners,
        fade     : true,
        dots     : false,
        prevArrow: <CarouselArrow direction="prev" />,
        nextArrow: <CarouselArrow direction="next" />
    };

    return (
        <div className="bring__container">
            <Slider {...settings} >
                {BRINGS_SRC.map((item, index) => (
                    <div key={`bring-${index}`} className="bring__banner">
                        <div className={`bring__background background__fill-${index+1}`}></div>
                        <Icon src={item.mob} className="banner-mobile"/>
                        <Icon src={item.img} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default BringApp;