import React, { useRef } from "react";
import "./cookiesPolicyPopup.scss";
import { Portal } from '@shared';
import { Button, Text, Link} from "@ui-kit";
import { 
    COOKIE_POLICY_URL,
  } from "@app/routes";

const ModalCookiesPolicy = () => {
    const cookieEl = useRef(true);

    const closeCookie = () => {
        sessionStorage.setItem('decline_cookie', true);
        cookieEl.current.classList.add("hidden");
    }

    const allowCookie = () => {
        localStorage.setItem('accept_cookie', true);
        cookieEl.current.classList.add("hidden");
    }

    return (
        <Portal className="portal-cookie">
           <div className="cookie__container" ref={cookieEl}>
                <div className="cookie__content">
                    <Text as="h6" className="cookie__title">We use third-party cookies in order to personalise your experience</Text>
                    <div className="d-flex">
                        <Text as="span" className="cookie_link">Read our&nbsp;</Text>
                        <Link to={COOKIE_POLICY_URL} theme="text" className="cookie_link">Cookie Policy.</Link>
                    </div>
                </div>
                <div className="cookie__actions">
                    <Button 
                        theme="secondary-gray" 
                        size="sm" 
                        className="cookie-decline" 
                        onClick={closeCookie}
                    >Decline</Button>
                    <Button 
                        size="sm" 
                        className="cookie-allow" 
                        onClick={allowCookie}
                    >Allow</Button>
                </div>
           </div>
        </Portal>
        )
}

export default ModalCookiesPolicy;