
import ReCAPTCHA from 'react-google-recaptcha';
import { onLoad } from '@lib/utils';

export function startRecaptcha(recaptchaRef) {
  return new Promise(async resolve => {
    const _resolve = () => {
      recaptchaRef.current.execute();
      resolve();
    };

    if (process.env.REACT_APP_CAPTCHA_DISABLE === 'true') {
      _resolve();
    } else {
      await onLoad(() => !!window.grecaptcha);
      window.grecaptcha.ready(_resolve);
    }
  });

}

const Recapcha = (props) => {
  if (process.env.REACT_APP_CAPTCHA_DISABLE === 'true') {
      props.recaptchaRef.current = {
        execute: () => props.onChange('012345'),
        reset: () => {},
      };

    return;
  }

  const inheritedProps = Object.assign({}, props);
  delete inheritedProps.className;

  return (
    <div className={inheritedProps.wrapperClassName}>
      <ReCAPTCHA
        {...inheritedProps}
        ref={inheritedProps.recaptchaRef}
      />
    </div>
  );
};

Recapcha.start = startRecaptcha;

export default Recapcha;
