export class SimilarProductDTO {
  id;
  name;
  email;
  firstName;
  lastName;
  linkId;
  pathToBrowse;
  pathToPreview;
  isFree;
  compatibility_draw;
  illustration_formats;
  user = {};

  constructor(option) {
    this.id = option.id;
    this.price = option.price;
    this.name = option.name;
    this.linkId = option.linkId;
    this.isFree = option.isFree;
    this.pathToBrowse = option.pathToBrowse;
    this.pathToPreview = option.pathToPreview;
    try {
        const formats = option.illustration_formats.replaceAll('&quot;', '"')
        this.illustration_formats = option.illustration_formats ? JSON.parse(formats) : [];
    } catch (e) {
        return console.error("BAD illustration_formats", this.id, option.illustration_formats);
    }
    try {
      const compatibility = option.compatibility_draw.replaceAll('&quot;', '"')
      this.compatibility_draw = option.compatibility_draw ? JSON.parse(compatibility) : [];
    } catch (e) {
        return console.error("BAD compatibility_draw", this.id, option.compatibility_draw);
    }
    this.user = {
      email: option?.email,
      firstName: option?.firstName || 'BriefStock',
      lastName: option?.lastName || '',
      ava: option?.ava || `2_avatar.jpg`,
    };
  }
}
