import { 
    BASE_URL,
    LICENSES_URL,
    BROWSE_SEARCH_URL,
    ADMIN_PROJECT_CREATE_URL,
  } from "@app/routes";

export const HEADER_NAVIGATION_LOGO = [
    { title: 'LOGO', path: BASE_URL, ico:"/images/main_logo-b.png", classes:'header__logo-icon' },
]

export const HEADER_NAVIGATION_LOGO_CUT = [
    { title: 'LOGO', path: BASE_URL, ico:"/images/logo.png", classes:'header__logo-icon' },
]

export const HEADER_NAVIGATION_LINKS= [
    ...HEADER_NAVIGATION_LOGO,
    { title: 'Gallery', path: BROWSE_SEARCH_URL },
    { title: 'Order Illustrations', path: ADMIN_PROJECT_CREATE_URL },
    { title: 'License', path: LICENSES_URL },
];

