import React, { useEffect, useState } from 'react';
import './modalUploadArchive.scss';
import { RadioButtons } from '@ui-kit';
import { useDispatch } from 'react-redux';
import { UploadImage, FileLoaderWithProgress } from '@widgets';
import {
  updateAdminProjectPropertyTC,
  updateAdminProjectBinaryPropertyTC,
  setisProjectChangeAC,
  setProjectPropertieAC,
  setCurrentStepsHoursAC,
} from '@entities';
import {
  RADIO_ITEM_LINK,
  RADIO_ITEM_FILE,
  UPLOAD_LINK,
  UPLOAD_FILE,
} from './modalUploadArchive.consts';
import { Text, Button, Input } from '@ui-kit';

import { IconFeatured } from '@ui-kit';
import CloudIcon from '@ui-kit/icons/download-cloud-gray600.svg';

const ModalUploadArchive = ({ setUploadArchiveShow = ()=>{}, archive, isUploadArchiveShow, getUploadArchivePopUp }) => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [archiveValue, setArchiveValue] = useState({});
  const [radioItemsLink, setRadioItemsLink] = useState(RADIO_ITEM_LINK);
  const [radioItemsFile, setRadioItemsFile] = useState(RADIO_ITEM_FILE);
  const [radioContent, setRadioContent] = useState(UPLOAD_LINK); //UPLOAD_LINK //UPLOAD_FILE

  const isArchiveString = archiveValue?.type === 'string';
  const isArchiveValue = archiveValue.value !== '';
  const isArchiveIsString = archive && archive.includes('http');

  useEffect(() => {
    if (isArchiveIsString){
      checkItem(UPLOAD_LINK);
      setArchiveValue({ type: 'string', value: archive });
    }
    else {
      checkItem(UPLOAD_FILE);
      setArchiveValue({ type: 'binary', value: '' });
    }
    // eslint-disable-next-line
  }, []);

  const onBackgroundClick = (e) => {
    if (e.target.id === 'chossing-archive-background') {
      getUploadArchivePopUp({ state: false });
    }
  };

  const checkItem = (key) => {
    setArchiveValue('');

    if (key === UPLOAD_LINK) {
      setErrors({});
      radioItemsLink[0].isActive = true;
      radioItemsFile[0].isActive = false;
      setArchiveValue({ type: 'binary', value: '' });
    }

    if (key === UPLOAD_FILE) {
      radioItemsFile[0].isActive = true;
      radioItemsLink[0].isActive = false;
      setArchiveValue({ type: 'string', value: '' });
    }

    setRadioContent(key);
    setRadioItemsLink(radioItemsLink);
    setRadioItemsFile(radioItemsFile);
  };

  const filePreviewWasUploaded = (files) => {
    setErrors({});

    const filesArray = Array.from(files);

    setArchiveValue({ type: 'binary', value: filesArray[0] });
  };

  const setArchive = (e) => {
    setArchiveValue({ type: 'string', value: e?.target?.value });
  };

  const validateUrl = (value) => {
    const link = !value.includes('http') ?
        'http://' + value : value;

    const regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    if (regex.test(link)) {
      return link;
    }

    setErrors({link: 'Not valid url'});
    return false;
  };

  const onPublish = () => {
    setErrors({});

    const stage = isUploadArchiveShow.stage;
    const followingId = +isUploadArchiveShow.followingId;
    const stageValue = {
      [stage]: { hours: 0, minutes: 0, date: Date().now },
    }
    dispatch(setCurrentStepsHoursAC(stageValue));
    dispatch(setisProjectChangeAC());

    if(followingId >= 6) {
      dispatch(setProjectPropertieAC({ key: 'followingId', value: followingId + 1 }));
    }

    if (archiveValue.type === 'binary') {
      return dispatch(
        updateAdminProjectBinaryPropertyTC({
          name: 'archive',
          value: archiveValue.value,
          callback: () => {
            setUploadArchiveShow({ state: false });
        },
        })
      );
    }

    const link = validateUrl(archiveValue.value);
    if(!link) return;

    dispatch(
      updateAdminProjectPropertyTC({
        name: 'archive',
        value: link,
        callback: () => {
          setUploadArchiveShow({ state: false });
        },
      })
    );
  };

  const renderContent = (key) => {
    if (key === UPLOAD_LINK) {
      return (
        <div>
          <Input
            placeholder="Archive link"
            name="archive_link"
            label="Archive link"
            onChange={setArchive}
            onFocus = {() => checkItem(UPLOAD_LINK)}
            className={errors.hours && 'form-item__error'}
            value={isArchiveString ? archiveValue.value : ''}
          />
        </div>
      );
    }

    if (key === UPLOAD_FILE) {
      return (
        <div onClick={()=> checkItem(UPLOAD_FILE)}>
          <div
            className={`dropdown-uploader__container ${
              errors && errors.uploadImage && 'progress-error'
            }`}
          >
            <UploadImage
              wasUploaded={filePreviewWasUploaded}
              maxSize={10485760} //10MB
              accept=".zip,.rar,.7zip"
              errorCallback={(error) => setErrors(error)}
            >
              <div className="uploader-wrapper d-flex">
                <IconFeatured src={CloudIcon} theme="gray" />
                <div className="dropdown-uploader__text">
                  <Text as="h6" className="marked">
                    Click to upload
                  </Text>
                  <Text as="h6">or drag and drop</Text>
                </div>
                <Text as="p" className="uploader-text">
                  SVG, PNG, JPG or GIF (max. 800x400px)
                </Text>
              </div>
            </UploadImage>
          </div>
          {errors && errors.uploadImage && (
            <Text as="span" className="error">
              {errors.uploadImage}
            </Text>
          )}
          {!isArchiveString && isArchiveValue && (
            <FileLoaderWithProgress file={archiveValue.value} />
          )}
        </div>
      );
    }
  };

  return (
    <div
      id="chossing-archive-background"
      className="background-default upload-archive"
      onMouseDown={onBackgroundClick}
    >
      <div className="upload-archive-popup__wrapper">
        <Text as="h4" className="upload-archive-popup__title">
          Upload and attach files
        </Text>
        <Text as="h6" className="upload-archive-popup__subtitle">
          Attach a link to the project archive or download the project archive.
        </Text>

        <div className="upload-archive-popup__radio">
          <RadioButtons radioItems={radioItemsLink} checkItem={checkItem} />
        </div>
        <div
          className={`upload-archive-popup__link ${
            radioContent !== UPLOAD_LINK && 'disabled'
          }`}
        >
          {renderContent(UPLOAD_LINK)}
        </div>
        {errors.link && (
            <Text as="span" className="error">
              {errors.link}
            </Text>
          )}

        <div className="upload-archive-popup__radio">
          <RadioButtons radioItems={radioItemsFile} checkItem={checkItem} />
        </div>
        <div
          className={`upload-archive-popup__file ${
            radioContent !== UPLOAD_FILE && 'disabled'
          }`}
        >
          {renderContent(UPLOAD_FILE)}
          {errors.hours && (
            <Text as="span" className="error">
              {errors.hours}
            </Text>
          )}
        </div>
        {errors.minutes && (
          <Text as="span" className="error">
            {errors.minutes}
          </Text>
        )}
        <div className="upload-archive-popu__actions">
          <Button
            className="upload-archive-popu__btn publish"
            onClick={onPublish}
          >
            Confirm
          </Button>
          <Button
            className="upload-archive-popu__btn"
            theme="secondary-gray"
            onClick={() => getUploadArchivePopUp({ state: false })}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalUploadArchive;
