import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import './legal.scss';

import { getServerError } from '@entities';
import { Input, Text, Tooltip, Icon, CheckBox } from '@ui-kit';
import QestionIcon from '@ui-kit/icons/help-circle-gray400.svg';
import InfoIcon from '@ui-kit/icons/alert-circle-error500.svg';

const Legal = ({
  errors = {},
  formData = {},
  onChangeInput = () => {},
  countryVatPlaceholderArray = [],
  setConfirmData = ()=>{},
  setIsAcceptPrivacyPolicy = ()=>{}
}) => {
  const serverError = useSelector(getServerError);
  const [isChecked, setIsChecked] = useState(false);
  const label = isChecked ? 'Tax' : 'Tax *';

  const isVatPlaceholderExist = countryVatPlaceholderArray.length;

  const vatError = serverError.filter(item => item.msg === 'Invalid personal tax number format.');
  if(vatError.length) {
    errors['tax'] = vatError[0].msg;
  }
  
  let vatPlaceholder = '';

  if (isVatPlaceholderExist) {
    vatPlaceholder = 'For your country: ';
    countryVatPlaceholderArray.forEach((country, index) => {
      vatPlaceholder += `${country.tax_example}`;
      if (index !== countryVatPlaceholderArray.length - 1) {
        vatPlaceholder += ', ';
      }
    });
  } else {
    vatPlaceholder = 'CIF/VAT number';
  }

  const renderInputNotification = () => {
    if (errors?.tax) {
      return 'form-item__error ';
    }
  };

  const isError = errors?.tax ? InfoIcon : QestionIcon;

  const legalTooltipContent = (
    <>
      <Text as="h6" className="tooltipe__title">
        All taxes applied to your order vary by <br />
        billing area
      </Text>

      <Text as="h6" className="tooltipe__subtitle">
        Please enter your CIF/VAT number to specify <br />
        the applicable CIF/VAT rate.
      </Text>
    </>
  );

  const checkItem = (event) => {
    const value = event.target.checked;
    setIsChecked(value);
    formData['notCheckTax'] = value;
    
    if (value) {
      delete formData['tax'];
    };
    
    setConfirmData(false);
    setIsAcceptPrivacyPolicy(false);
  };


  return (
    <>
      <div className="legal__item pright">
        <Input
          required
          type="text"
          name="company"
          label="Company name *"
          onChange={onChangeInput}
          isError={errors?.company}
          placeholder="Company name"
          errorText={errors?.company}
          value={formData?.company || ''}
        />
      </div>

      <div className="legal__item pright">
        <div className="d-flex pr">
          <Input
            isDisabled={isChecked}
            required={!isChecked}
            name="tax"
            type="text"
            label={label}
            onChange={onChangeInput}
            value={formData?.tax || ''}
            placeholder={vatPlaceholder}
            className={renderInputNotification()}
          />

          <Tooltip
            content={legalTooltipContent}
            position="top"
            direction="revers"
            ancorPosition="anchor-right-bottom"
          >
            <Icon
              src={isError}
              className="question__icon"
              alt="question__icon"
            />
          </Tooltip>
        </div>

        {errors.tax && (
          <Text as="span" className="error">
            {errors.tax}
          </Text>
        )}

        {!isVatPlaceholderExist && (
          <Text as="span" className="warning">
            Be careful with tax numbers. We could not check in the existing
            database
          </Text>
        )}

        <div className="billing-form__row-full mt-12 mb-24">
          <CheckBox
            theme="radio"
            checked={isChecked} 
            onChange={checkItem} 
            label="There is no CIF/VAT in my country"
          />
        </div>

      </div>
    </>
  );
};

export default Legal;
