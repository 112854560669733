import React from "react";
import './accordion.scss';

import {Text, Icon} from '@ui-kit';
import MinusIcon from '@ui-kit/icons/minus-circle-gray400.svg';
import PlusIcon from '@ui-kit/icons/plus-circle-gray400.svg';

const AccordionItem = ({item={}, size}) => {
    const classes = ['accordion__container-item', 'pr', size]
    return (
        <div className={classes.join(' ')}>
            <div className="accordion__ico">
                <Icon src={item.active?MinusIcon:PlusIcon}/>
            </div>
            <Text as="h4" className="title">
                {item.title}
            </Text>
            {item.active && 
                <Text as="h5" className="description mt-8">
                    {item.description}
                </Text>
            }
        </div>
    )
}

export default AccordionItem;