import { Validation } from '@shared/lib/utils';

const onJobFormValid = (formData) => {
    let errorsState = {};

    const validationFirstName = new Validation({
        field: formData.firstName,
        title: 'First name',
    })
        .isRequired()
        .maxLenght(30)
        .getErrors();

    const validationLastName = new Validation({
        field: formData.lastName,
        title: 'Last name',
    })
        .isRequired()
        .maxLenght(30)
        .getErrors();

    const validationEmail = new Validation({
        field: formData.email,
        title: 'Email',
    })
        .isRequired()
        .isEmail()
        .maxLenght(64)
        .getErrors();

    const validationLink = new Validation({
        field: formData.link,
        title: 'Link',
    })
        .isRequired()
        .isUrl()
        .maxLenght(200)
        .getErrors();

    let validationLinkTask = '';

    if(formData.link_task) {
        validationLinkTask = new Validation({
            field: formData.link_task,
            title: 'Link on task',
        })
            .isUrl()
            .maxLenght(200)
            .getErrors();
    }

    const validationAbout = new Validation({
        field: formData.about,
        title: 'About',
    })
        .isRequired()
        .maxLenght(3000)
        .getErrors();

    const validationRole = new Validation({
        field: formData.role,
        title: 'Role',
    })
        .isRequired()
        .getErrors();

    if (validationFirstName.length) {
        errorsState = {
        ...errorsState,
        firstName: validationFirstName[0],
        };
    }

    if (validationLastName.length) {
        errorsState = {
        ...errorsState,
        lastName: validationLastName[0],
        };
    }
        
    if (validationEmail.length) {
        errorsState = {
        ...errorsState,
        email: validationEmail[0],
        };
    }

    if (validationLink.length) {
        errorsState = {
        ...errorsState,
        link: validationLink[0],
        };
    }

    if (validationLinkTask.length) {
        errorsState = {
        ...errorsState,
        link_task: validationLinkTask[0],
        };
    }

    if (validationAbout.length) {
        errorsState = {
        ...errorsState,
        about: validationAbout[0],
        };
    }

    if (validationRole.length) {
        errorsState = {
        ...errorsState,
        role: validationRole[0],
        };
    }

    return errorsState;
};

export default onJobFormValid;
