import React from 'react';
import './totalproducts.scss';
import { useSelector } from 'react-redux';
import {
  getCurrenteSearch,
  getPaginationAmount,
  getCurrentProducts,
  getPublishedEntireAmount,
} from '@entities';
import { Text } from '@ui-kit';

const TotalProduct = () => {
  const totalAmount = useSelector(getPublishedEntireAmount);
  const searchCurrentValue = useSelector(getCurrenteSearch);
  const paginationAmount = useSelector(getPaginationAmount);
  const products = useSelector(getCurrentProducts);

  const renderContent = () => {
    if (searchCurrentValue) {
      const count = products.length ? paginationAmount : 0;

      return (
        <Text as="h5" className="total__wrapper result">
          There are <Text as="span">{count}</Text> results for "{searchCurrentValue}"
        </Text>
      );
    }

    return (
      <Text as="h5" className="total__wrapper">
        There are <Text as="span">{totalAmount}</Text> illustrations now!
      </Text>
    );
  };

  return renderContent();
};

export default TotalProduct;
