import React from 'react';
import './icon-featured.scss';

/*
* <Icon styles={{paddingRight: '15px'}} src={Temp} alt="React Logo"/>
*/
const IconFeatured = ({ 
  src, 
  alt = 'icon', 
  className ='', 
  theme = 'primary', 
  size = 'md', 
  ...options 
})=> {

  const classes = ['icon-featured__container', 'pr', size, theme, className.split(' ')]

  return (
    <div  className={classes.join(' ')}>
      <img 
        {...options}
        src={src} 
        alt={alt}
      />
    </div>
  );
}

export default IconFeatured;