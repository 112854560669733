

export const onLoad = async checkIsLoaded => {

    checkIsLoaded = checkIsLoaded || (() => document.readyState === 'complete');

    return new Promise(resolve => {

        function delay() {
            setTimeout(() => resolve(), 200);
        }

        if ( checkIsLoaded() ) {

            delay();

        } else {

            document.onreadystatechange = function () {

                if ( checkIsLoaded() ) delay();

            }
        }

    });
};