import { createSelector } from 'reselect';

export const getProducts = (state) => state.productsData;

export const getCurrentProducts = createSelector(
  getProducts,
  (productsData) => productsData.products
);

export const getIsLoading = createSelector(
  getProducts,
  (productsData) => productsData.isLoading
);

export const getAllAmount = createSelector(
  getProducts,
  (productsData) => productsData.entireAmount
);

export const getPublishedEntireAmount = createSelector(
  getProducts,
  (productsData) => productsData.publishedEntireAmount
);

export const getMonthlyAmount = createSelector(
  getProducts,
  (productsData) => productsData.monthlyAmount
);

export const getPaginationAmount = createSelector(
  getProducts,
  (productsData) => productsData.paginationAmount
);

export const getMsConnected = createSelector(
  getProducts,
  (productsData) => productsData.msConnected
);
