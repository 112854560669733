import React from 'react';
import { Avatar, Icon } from '@ui-kit';
import { geShortCut } from '@shared';
import { format } from "date-fns";
import InfoRedIcon from '@ui-kit/icons/alert-circle-warning500.svg';


const UserItem = ({ user = {}, onClick = ()=>{} }) => {
  const {
    id,
    ava,
    firstName,
    lastName,
    createdAt,
    role,
    subRole,
    email,
    isSelected,
    isActivated,
  } = user;
  
  const upperCase = (word) => {
    if (!word) {
      return;
    };
    const lowerCase = word.toLowerCase();
    return lowerCase.replace(lowerCase[0], lowerCase[0].toUpperCase());
  };


  const renderEmail = () => {
    if (!isActivated) {
      return (
        <div className="user-email">
          <div className="d-flex noactivated">
            <Icon src={InfoRedIcon} />
            <h6>{email || ''}</h6>
          </div>
        </div>
      );
    };

    return (
      <div className="user-email">
        <h6>{email || ''}</h6>
      </div>
    );
  };

  const formatDate = () => {
    return format(createdAt, "dd-MM-yyyy");
  }

  const formatTime = () => {
    return format(createdAt, "HH:mm:ss");
  }

  return (
    <div
      className={`admin__table-row ${isSelected && 'current'}`}
      onClick={() => onClick(id)}
    >
      <div className="d-flex ai-center user-name">
        <div className="pr user-avatar__wrapper">
          <Avatar 
            src={ava} 
            text ={geShortCut(firstName, lastName)}
          />
        </div>
        <div className="d-flex fd-column user-firstname">
          <h5>
            {firstName || ''}
          </h5>
          <h6>#{id}</h6>
        </div>
      </div>

      <div className="user-surname">
        <h5>
          {lastName || ''}
        </h5>
      </div>

      <div className="user-role">
        <h5>{upperCase(role)}</h5>
        <h6>{upperCase(subRole) || ''}</h6>
      </div>

      {renderEmail()}

      <div className="user-create">
        <h5>{formatDate()}</h5>
        <h6>{formatTime()}</h6>
      </div>
    </div>
  );
};

export default UserItem;
