import { ApiService } from '@shared/api';

class UsersService extends ApiService {
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.baseUrl = '/user';
  }

  async getUsers(params) {
    return await this.instance.get(`${this.baseUrl}/users?${this.generateQueryString(params)}`);
  }

  async getUsersByRole(params) {
    return await this.instance.get(`${this.baseUrl}/roles?${this.generateQueryString(params)}`);
  }

  async getExecutors() {
    return await this.instance.get(`${this.baseUrl}/executors`);
  }
}

const usersService = new UsersService();

export default usersService;
