import { replaceTo } from '@lib/utils';

export const modifyProduct = (product) => {
  const clearKeyWords = replaceTo(product?.key_words, [
      '&quot;',
      '[\\[\\]" ]',
      ';',
    ]);
  product.key_words = clearKeyWords.split(',');

  const clearCompatibility = replaceTo(
    product.compatibility_draw,
    '&quot;',
    '"'
  );
  product.compatibility_draw = JSON.parse(clearCompatibility);
  const clearFormats = replaceTo(product.illustration_formats, '&quot;', '"');
  product.illustration_formats = JSON.parse(clearFormats);

  return product;
};
