import React from 'react';
import { useDispatch } from 'react-redux';
import './profile-purchases.scss';

import { uploadBinaryFileTC } from '@entities';
import { Text } from '@ui-kit';
import CompletedItem from './completedItem';

const CompletedProjects = ({projects, isUser}) => {
    const dispatch = useDispatch();

    const downloadArchive = (blob) => {
        const link = document.createElement('a');
        link.setAttribute('download', blob.name);

        const href = URL.createObjectURL(blob);
        link.href = href;
        link.setAttribute('target', '_blank');
        link.click();
        URL.revokeObjectURL(href);
    };

    const makeRequest = async (param) => {
        const isArchiveIsString = param.archive?.includes('http');
        if (!isArchiveIsString) {
            const file = await dispatch(uploadBinaryFileTC({dir: param.dir, name: param.archive}));
      
            return downloadArchive(file);
        }
    
        window.open(
        param.archive,
            '',
            'toolbar=yes,scrollbars=yes,resizable=yes,top=0,bottom=0,left=2800,width=700,height=600'
        );
    };

    return (
        <>
            <div className="admin__table-header">
                <Text as="h6" className="project-name ">
                    Name
                </Text>
                <Text as="h6" className="product-license">
                    License
                </Text>
                <Text as="h6" className="product-date">
                    Date
                </Text>
                <Text as="h6" className="product-download">
                    Download an illustration
                </Text>
            </div>
            <div className="admin__table-body">
                {projects.map((item, index) => (
                    <CompletedItem
                    key={`profile-purchase-${index}`}
                    item={item}
                    makeRequest={makeRequest}
                    isUser={isUser}
                />
                ))}
            </div>
        </>
    )
}
export default CompletedProjects;