import React from "react";
import './email-subscribe.scss';

import { Text, Icon } from '@ui-kit';
import { SubscribeForm } from '@widgets';

const EmailSubscribe = () => {

 return (
    <div className="subscribe__container">
        <div className="main__container subscribe__content d-flex">
            <div className="subscribe_img">
                <Icon src="/images/banner_contacts.png" />
            </div>
            <div className="subscribe__wrapper">
                <Text as="h2">Want free illustration sets?</Text>
                <Text as="h4">
                    Subscribe to our newsletter to regularly receive the best sets of our illustrations!
                </Text>
                <SubscribeForm />                
            </div>
        </div>
    </div>
 );
};

export default EmailSubscribe;