import { Price, Rate, User } from '@entities';

const { REACT_APP_PROJECTS_URL } = process.env;

export class UserProjectDTO {
  id;
  dir;
  sale;
  rate;
  user;
  title;
  stage;
  price;
  rating;
  isChat;
  isPaid;
  roomId;
  userId;
  archive;
  isDeleted;
  paidAt;
  paidHours;
  rate_type;
  followingId;
  renderImage;
  sketchImage;
  filesInstance;
  followingData;
  interviewerId;
  executors = [];
  isMessagesSend;
  executorContent;
  workingHoursSum;
  html_description;
  currentStepsHours;
  reason_for_deletion;
  notificationsToUser;
  illustration_format;
  expectedCostMaxRate;
  isAcceptedInterviewer;
  expectedCostHourlyRate;
  createdAt;

  constructor(option) {
    if (!option) {
      option = {};
    }

    this.id = option.id;
    this.dir = option.dir;
    this.title = option.title;
    this.roomId = option.roomId;
    this.isPaid = option.isPaid;
    this.userId = option.userId;
    this.archive = option.archive;
    this.sale = option?.sale || 0;
    this.stage = +option?.stage || 1;
    this.isDeleted = option.isDeleted;
    this.executors = option.executors;
    this.paidAt = option.paidAt;
    this.paidHours = option.paidHours;
    this.isChat = +option?.isChat || 1;
    this.filesInstance = option.filesInstance;
    this.followingData = option.followingData;
    this.html_description = option.html_description;
    this.currentStepsHours = option.currentStepsHours;
    this.isMessagesSend = option?.isMessagesSend || 1;
    this.interviewerId = +option?.interviewerId || null;
    this.illustration_format = option.illustration_format;
    this.expectedCostMaxRate = option?.expectedCostMaxRate || 0;
    this.workingHoursSum = `${option.workingHoursSum || '0.0'}`;
    this.followingId = option?.followingId ? option.followingId : 2;
    this.isAcceptedInterviewer = +option?.isAcceptedInterviewer || 1;
    this.expectedCostHourlyRate = option?.expectedCostHourlyRate || 0;

    this.user = new User(option.user);
    this.price = new Price(option.price);
    this.rate = new Rate(option.rate, option.rate_type);
    this.createdAt = option.createdAt;

    this.executorContent = option.executorContent
      ? JSON.parse(option.executorContent)
      : {};

    const followingData = option?.followingData
      ? option.followingData.replaceAll('\\', '').replaceAll('&quot;', '"')
      : '[]';

    this.followingData = JSON.parse(followingData);

    const currentStepsHours = option?.currentStepsHours
      ? option.currentStepsHours.replaceAll('\\', '').replaceAll('&quot;', '"')
      : '[]';

    this.currentStepsHours = JSON.parse(currentStepsHours);

    const notificationsToUser = option?.notificationsToUser
      ? option.notificationsToUser
          .replaceAll('\\', '')
          .replaceAll('&quot;', '"')
      : '[]';

    this.notificationsToUser = JSON.parse(notificationsToUser);

    if (option.executors && option.executors.length) {
      this.executors = option.executors.map((executor) => new User(executor));
    }

    if (option.sketchImage && option.sketchImage === 'null') {
      this.sketchImage = null;
    } else {
      this.sketchImage = option.sketchImage;
    }

    if (!option.renderImage) {
      this.renderImage = null;
      this.renderImageUrl = '/images/project/default.jpg';
    } else {
      this.renderImage = option.renderImage;
      this.renderImageUrl = `${REACT_APP_PROJECTS_URL}/${this.dir}/${this.renderImage}`;
    }
  }
}
