import React from 'react';
import './avatar.scss';
import { Button, Status } from '@ui-kit';

const { REACT_APP_AVATAR_URL } = process.env;

const Avatar = (props) => {
  const {
    src,
    lable,
    className = '',
    theme,
    size = 'md',
    status = null,
    isUserOnline,
    srcStatic,
    text,
    user,
    ...options
  } = props;

  let classes = [
    'avatar__wrapper',
    'd-flex',
    'jc-center',
    'ai-center',
    'pr',
    size,
    theme,
    ...className.split(' '),
  ];

  const renderAvatar = () => {
    if (src && src !== 'null') {
      const fullUrl = `${REACT_APP_AVATAR_URL}/${src}`;

      return <img src={fullUrl} alt="avatar" />;
    }

    if (srcStatic) {
      return <img src={srcStatic} alt="avatar" />;
    }

    const textAvatar = (
      <Button
        theme="secondary"
        className="text__avatar d-flex ai-center jc-sb"
      >
        <span className="d-flex jc-center w-100">{text || 'DF'}</span>
      </Button>
    );

    if (!lable) {
      return textAvatar;
    }
  };

  return (
    <div {...options} className={classes.join(' ')}>
      {status && <Status size={size} isUserOnline={isUserOnline} />}
      <div className="avatar__img-wrapper">{renderAvatar()}</div>
    </div>
  );
};

export default Avatar;
