import { Validation } from '@shared/lib/utils';

const onCaptchaValid = (formData) => {
  let errorsState = {};


  const validationCaptcha = new Validation({
    captcha: formData.captchToken,
  })
    .isRequired('captcha', 'Captcha')
    .getErrors();

  /* combine */

  if (validationCaptcha.length) {
    errorsState = {
      ...errorsState,
      captcha: validationCaptcha[0],
    };
  }

  return errorsState;
};

export default onCaptchaValid;
