import React, { useEffect, useState } from 'react';
import './profile-settings.scss';

import { useSelector, useDispatch } from 'react-redux';
import { useForm, geShortCut } from '@shared';
import profileFormValid from './profile-validation.hook';
import { 
  getCustomer, 
  updateUserSettingsTC,
  getUserTC,
  setCurrentUserAvatarAC,
  sendActivationOnEmailTC,
  updateUserAvaTC,
  Snackbar,
  setAlertAC,
} from '@entities';
import { 
  FileUpload, 
  ProfileAddress,
  ProfileRole,
  ProfileNotifications 
} from '@widgets';
import { 
  Icon, 
  Avatar, 
  Text, 
  Button, 
  Input
} from '@ui-kit';
import EmailIcon from '@ui-kit/icons/mail-gray500.svg';
import CheckIcon from '@ui-kit/icons/check-accent500-md.svg';


const ProfileSettings = () => {
  const dispatch = useDispatch();
  const user = useSelector(getCustomer);
  const isExecutor = user.role === 'USER' ? false : true;

  const notSendEmail = {
    icon:     false,
    title:   "Thank you for registering on BriefStock",
    content: `We ask you to go through the last step so that you can take full 
              advantage of all the BriefStock features.Please, click the button, 
              go to your email and confirm the completion of registration in an 
              incoming letter from BriefStock.`
  };
  const sendEmail = {
    icon:     CheckIcon,
    title:   "We have sent an email to you",
    content: `If the letter has not arrived within 2 minutes, you can request 
              it again.Please note that it may end up in the Spam folder.`
  };

  const [activatedText, setActivatedText] = useState(notSendEmail);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!user.id) {
      return;
    }

    if (!+user.isActivated) {
      setErrors({email: 'Email not confirmed.'})
    }
  }, [user]);

  useEffect(() => {
    const errorsArr = Object.keys(errors);
    if (errorsArr.length) {
        const inputElement = document.getElementsByName(errorsArr[0])[0];
        inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };
}, [errors]);

  const { 
    ava, 
    firstName, 
    lastName, 
    isMessagesSend,
    isActivated
  } = user;

  const [uploadedAvatar, setUploadedAvatar] = useState(ava);
  const [uploadedAvatarDisplay, setUploadedAvatarDisplay] = useState(null);

  const avaText = geShortCut(firstName, lastName);

  const { formData, onChangeInput, onSubmitForm, setFormData } = useForm(
    user,
    handlerBeforeSubmitingForm
  );

  useEffect(() => {
    setFormData(user);
    // eslint-disable-next-line
  }, [user]);

  async function handlerBeforeSubmitingForm() {
    const formErrors = profileFormValid(formData);

    if (Object.keys(formErrors).length) {
      return setErrors(formErrors);
    }

    dispatch(updateUserSettingsTC(formData));
    setErrors({});
  };

  const resendEmail = (event) => {
    event.stopPropagation();

    dispatch(sendActivationOnEmailTC({email: user.email}));
    setActivatedText(sendEmail);
  };

  const resetSettings = () => {
    dispatch(getUserTC(user.id));
    dispatch(setCurrentUserAvatarAC(null));
    setErrors({});
  };

  useEffect(() => {

    if (uploadedAvatar && uploadedAvatar.slice(0,4) === 'blob') {
      setUploadedAvatarDisplay((
        <Avatar 
            srcStatic={uploadedAvatar}
            className="xxl"
            text={geShortCut(user.firstName, user?.lastName)}
        />
      ));
    }
    setUploadedAvatarDisplay((
      <Avatar 
        src={uploadedAvatar}
        className="xxl"
        text={geShortCut(user.firstName, user?.lastName)}
      />
    ));
    // eslint-disable-next-line
  }, [ uploadedAvatar ]);

  const onAvatarUpload = async(files) => {
    const uploadPromise = dispatch(updateUserAvaTC(files, user));
    const uploadedAvatarName = (await uploadPromise)?.data.avatarName;

    setUploadedAvatar( uploadedAvatarName );
    formData.ava = uploadedAvatarName;

    dispatch(setCurrentUserAvatarAC(files));
  };

  const setAvatarErrors = async(errors) => {
    const snackbar = new Snackbar({
      status: 500,
      message: (
        <div className="">
          <h5 className="snack__header">
            { errors.uploadImage }
          </h5>
        </div>
      ),
    });

    dispatch(setAlertAC(snackbar));
  };

  return (
    <div className="profile__settings">
      <div className="profile__header d-flex">
        <div className="profile__banner d-flex">
          {<Icon src="/images/banner-profile.jpg"  alt="banner"/>}
        </div>
        <div className="profile__header-wrapper d-flex">
          <div className="profile__header-content d-flex">
            <Avatar 
                src={ava}
                className="profile lg"
                text = {avaText}
            />
            <div className="profile__header-info d-flex">
              <Text as="h2">
                {firstName} {lastName}
              </Text>
            </div>
          </div>
        </div>
      </div>

      <div className="main__container d-flex jc-center">
        <div className="profile__settings-container">
          <form id="personal-settings" className="profile__settings-form">
            <div className="profile__name-block divider mb-43">
              <div className="profile__input">
                <Input 
                  placeholder="Enter your first name"
                  name="firstName"
                  type="text"
                  required
                  label="First Name *"
                  value={formData?.firstName || ''}
                  onChange={onChangeInput}
                  className={errors.firstName && 'form-item__error'}
                />
                {errors.firstName && (
                  <Text as="span" className="error">
                    {errors.firstName}
                  </Text>
                )}
              </div>
              <div className="profile__input">
                <Input
                  placeholder="Enter your last name"
                  name="lastName"
                  type="text"
                  label="Last Name"
                  value={formData?.lastName || ''}
                  onChange={onChangeInput}
                  className={errors.lastName && 'form-item__error'}
                />
                {errors.lastName && (
                  <Text as="span" className="error">
                    {errors.lastName}
                  </Text>
                )}
              </div>
            </div>
            <div className="profile__input-block mb-20 divider">
              <Input 
                placeholder="Enter your email"
                name="email"
                type="email"
                required
                label="Email"
                iconLeft={EmailIcon}
                value={formData?.email || ''}
                isDisabled={true}
                className={errors.email && 'form-item__error'}
                disabled="disabled"
              />
              {errors.email && (
                  <Text as="span" className="error">
                    {errors.email}
                  </Text>
              )}
              {!+isActivated && (
                <div className="profile__activated-email mt-20">
                  <Button 
                    size="md" 
                    theme="secondary-gray"
                    type="button"
                    onClick={resendEmail}
                  >
                    Resend the email
                  </Button>
                  <div className="activated-content">
                    <div className="title">
                      {activatedText.icon && (
                        <Icon src={activatedText.icon} className="check-icon"/>
                      )}
                      <Text as="h5">
                        {activatedText.title}
                      </Text>
                    </div>
                    <Text as="h5">
                      {activatedText.content}
                    </Text>
                  </div>
                </div>
              )}
            </div>
            
            <div className="profile__avatar-block divider mb-43">
              <label>Your photo</label>
              <div className="profile__avatar-uploader d-flex">
                {uploadedAvatarDisplay}
                <FileUpload 
                  text={geShortCut(user.firstName, user?.lastName)}
                  onFileUpload={onAvatarUpload}
                  setErrors={setAvatarErrors}
                />
              </div>
            </div>

            <div className="profile__address-container mb-20 divider">
              <ProfileAddress 
                user={user}
                formData={formData}
                errors={errors}
                onChangeInput={onChangeInput}
                className="mb-43"
              />
            </div>

            {isExecutor && (
              <div className="profile__role-container divider mb-20">
                <ProfileRole
                  formData={formData}
                  user={user}
                  errors={errors}
                  onChangeInput={onChangeInput}
                  className="mb-43"
                  disabled="disabled"
                />
              </div>
            )}

            <ProfileNotifications 
              isMessagesSend={isMessagesSend}
              onChangeInput={onChangeInput}
            />

            <div className="divider-end" />

            <div className="profile__buttons-block d-flex as-end">
              <Button 
                size="md" 
                theme="secondary-gray"
                onClick={resetSettings}
              >
                Cancel
              </Button>
              <Button 
                size="md"
                onClick={onSubmitForm}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
  
export default ProfileSettings;