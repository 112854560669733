import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DOMPurify from 'dompurify';
import './chat-typing-form.scss';
import {
  Snackbar,
  setAlertAC,
} from '@entities';
import Hotkeys from 'react-hot-keys';

const TypingForm = (props) => {
  const {
    onChange = ()=>{},
    onTypingHeightChange = ()=>{},
    placeholder = 'Write a message form',
    className = '',
    counterElement,
    name = "textarea",
    isDisabled,
  } = props;

  const dispatch = useDispatch();
  const baseClasses = ['typing__container', ...className.split(' ')];
  const [ classes, setClasses ] = useState( baseClasses );
  const maxMessageLength = +process.env.REACT_APP_PROJECT_MAX_MESSAGE_LENGTH;

  const setCounter = text => {
    if (!counterElement?.current) return;

    text = text || '';
    counterElement.current.innerText = maxMessageLength - text.length;
  };

  const setCounterColor = color => {
    if (!counterElement?.current) return;

    counterElement.current.style.color = color;
  };

  useEffect(() => {
    setCounter();
    document.getElementById('editableDiv').addEventListener('paste', handlePaste);
    // eslint-disable-next-line
  }, []);

  const checkLength = (event) => {
    const message = event.target.outerText;
    const isMessageLong = message.length > maxMessageLength;

    setCounter( message );

    if (isMessageLong) {
      event.preventDefault();

      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
             The message entered is too large.
            </h5>
          </div>
        ),
      });
      dispatch(setAlertAC(snackbar));

      setCounterColor("#F04438");
      setClasses([ ...baseClasses, "error-color" ]);
      return;
    }

    setCounterColor("inherit");
    setClasses(baseClasses);
  };

  const clearHTML = (str) => {
    let text  = DOMPurify.sanitize(str, {
      ALLOWED_TAGS: ['b', 'i', 'u', 'q', 'a', 'br'],
      FORBID_TAGS: ['style', 'script', 'meta', 'link'],
      FORBID_ATTR: ['style', 'id', 'name', 'type', 'value'],
    });

    return text || "";
  };

  const onFormChange = (text) => {
    onChange(text);

    const editorInput = document.getElementById("editableDiv");
    editorInput.style.maxHeight = 'calc(95vh - 336px)';
  }

  const onBlur = () => {
    const editorInput = document.getElementById("editableDiv");
    editorInput.style.maxHeight = '40px';

    onTypingHeightChange();
  };

  const onClick = () => {
    const editorInput = document.getElementById("editableDiv");
    editorInput.style.maxHeight = 'calc(100dvh - 455px)';
    
    onTypingHeightChange()
  };

  const clearNR = (text) => {
    let clearText = text.replaceAll(/(\n|\r)/g, '<br>');
    clearText = clearText.replaceAll(/((<br>){2,})/g, '<br>');
    return clearText.trim();
  };

  const clearBr = (text) => {
    let clearText = text.replaceAll(/(<br>|<br\/>|\r)/gi, '\n');
    clearText = clearText.replaceAll('&nbsp;', ' ');
    clearText = clearText.replaceAll(/((\n){2,})/g, '\n');
    return clearText.trim();
  }

  const handlePaste = (event) => {
    let clipboardData, pastedData;
  
    event.stopPropagation();
    event.preventDefault();
  
    clipboardData = event.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData('Text');
    pastedData = clearNR(pastedData)

    const editor = document.querySelector('.editor');

    // Insert text at the current cursor position
    const selection = window.getSelection();

    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.deleteContents();

      // Create a temporary element to insert text
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = pastedData;
      let node, lastNode = '';
      const frag = document.createDocumentFragment();
      while ((node = tempDiv.firstChild)) {
        lastNode = frag.appendChild(node);
      };
      range.insertNode(frag);

      // Move the cursor after the inserted text
      if (lastNode) {
        range.setStartAfter(lastNode);
        range.setEndAfter(lastNode);
        selection.removeAllRanges();
        selection.addRange(range);
      };
    };

    pastedData = clearHTML(pastedData).trim();
    pastedData = clearBr(editor.innerHTML);
    checkLength(event);
    onFormChange(pastedData);
  };

  const onInput = (event) => {
    let text = event.target.innerHTML;
    text = clearHTML(text).trim();

    const key = event.nativeEvent.inputType;
    
    if (key === 'insertParagraph') {
      event.preventDefault();
      const editor = document.querySelector('.editor');
      editor.innerHTML = '';
      checkLength(event);
      return;
    };

    checkLength(event);
    onFormChange(text);
  };

  function hotKeyFilter(event) {
    const target = event.target || event.srcElement;
    const tagName = target.tagName;

    return !(tagName === 'INPUT' || tagName === 'SELECT' || tagName === 'TEXTAREA');
  };

  function onKeyDown(hotKey, event) {
    document.execCommand('insertLineBreak');
    event.preventDefault();
  };

  return (
    <Hotkeys 
       keyName="ctrl+enter,command+enter" 
       filter={hotKeyFilter}
       onKeyDown={onKeyDown}
    >
      <div
          className={classes.join(' ')}
      >
        <div
          id="editableDiv"
          name={name}
          className="editor"
          contentEditable={!isDisabled}
          placeholder={placeholder}
          onInput={(event) => onInput(event)}
          onBlur={onBlur}
          onClick={onClick}
        ></div>
      </div>
    </Hotkeys>
  );
};

export default TypingForm;
