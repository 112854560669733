import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Recapcha, } from '@shared';
import jwt from 'jwt-decode';
import './reset.scss';

import { useForm } from '@shared';
import { Button, Input, Text, Icon } from '@ui-kit';
import { resetPasswordTC, getServerError, setServerErrorsAC } from '@entities';
import onResetFormValid from './reset-validation.hook';
import onCaptchaValid from '../security-form/captcha-validation.hook';
import { ADMIN_LOGIN_URL } from '@app/routes';
import { LoadingAdmin } from '@shared/ui';
import Arrow from '@ui-kit/icons/arrow-gray700.svg';
import LockIcon from '@ui-kit/icons/lock-accent600.svg';
import EyeOnIcon from '@ui-kit/icons/eye-gray400.svg'
import EyeOffIcon from '@ui-kit/icons/eye-off-gray400.svg'

//Autofill call onChangeInput form 2 time
const ResetPassForm = ({ setPasswordIsReseted }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptchaRef = useRef();
  const location = useLocation();
  const { pathname } = location;
  const serverError = useSelector(getServerError);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [recoveryToken, setRecoveryToken] = useState('');
  const [isPasswordShow, setPasswordShow] = useState(false);
  const [isConfirmShow, setConfirmShow] = useState(false);

  const SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const eyeIcon = isPasswordShow ? EyeOnIcon : EyeOffIcon;
  const inputType = isPasswordShow ? 'text' : 'password';
  const eyeConfirmIcon = isConfirmShow ? EyeOnIcon : EyeOffIcon;
  const inputConfirmType = isConfirmShow ? 'text' : 'password';
  const password = {
    password: '',
    confirm: '',
  };

  const { formData, onChangeInput, onSubmitForm } = useForm(
    password,
    handlerBeforeSubmitingForm
  );

  useEffect(() => {
    const tokens = pathname.split('/');
    setRecoveryToken(tokens[tokens.length - 1]);
  }, [pathname]);

  useEffect(() => {
    return () => dispatch(setServerErrorsAC([]));
  }, [dispatch]);

  async function handlerBeforeSubmitingForm() {
    // recaptchaRef.current.execute();

    await Recapcha.start(recaptchaRef);

    const formErrors = onResetFormValid(formData);

    if (Object.keys(formErrors).length) {
      return setErrors(formErrors);
    }

    setErrors({});
  }

  const onSecuritySuccess = async (token) => {
    const { email } = jwt(recoveryToken);

    if (!email) {
      return setErrors({
        captcha:
          'Token is not valid. Try resetting your password again or contact technical support',
      });
    }

    formData.captchToken = token;
    formData.recoveryToken = recoveryToken;
    formData.email = email;

    recaptchaRef.current.reset();
    setIsLoading(true);
    const isResult = await dispatch(resetPasswordTC(formData));

    if (!isResult) {
      setIsLoading(false);
      return;
    }

    setPasswordIsReseted(true);
  };

  const onSecurityError = () => {
    const captchaError = onCaptchaValid({ captchToken: null });

    setErrors({ ...errors, ...captchaError });
  };

  const onRecaptchaChange = (token) => {
    if (!token) {
      onSecurityError();
    }

    return  onSecuritySuccess(token);
  } 

  const onIconClick = (options) => {
    if (options === 'password') {
      if (isPasswordShow) {
        setPasswordShow(false);
        return;
      }
      setPasswordShow(true);
      return;
    }

    if (isConfirmShow) {
      setConfirmShow(false);
      return;
    }
    setConfirmShow(true);
  };

  return (
    <div className={`form__container reset__form ${isLoading && 'pr'}`}>
      {isLoading && <LoadingAdmin />}
      <div className="form__header">
        <div className="form__header-logo-container">
          <div className="form__header-icon">
            <Icon src={LockIcon} />
          </div>
        </div>
        <Text as="h2" className="form__header-title">
          Set new password
        </Text>
        <Text as="h5" className="login__header-subtitle">
          Must be at least 8 characters.
        </Text>
      </div>
      <div className="form__content">
        <form onSubmit={onSubmitForm} className="login__form">
          <Input
            placeholder="Create a password"
            name="password"
            type={inputType}
            autoComplete="new-password"
            required
            label="Password"
            value={formData?.password || ''}
            onChange={onChangeInput}
            className={errors.password && 'form-item__error'}
            iconRight={eyeIcon}
            onIconClick={() => onIconClick('password')}
          />
          {errors.password && (
            <Text as="span" className="error">
              {errors.password}
            </Text>
          )}
          <Input
            placeholder="Confirm password"
            name="confirm"
            type={inputConfirmType}
            autoComplete="new-password"
            required
            label="Confirm password"
            value={formData?.confirm || ''}
            onChange={onChangeInput}
            className={errors.confirm && 'form-item__error'}
            iconRight={eyeConfirmIcon}
            onIconClick={() => onIconClick('confirm')}
          />
          {errors.confirm && (
            <Text as="span" className="error">
              {errors.confirm}
            </Text>
          )}

          <Button type="submit" className="mt-24" size="lg">
            <Text as="span">Reset password</Text>
          </Button>

          <Recapcha
            recaptchaRef={recaptchaRef}
            className="g-recaptcha"
            sitekey={SITE_KEY}
            onChange={onRecaptchaChange}
            onErrored={onSecurityError}
            size= "invisible"
            //badge="inline"
          />
        </form>
        {errors.captcha && (
          <Text as="span" className="error">
            {errors.captcha}
          </Text>
        )}
        {/* server errors block */}
        {!!serverError.length && (
          <div className="server__error">
            {serverError.map((error) => (
              <Text as="span" key={error.param} className="error">
                {error.msg}
              </Text>
            ))}
          </div>
        )}
      </div>
      <div className="form__footer">
        <Button
          iconLeft={Arrow}
          onClick={() => navigate(ADMIN_LOGIN_URL)}
          size="md"
          theme="link-gray"
        >
          Back to log in
        </Button>
      </div>
    </div>
  );
};

export default ResetPassForm;
