export const BRIEFINFO = [
  {
    value: "item1",
    active: false,
    title: "What illustration style are you interested in?",
    description:
      "What level of detail is needed? Please send us some references so we can understand what you are looking for. You can send us links or attach files (only .jpg, .jpeg, .png and no more than 1.5 MB).",
  },
  {
    value: "item2",
    active: false,
    title: "Can you describe your character?",
    description:
      "It would be useful to know such details as gender, age, skin color, body type, clothing, etc.",
  },
  {
    value: "item3",
    active: false,
    title: "What pose should the character have?",
    description:
      "Please describe the pose in detail or send us some references.",
  },
  {
    value: "item4",
    active: false,
    title: "What emotion should the character have?",
    description:
      "Should this emotion be expressed only in the face or throughout the body?",
  },
  {
    value: "item5",
    active: false,
    title: "Do you want the illustration to have a background?",
    description: "If so, please share your vision or send us the references.",
  },
  {
    value: "item6",
    active: false,
    title: "What should be shown in the background?",
    description:
      "What time of day should it be? What angle/perspective should we use?",
  },
  {
    value: "item7",
    active: false,
    title: "Do you have any color preferences?",
    description: "",
  },
  {
    value: "item8",
    active: false,
    title: "What aspect ratio/format of the illustration do you need?",
    description: "1:1.5, 1:1, 1.5:1 or other (specify).",
  },
  {
    value: "item9",
    active: false,
    title: "What format of the source files do you need?",
    description:
      "If asked in advance, we can prepare the png format along with eps, ai and jpeg.",
  },
];
