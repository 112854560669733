import { createSelector } from 'reselect';

export const getProjectData = (state) => state.projectData;

export const getCurrentProject = createSelector(
  getProjectData,
  (projectData) => projectData.currentProject
);

export const getMessages = createSelector(
  getProjectData,
  (projectData) => projectData.messages
);

export const getCurrentProjectImage = createSelector(
  getProjectData,
  (projectData) => projectData.currentProjectImage
);

export const getExecutors = createSelector(
  getProjectData,
  (projectData) => projectData.executors
);

export const getWorkingHoursSum = createSelector(
  getCurrentProject,
  (projectData) => projectData.workingHoursSum
);

export const getCurrentStepHours = createSelector(
  getCurrentProject,
  (projectData) => projectData.currentStepsHours
);

export const getProjectWasChanged = createSelector(
  getProjectData,
  (projectData) => projectData.isProjectWasChanged
);

export const getUnsavedProjectShow = createSelector(
  getProjectData,
  (projectData) => projectData.isUnsavedProjectShow
);

export const getProductToProject = createSelector(
  getProjectData,
  (projectData) => projectData.productToProject
);
