import { v4 as uuidv4 } from 'uuid';
import './input.scss';
import { Text, Icon } from '@ui-kit';
import InfoRedIcon from '@ui-kit/icons/alert-circle-error500.svg';

const Input = (props) => {
  const {
    hint = '',
    className = '',
    errorText = '',
    isError = false,
    placeholder = '',
    isDisabled = false,
    type = '',
    onChange = () => {},
    label,
    position,
    iconRight,
    iconLeft,
    icoLeft,
    onIconClick,
    children,
    maxLengthCustom,
    minLengthCustom,
    ...options
  } = props;

  let typeInput = type;
  const labelId = uuidv4();
  let classes = ['input__container'];

  if (iconRight) {
    classes.push('icon__right');
  }

  if (iconLeft) {
    classes.push('icon__left');
  }

  if (type === 'url') {
    typeInput = 'text';
    classes.push('url');
  }

  if (isError) {
    classes.push('error');
  }

  if (isDisabled) {
    classes.push('disabled');
  }

  classes = [...classes, ...className.split(' ')];

  const handler = (event) => {
    const value = event.target.value;

    if (maxLengthCustom) {
      if (checkMaxLength(value)) {
        event.preventDefault();
        return false;
      }
    }

    if (minLengthCustom) {
      if (checkMinLength(value)) {
        event.preventDefault();
        return false;
      }
    }

    onChange(event);
  };

  const checkMaxLength = (value) => {
    return +value > maxLengthCustom;
  };

  const checkMinLength = (value) => {
    if (+value === 0) {
      return false;
    }

    return +value < minLengthCustom;
  };

  return (
    <>
      <div className={classes.join(' ')}>
        <div className="input__children pr">
          {iconLeft && (
            <div className="icon__left-icon"> {<Icon src={iconLeft} />}</div>
          )}
          <label htmlFor={labelId}>{label}</label>
          <div className="input__line">
            {type === 'url' && (<div className="input__url">http://</div>)}
            <input
              {...options}
              type={typeInput}
              id={labelId}
              placeholder={placeholder}
              className="input"
              onChange={handler}
            />
          </div>

          {iconRight && !isError && (
            <div className="icon__right-icon" onClick={onIconClick}>
              {<Icon src={iconRight} />}
            </div>
          )}
          {isError && (
            <div className="icon__left-icon">{<Icon src={InfoRedIcon} />}</div>
          )}
        </div>
      </div>
      {hint && !errorText && (
        <Text as="h6" className="input__text-hint ">
          {hint}
        </Text>
      )}
      {errorText && (
        <Text as="h6" className="input__text-error">
          {errorText}
        </Text>
      )}
    </>
  );
};

export default Input;

/*
<svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3332 3.00016C18.3332 2.0835 17.5832 1.3335 16.6665 1.3335H3.33317C2.4165 1.3335 1.6665 2.0835 1.6665 3.00016M18.3332 3.00016V13.0002C18.3332 13.9168 17.5832 14.6668 16.6665 14.6668H3.33317C2.4165 14.6668 1.6665 13.9168 1.6665 13.0002V3.00016M18.3332 3.00016L9.99984 8.8335L1.6665 3.00016"
        stroke="red"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
*/

/*  <Input
          className="pagination__field"
          label={'hello'}
          iconRight={mail}
          iconLeft={mail}
          placeholder="sadasdas"
          hint="This is a hint text to help user."
          isError={true}
          maxLengthCustom ={5}
          minLengthCustom = {1}
          isDisabled={true}
          errorText="This is an error message."
        /> */
