import React, { useState, useRef, useEffect } from 'react';
import './productGalleryInfo.scss';
import { Icon, Text, Button, Tooltip, Modal } from '@ui-kit';

import {
  CompatibleWith,
  FilesTypes,
  PriceLine,
  LicenseLine,
  ViewMore,
  PreviewProductImg,
  AvatarLabel,
  Keywords,
} from '@features';
import { SimilarProducts } from '@widgets';
import { 
  BROWSE_URL, 
  CART_URL,
  ADMIN_USER_PROJECTS_URL 
} from '@app/routes';

import Cart from '@ui-kit/icons/cart-white.svg';
import Close from '@ui-kit/icons/x-gray700.svg';
import CartBlue from '@ui-kit/icons/cart-accent700.svg';
import Check from '@ui-kit/icons/check-accent500-md.svg';
import Qestion from '@ui-kit/icons/help-circle-gray400.svg';
import DownloadIcon from '@ui-kit/icons/download-white.svg';

// You cannot use react-redux hooks in this component!!
const ProductInfo = ({
  navigateTo = () => {},
  product = {},
  similarProducts = [],
  showClose = true,
  linkPreview = false,
  isGallery = false,
  isUserAuth = false,
  addToCart = () => {},
  closeInfo = () => {},
  onSelectTag = () => {},
  callback = () => {},
  getFree = ()=>{},
}) => {
  const [showDescription, setShowDescription] = useState(false);
  const href = useRef();

  const {
    id,
    linkId,
    pathToPreview,
    title = '',
    html_description = '',
    compatibility_draw = '',
    illustration_formats = '',
    price,
    key_words,
    inCart,
    isFree,
    user = {},
  } = product;

  const nowPrice = isFree ? 0 : price;

  useEffect(() => {
    if (inCart) {
      addedCartsStyles();
    }
  }, [product, inCart]);

  const cutDescription = (text) => {
    if (!text || text === "[]") {
      return '';
    }

    const length = 70;
    const label = !showDescription ? 'view more' : 'show less';

    if (text.length > length && !showDescription) {
      return (
        <Text as="h5" className={`info__descr mb-20`}>
          {text.slice(0, length)}
          <ViewMore
            text={label}
            showDots={true}
            onClick={() => setShowDescription((state) => !state)}
          />
        </Text>
      );
    }

    return (
      <Text as="h5" className={`info__descr mb-20`}>
        {text}
        <ViewMore
          text={label}
          showDots={false}
          onClick={() => setShowDescription((state) => !state)}
        />
      </Text>
    );
  };

  const onSelectKeyword = (keyword) => {
    onSelectTag(keyword);
  };

  const renderPreviewImage = () => {
    if (linkPreview) {
      return (
        <a
          className="product__info-link"
          href={`${BROWSE_URL.pathname}/${linkId}-${id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <PreviewProductImg id={id} pathToPreview={pathToPreview} />
        </a>
      );
    }

    return <PreviewProductImg id={id} pathToPreview={pathToPreview} />;
  };

  const renderCartButtons = () => {
    if (isFree) {
      return (
        <Button
          size="lg"
          iconLeft={DownloadIcon}
          onClick={getFree}
          className="free__btn cart__icon"
        >
          Download
        </Button>
      )
    };

    return (
      <div className="cart-success-added d-flex">
        <div
          className="cart__ico secondary d-flex ai-center"
          onClick={() => navigateTo(CART_URL)}
        >
          <Icon src={CartBlue} />
          <Text as="h6">View Cart</Text>
        </div>
        <div className="d-flex ai-center ml-24">
          <Icon src={Check} alt="check" className="mr-16" />
          <Text as="h5">Item in your cart</Text>
        </div>
      </div>
    );
  };

  const addedCartsStyles = () => {
    const current = href.current;

    if (current) {
      current.classList.add('product-in-cart__wrapper');
    }
  };

  const notFoundClass = ["similar__not-found"]

  const navigateToProjects = () => {
    if(isUserAuth) {
      return window.open(ADMIN_USER_PROJECTS_URL.pathname + '?create-new=true', '_blank');
    }

    callback(product)
  }

  const renderSlider = () => {
    if(similarProducts?.length) {
      return (
        <>
          <Text as="h5" className="similar__title">
            You might like these illustrations
          </Text>
          <SimilarProducts items={similarProducts} addToCart={addToCart} />
        </>
      )
    };

    if(!isGallery) {
      return (
        <div className={notFoundClass.join(' ')}>
          <div className="similar__not-found-content">
            <Text as="h5" className="title">
              This illustration is one of a kind
            </Text>
            <Text as="h5">
              If you want a similar one, please change your request. 
              In case you need something unique, our authors are ready 
              to create an illustration for you from scratch.
            </Text>
          </div>
          <Button
            onClick={navigateToProjects}
            theme="link-color"
            className="arrow-right"
            size="lg"
          >
            Order illustrations
          </Button>
        </div>
      )
    }
  }

  return (
    <div>
      <div className="d-flex pr jc-center product__container-info">
        {showClose && (
          <Icon
            src={Close}
            onClick={() => closeInfo(id)}
            className="close__icon"
          />
        )}
        <div className="product__info-container d-flex">
          {renderPreviewImage()}
          <div className="additional-data d-flex mt-20">
            <AvatarLabel author={user} />

            <Keywords
              keywords={key_words}
              onSelectKeyword={onSelectKeyword}
              length={6}
            />
          </div>
          <div className="additional-data-mobile">
            <AvatarLabel author={user} />
          </div>
        </div>

        <div className="info__description">
          <Text as="h3" className="info__title mb-12">
            {title}
          </Text>

          {cutDescription(html_description)}

          <div className="d-flex editillustration__wrapper mb-20 ai-center">
            <Text
              as="h6"
              className="mr-8 edit-link"
              onClick={() => callback(product)}
            >
              Edit this illustration for $23/hour
            </Text>
            <Tooltip
              content={editIllustrationTooltipe}
              position="top"
              direction="revers"
              ancorPosition="anchor-right-bottom"
            >
              <Icon
                src={Qestion}
                className="question__icon"
                alt="question__icon"
              />
            </Tooltip>

            <Modal 
              content={editIllustrationModal}
            >
              <Icon
                src={Qestion}
                className="question__icon"
                alt="question__icon"
              />
            </Modal>
          </div>
          <LicenseLine position='bottom' ancorPosition='anchor-right-top' />
          <CompatibleWith compatibility_draw={compatibility_draw} />
          <FilesTypes illustrationFormats={illustration_formats} />
          <PriceLine price={nowPrice} currency="$" isFree={isFree} />
          <div className="additional-data-mobile keywords mb-20">
            <Keywords
              keywords={key_words}
              onSelectKeyword={onSelectKeyword}
              length={6}
            />
          </div>
          <div className={" info__btn " + (inCart || isFree ? ' product-in-cart__wrapper ' : '')} ref={href}>
            {renderCartButtons()}
            <Button
              size="lg"
              className="cart__btn"
              iconLeft={Cart}
              onClick={(event) => addToCart(event, product)}
            >
              Add to Cart
            </Button>
          </div>
        </div>

        <div className="info__description-tablet">
          <div className="description-left">
            <Text as="h3" className="info__title mb-12">
              {title}
            </Text>

            {cutDescription(html_description)}

            <div className="d-flex editillustration__wrapper mb-20 ai-center">
              <Text
                as="h6"
                className="mr-8 edit-link"
                onClick={() => callback(product)}
              >
                Edit this illustration for $23/hour
              </Text>
              <Tooltip
                content={editIllustrationTooltipe}
                position="top"
                direction="revers"
                ancorPosition="anchor-right-bottom"
              >
                <Icon
                  src={Qestion}
                  className="question__icon"
                  alt="question__icon"
                />
              </Tooltip>

              <Modal 
                content={editIllustrationModal}
              >
                <Icon
                  src={Qestion}
                  className="question__icon"
                  alt="question__icon"
                />
              </Modal>
            </div>
            <div className="additional-data-mobile keywords mb-20">
              <Keywords
                keywords={key_words}
                onSelectKeyword={onSelectKeyword}
                length={6}
              />
            </div>
          </div>
          <div className="description-right">
            <LicenseLine position='bottom' ancorPosition='anchor-right-top' />
            <CompatibleWith compatibility_draw={compatibility_draw} />
            <FilesTypes illustrationFormats={illustration_formats} />
            <PriceLine price={nowPrice} currency="$" isFree={isFree} />
            
            <div className={" info__btn " + (inCart || isFree ? ' product-in-cart__wrapper ' : '')} ref={href}>
              {renderCartButtons()}
              <Button
                size="lg"
                className="cart__btn"
                iconLeft={Cart}
                onClick={(event) => addToCart(event, product)}
              >
                Add to Cart
              </Button>
            </div>
          </div>
        </div>

      </div>
      {renderSlider()}
    </div>
  );
};

const editIllustrationTooltipe = (
  <>
    <Text as="h6" className="tooltipe__title">
      Do you want to change something?
    </Text>

    <Text as="h6" className="tooltipe__subtitle">
      The Briefstock illustrators can edit this <br />
      illustration for you. You only need to create <br />
      a project and describe what you would like to <br />
      change. We will estimate the project before <br />
      we start work. 
    </Text>
  </>
);

const editIllustrationModal = (
  <>
    <Text as="h6" className="modal__title">
      Do you want to change something?
    </Text>

    <Text as="h6" className="modal__subtitle">
      The Briefstock illustrators can edit this
      illustration for you. You only need to create 
      a project and describe what you would like to 
      change. We will estimate the project before 
      we start work.
    </Text>
  </>
);

export default ProductInfo;
