import React from "react";
import './no_result.scss';
import {IconFeatured} from "@ui-kit";
import SearchBlue from '@ui-kit/icons/search-accent600.svg';

const NoResult = ({children = () => {}}) => {

    return (
        <div className="no-result__container">
            <div className="no-result-ico d-flex jc-center">
                <IconFeatured src={SearchBlue} theme="primary" size="lg" alt="no-result"/>
            </div>
            {children}
        </div>
    )
}

export default NoResult;