export const replaceTo = (initialString = '', param, substitute) => {
  if (initialString === null) {
    return null;
  };
  
  let string = initialString;

  if (Array.isArray(param)) {
    for (let i = 0; i < param.length; i++) {
      const regex = new RegExp(param[i], 'gm');

      if (i === param.length - 1) {
        break;
      }
      string = string.replace(regex, '');
    }

    return string.replaceAll(param[param.length], substitute);
  }

  const regex = new RegExp(param, 'g');

  return initialString.replaceAll(regex, substitute);
};

export const convertObjectValue = (value) => {
  if (value === 'null') {
    return null;
  }

  if (value === 'undefined') {
    return undefined;
  }

  if (value === '0') {
    return 0;
  }

  if (value === '1') {
    return 1;
  }

  return value
};
