import { Validation } from '@shared/lib/utils';

const profileFormValid = (formData) => {
let errorsState = {};

const validationFirstName = new Validation({
    field: formData.firstName,
    title: 'First name',
})
    .isRequired()
    .maxLenght(30)
    .getErrors();

const validationLastName = new Validation({
    field: formData.lastName,
    title: 'Last name',
})
    .maxLenght(30)
    .getErrors();

const validationEmail = new Validation({
    field: formData.email,
    title: 'Email',
})
    .isRequired()
    .isEmail()
    .maxLenght(64)
    .getErrors();

const validationPostCode = new Validation({
    field: formData.postCode,
    title: 'Postal code',
})
    .maxLenght(30)
    .getErrors();

const validationAddress = new Validation({
    field: formData.address,
    title: 'Address',
})
    .maxLenght(256)
    .isAddress()
    .getErrors();

if (validationFirstName.length) {
    errorsState = {
    ...errorsState,
    firstName: validationFirstName[0],
    };
}

if (validationLastName.length) {
    errorsState = {
    ...errorsState,
    lastName: validationLastName[0],
    };
}
    
if (validationEmail.length) {
    errorsState = {
    ...errorsState,
    email: validationEmail[0],
    };
}

if (validationPostCode.length) {
    errorsState = {
    ...errorsState,
    postCode: validationPostCode[0],
    };
}

if (validationAddress.length) {
    errorsState = {
    ...errorsState,
    address: validationAddress[0],
    };
}

return errorsState;
};

export default profileFormValid;
