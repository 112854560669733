import React from 'react';
import { useDispatch } from 'react-redux';
import './delete-user.scss';

import { deleteUserTC } from '@entities';
import { Text, Button, IconFeatured } from '@ui-kit';
import ErrorIcon from '@ui-kit/icons/alert-circle-error600.svg';

const ModalDeleteUser = ({userId, setModalDeleteUser, onUpdateData}) => {
    const dispatch = useDispatch();

    const onBackgroundClick = (e) => {
        if (e.target.id === 'delete-user-background') {
          return setModalDeleteUser({status:false});;
        }
      };

    const onClickDelete = () => {
        if(!userId) return;
        
        dispatch(deleteUserTC(userId));
        setModalDeleteUser({status:false});
        return onUpdateData();
    }

    const onCancel = () => {
        return setModalDeleteUser({status:false});
      }

    return (
        <div
            id="delete-user-background"
            className="background-default delete-user-background"
            onMouseDown={onBackgroundClick}
        >
        <div className="delete-user-popup__wrapper">
            <IconFeatured src={ErrorIcon} theme="error" size="lg" />

            <div className="delete-user-popup__content">
                <Text as="h4" className="delete-user-popup__title">
                    Delete user
                </Text>
                <Text as="h6" className="delete-user-popup__subtitle">
                    Are you sure you want to delete this user?
                </Text>
                <Text as="h6" className="delete-user-popup__subtitle">
                    This action cannot be undone.
                </Text>
                
                <div className="delete-user-popu__actions">
                    <Button
                        theme="primary-danger"
                        onClick={onClickDelete}
                    >
                        Delete
                    </Button>
                    <Button
                        theme="secondary-gray"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ModalDeleteUser;