import { 
  SET_USERS, 
  SET_USERS_PAGINATION_AMOUNT,
  SET_SELECTED_USER,
  SET_USERS_LIMIT
 } from './users.constants';

const initialState = {
  isLoading: true,
  users: [],
  paginationAmount: 1,
  usersLimit: 10,
};

const usersReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case SET_USERS: {
      return {
        ...state,
        users: payload,
      };
    }

    case SET_SELECTED_USER: {
      return {
        ...state,
        users: state.users.map((user) => {
          user.isSelected = user.id === payload ? true : false;

          return user;
        }),
      };
    }

    case SET_USERS_PAGINATION_AMOUNT: {
      return {
        ...state,
        paginationAmount: payload,
      };
    }

    case SET_USERS_LIMIT: {
      return {
        ...state,
        usersLimit: payload,
      };
    }

    default:
      return state;
  }
};

export default usersReducer;
