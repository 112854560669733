const { REACT_APP_WS_URL } = process.env;
const PING_PONGINTERVAL = 30000;

export class Socket {
  userId;
  id;
  socket;
  interval;
  reconnectAttemptsCount = 0;
  maxReconnectAttemptsCount = 5;
  listeners = [];
  lastPongReceivedAt = null;

  constructor(userId, role) {
    if (typeof Socket.instance === 'object') {
      return Socket.instance;
    }

    this.url = REACT_APP_WS_URL;
    this.userId = userId;
    this.id = +new Date();

    Socket.instance = this;

    return this;
  }

  send(event, data = {}) {
    this.socket.send(JSON.stringify({ event, data }));
  }

  listen(callback) {
    this.listeners.push(callback);

    this.socket.onmessage = event => {
      this.listeners.forEach(lsr => lsr(event));
    };
  }

  stopListening(callback) {
    this.listeners = this.listeners.filter(lsr => lsr !== callback);
  }

  initListeners(onOpen, onError, onClose) {
    this.socket = new WebSocket(
      `${REACT_APP_WS_URL}/connector?userId=${this.userId}&id=${this.id}`
    );

    const setPingPongConnection = () => {
      console.log(`[WSS] id=${this.id} start ping-pong connection`);

      clearInterval(window.intervalID);

      window.intervalID = setInterval(() => {
        this.socket.send(JSON.stringify({ event: 'ping' }));

        if (this.lastPongReceivedAt) {
          // Обработать окончание сессии
          if (((new Date()) - this.lastPongReceivedAt) > PING_PONGINTERVAL * 2) {
            window.location.href = '/';
          }
        }

      }, PING_PONGINTERVAL);
    };

    const mapingToWindow = () => {
      // window.socket = this.socket;
    }

    this.socket.onopen = () => {

      this.stopListening(() => this.handlePongResponse);

      this.listen(() => this.handlePongResponse);

      clearInterval(window.socketReconnectInterval);

      window.socketReconnectInterval = null;

      this.reconnectAttemptsCount = 0;

      console.log(`[WSS] id=${this.id} connected`);

      setPingPongConnection();
      onOpen();
      mapingToWindow();
    };

    const reconnect = (() => {

      return () => {

        this.reconnectAttemptsCount++;

        if (this.reconnectAttemptsCount === this.maxReconnectAttemptsCount) {

          clearInterval(window.socketReconnectInterval);

          this.reconnectAttemptsCount = 0;

          if (this.onReconnectError) this.onReconnectError();

          return window.socketReconnectInterval = null;

        }

        window.socketReconnectInterval = window.socketReconnectInterval || setInterval(() => {
          this.initListeners(onOpen, onError, onClose);
        }, 2000);
      };

    })();

    this.socket.onclose = (e) => {

      console.log(
        'Socket is closed.',
        e.reason
      );

      if (onClose) onClose(e);

      reconnect();
    };
    
    this.socket.onerror = (e) => {
      console.log(
        'Socket error. Reconnect will be attempted in 1 second.',
        e.reason
      );

      if (onError) {

        // https://stackoverflow.com/a/77586690
        reconnect();

        onError(e);

      }

    };
  }

  handlePongResponse(event) {

    const data = event?.data;
    if (!data) {
      return;
    }

    const toJson = JSON.parse(data);
    const customEvent = toJson.event;

    if (customEvent === 'pong') {
      this.lastPongReceivedAt = new Date();
    }
  }

  getInstance(id) {
    return this;
  }

  close() {
    this.socket.close();
  }

  onReconnectError() {

  }
}
