import React from "react";
import { Text, Icon } from '@ui-kit';
import {RIGHT_PRODUCTS} from './righttoproducts.consts';

const Rights = () => {
    return (
        <>
            {RIGHT_PRODUCTS.map((item,index) => (
                <div key={`item-${index}`} className="rights__item">
                    <div className="icon">
                        <Icon src={item.ico} alt={item.alt}/>
                    </div>
                    <div  className="rights__item-content">
                        <Text as="h3" className="title">
                            {item.title}
                        </Text>
                        <Text as="h5" className="description">
                        {item.description}
                        </Text>
                    </div>
                </div>
            ))}
        </>
    )
}

export default Rights;