import { Validation } from '@shared/lib/utils';

const onResetFormValid = (formData) => {
  let errorsState = {};

  const validationPassword = new Validation({
    field: formData.password,
    title: 'Password',
  })
    .isRequired()
    .minLenght(8)
    .maxLenght(32)
    .containLettersAndNumbers()
    .getErrors();

  const validationConfirm = new Validation({
    field: formData.confirm,
    title: 'Confirm password',
  })
    .isRequired()
    .getErrors();
  
  if (formData.password !== formData.confirm) {
    errorsState = {
      ...errorsState,
      confirm: 'Passwords do not match!',
    };
  }

  if (validationPassword.length) {
    errorsState = {
      ...errorsState,
      password: validationPassword[0],
    };
  }

  if (validationConfirm.length) {
    errorsState = {
      ...errorsState,
      confirm: validationConfirm[0],
    };
  }

  return errorsState;
};

export default onResetFormValid;
