import React, {useState, memo} from "react";
import "./waiting.scss";

import { Icon, Text } from "@ui-kit";

const WaitingPreloader = ({keyText = ''}) => {
    const [textContent, setTextContent] = useState(TEXT_CONTENT_ARRAY[keyText]);

    useState(()=> {
        const STEP = 3;
        if (Array.isArray(textContent)){
            textContent.forEach((item, index)=> {
                setTimeout(()=> setTextContent(textContent[index]), STEP * index * 1000);
            })
        }

        return ()=>{
            setTextContent(null);
        }
    }, [keyText]);

    
    return (
        <div id="waiting-background" className="background-default preloader-waiting">
                <div className="waiting-popup__wrapper">
                    <Icon src="/images/tiger_waiting.png"/>
                    <Icon src="/images/oval_loading.gif" className="waiting-spinner"/>
                    <div className="preloader-waiting">
                       <Text as="h4" className="preloader__title">
                            {(!Array.isArray(textContent) && textContent) || '...'} 
                        </Text>
                    </div>
            </div>
        </div>
    );
}

const TEXT_CONTENT_ARRAY = {
    makeInvoice: [ 
        'Verifying and completing your order with care...',
        'Choosing the best packaging...',
        'Packing the illustrations with love...'
    ],
    payInvoice: [
        'Connecting to the banking system...',
        'Carefully counting the money...',
        'Successfully confirming the payment...',
    ],
    downloadArchive: [
        'Verifying and connecting to our storage...',
        'Choosing the best packaging...',
        'Packing the illustrations with love...'
    ]
}

export default memo(WaitingPreloader);