import React from 'react';
import { Icon, Status } from '@ui-kit';
import Dollar from '@ui-kit/icons/dollar-green500.svg';
import Inprogress from '@ui-kit/icons/edit-3-blueLight500.svg';
import Wait from '@ui-kit/icons/clock-orange500.svg';
import Crown from '@ui-kit/icons/crown-pink500.svg';
import Download from '@ui-kit/icons/download-blueGray500.svg';
import CrownValume from '@ui-kit/icons/crown-volume.svg';

const { REACT_APP_PROJECTS_URL } = process.env;

const ProjectItem = ({ project = {}, onClick = ()=>{}, updatedProjects = []}) => {
  let {
    id,
    dir,
    user,
    rate,
    title,
    isSelected,
    sketchImage,
    renderImageUrl,
    reason_for_deletion,
    notificationsToUser,
  } = project;

  const classes = ['admin__table-row'];

  if (isSelected) {
    classes.push('current');
  }

  if (reason_for_deletion) {
    classes.push('deleted');
  }

  const updatedProject = !!updatedProjects.filter((item) => +item === +id).length;

  const showNotificationLabel = ({ markType, id }) => {
    switch (
      markType //ready, wait, in_progress ,download, switch_license
    ) {
      case 'sketchReady':
      case 'illustrationReady':
        return (
          <div key={`notification-${id}`} className="icon__shape ready">
            <Icon src={Dollar} />
          </div>
        );

      case 'waitingResponse':
        return (
          <div key={`notification-${id}`} className="icon__shape wait">
            <Icon src={Wait} />
          </div>
        );

      case 'sourceReady':
        return (
          <div key={`notification-${id}`} className="icon__shape download">
            <Icon src={Download} />
          </div>
        );

      case 'switchingToExclusive':
        return (
          <div
            key={`notification-${id}`}
            className="icon__shape switch_license"
          >
            <Icon src={Crown} />
          </div>
        );

      default:
        //'workProgress'
        return (
          <div key={`notification-${id}`} className="icon__shape in_progress">
            <Icon src={Inprogress} />
          </div>
        );
    }
  };

  const renderPreview = () => {
    // if (!sketchImage && !renderImage) {
    //   return <EmptyProject />;
    // }

    if (sketchImage && !renderImageUrl) {
      return (
        <Icon
          src={`${REACT_APP_PROJECTS_URL}/${dir}/${sketchImage}`}
          className="new-project__preview-img"
        />
      );
    }

    if (renderImageUrl) {
      return (
        <Icon
          src={renderImageUrl}
          className="new-project__preview-img"
        />
      );
    }
  };

  const renderLicensesType = () => {
    if (rate.type === 'hourlyWithoutLicense') {
      return (
        <div className="user-project-licennses__icon">
          <Icon src={CrownValume} alt="crown-mark" />
        </div>
      );
    }
  };

  return (
    <div className={classes.join(' ')} onClick={() => onClick(project)}>
      <div className="d-flex ai-center project-name">
        <div className="pr project-name__icon__wrapper">
          {renderPreview()}
          {renderLicensesType()}
        </div>
        <div className="d-flex fd-column project-title">
          <div className='project-status'>
            <h5>{title}</h5>
            {updatedProject && <Status isUserOnline={true} size="xl" />}
          </div>
          <h5 className="project-id">#{id}</h5>
        </div>
      </div>

      <div className="project-customer">
        <h5>
          {user?.firstName || ''} {user?.lastName || ''}{' '}
        </h5>
        <h6>{user?.email || ''}</h6>
      </div>

      <div className="project-notification">
        {notificationsToUser &&
          notificationsToUser.map((item) => showNotificationLabel(item))}
      </div>
    </div>
  );
};

export default ProjectItem;
