import {
  SET_LOADING,
  SET_PROJECTS,
  SET_NEW_PROJECT,
  SET_SELECTED_PROJECTS,
  SET_TO_EMPTY_PROJECTS,
  REMOVE_PROJECT_FROM_LIST,
  SET_NEW_PROJECT_CLEAR_EMPTY,
  SET_PROJECT_PAGINATION_AMOUNT,
  SET_PROJECT_PROPERTIE_IN_LIST,
  SET_CURRENT_PROJECT_TO_PROJECTS_LIST,
  SET_PROJECTS_SEARCH,
} from './projects.constants';

const initialState = {
  isLoading: true,
  projects: [],
  paginationAmount: 1,
  projectsSearchValue: '',
};

const projectsReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case SET_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case SET_PROJECTS: {
      return {
        ...state,
        projects: payload,
      };
    }

    case SET_TO_EMPTY_PROJECTS: {
      return {
        ...state,
        projects: payload,
      };
    }

    case SET_NEW_PROJECT: {
      return {
        ...state,
        projects: [payload, ...state.projects],
      };
    }

    case SET_NEW_PROJECT_CLEAR_EMPTY: {
      const clearedProjects = state.projects.filter(
        (project) => project.type !== 'emptyProject'
      );

      return {
        ...state,
        projects: [payload, ...clearedProjects],
      };
    }

    case REMOVE_PROJECT_FROM_LIST: {
      const projectsFiltered = state.projects.filter(
        (project) => project.id !== payload
      );

      return {
        ...state,
        projects: projectsFiltered,
      };
    }

    case SET_SELECTED_PROJECTS: {
      return {
        ...state,
        projects: state.projects.map((project) => {
          project.isSelected = project.id === payload ? true : false;

          return project;
        }),
      };
    }

    case SET_CURRENT_PROJECT_TO_PROJECTS_LIST: {
      const renderImage =
        payload.renderImage !== null
          ? payload.renderImage + `?${Date.now()}`
          : null;

      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.id === payload.id) {
            project.title = payload.title;
            project.executors = payload.executors;
            project.rate = payload.rate;
            project.followingId = payload.followingId;
            project.notificationsToUser = payload.notificationsToUser;
            project.renderImage = renderImage;
          }

          return project;
        }),
      };
    }

    case SET_PROJECT_PAGINATION_AMOUNT: {
      return {
        ...state,
        paginationAmount: payload,
      };
    }

    case SET_PROJECT_PROPERTIE_IN_LIST: {
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.id === payload.id) {
            project[payload.propertie.key] = payload.propertie.value
          }

          return project;
        }),
      };
    }

    case SET_PROJECTS_SEARCH: {
      return {
        ...state,
        projectsSearchValue: payload,
      };
    }

    default:
      return state;
  }
};

export default projectsReducer;
