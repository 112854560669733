import React from "react";
import './view_more.scss';
import { Text} from '@ui-kit';

const ViewMore = ({text, showDots, onClick}) => {
    return (
        <Text as="span" className="info__viewmore" onClick={onClick}>
            {showDots && (
                <Text as="span" className="info__dots">
                ...
                </Text>
            )}       
            &nbsp;{text}
        </Text>
    )
}

export default ViewMore;