import detect from 'mobile-detect';

export const deviceType = () => {
    const md = new detect(window.navigator.userAgent);
    const phone = md.phone();
    const tablet = md.tablet();
    const mobile = md.mobile() || navigator?.userAgentData?.mobile;
    const os = md.os() || navigator?.userAgentData?.platform || navigator?.platform;
    const touch = (navigator.maxTouchPoints || 'ontouchstart' in document.documentElement);
    const orientation = window?.screen?.orientation?.type || window?.orientation;

    return {phone, tablet, mobile, os, touch, orientation};

}