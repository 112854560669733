import React from "react";
import './carousel-arrow.scss';
import { Icon } from '@ui-kit';

import ChevronIcon from '@ui-kit/icons/chevron-gray700.svg';
import ArrowIcon from '@ui-kit/icons/arrow-gray700.svg';
import ArrowLongIcon from '@ui-kit/icons/arrow-long-gray700.svg';

/*
 *<CarouselArrow direction="prev" theme="button-arrow" size="sm" isDisabled={true} />
 */

const CarouselArrow = ({
    direction  = 'prev',
    className  = '',
    theme      = 'button-chevron',
    size       = 'md',
    isDisabled = false,
    onClick, 
    currentSlide, 
    slideCount, 
    ...props
}) => {
    const classes = [
        'carousel-arrow', 
        `slick-${direction}`, 
        'slick-arrow',
        (currentSlide === 0 || currentSlide === slideCount - 1 ? " slick-disabled" : ""),
        direction,
        theme,
        size,
        ...className.split(' ')
    ];

    if (isDisabled) {
        classes.push('disabled');
    }

    const renderIcon = (theme) => {
        if (theme === 'button-chevron') {
            return ChevronIcon;
        }

        if (theme === 'button-arrow') {
            return ArrowIcon;
        }

        return ArrowLongIcon;
    }

    return (
        <div 
            {...props} 
            className={classes.join(' ')} 
            onClick={isDisabled ? () => {}: onClick}
            //aria-hidden="true"
            //inert="true"
            aria-disabled={currentSlide === 0 ? true : false}
        >
           <Icon src={renderIcon(theme)} />
        </div>
    );
};

export default CarouselArrow;