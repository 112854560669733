import {
  LICENSES_URL,
  BROWSE_SEARCH_URL,
  ADMIN_PROJECT_CREATE_URL,
} from '@app/routes';

import { Link } from '@ui-kit';

export let MENU_LIST = [
  {
    isSearch: true,
  },
  {
    component: (
      <Link to={BROWSE_SEARCH_URL} theme="text">
        Gallery
      </Link>
    ),
  },
  {
    component: (
      <Link to={ADMIN_PROJECT_CREATE_URL} theme="text">
        Order Illustrations
      </Link>
    ),
  },
  {
    component: (
      <Link to={LICENSES_URL} theme="text">
        License
      </Link>
    ),
  },
];
