export const SET_CURRENT_PROJECT                  = 'SET_CURRENT_PROJECT';
export const SET_PROJECT_LOADING                  = 'SET_PROJECT_LOADING';
export const SET_CHAT_MESSAGES                    = 'SET_CHAT_MESSAGES';
export const SET_CHAT_MESSAGE                     = 'SET_CHAT_MESSAGE';
export const SET_PROJECT_PROPERTIE                = 'SET_PROJECT_PROPERTIE';
export const SET_PROJECT_IMAGE                    = 'SET_PROJECT_IMAGE';
export const SET_EXECUTORS                        = 'SET_EXECUTORS';
export const SET_PROJECT_EXECUTORS                = 'SET_PROJECT_EXECUTORS';
export const SET_CURRENT_STEPS_HOURS              = 'SET_CURRENT_STEPS_HOURS';
export const SET_PROJECT_COST                     = 'SET_PROJECT_COST';
export const SET_IS_PROJECT_CHANGED               = 'SET_IS_PROJECT_CHANGED';
export const SET_IS_UNSAVED_PROJECT_SHOW          = 'SET_IS_UNSAVED_PROJECT_SHOW';
export const SET_PRODUCT_TO_PROJECT               = 'SET_PRODUCT_TO_PROJECT';
