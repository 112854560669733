import React from 'react';
import './user-project.scss';
import { Icon, Text, Link, Status } from '@ui-kit';
import { EmptyProject } from '@features';
import CrownValume from '@ui-kit/icons/crown-volume.svg';

const { REACT_APP_PROJECTS_URL } = process.env;

const UserProjectItem = ({ 
  project = {}, 
  onClick = ()=>{}, 
  updatedProjects = [],
  isMobileScreenView = false,
}) => {
  const {
    id,
    dir,
    title,
    rate,
    renderImageUrl,
    sketchImage,
    isSelected = false,
    executorContent,
    notificationsToUser,
  } = project;

  const updatedProject = !!updatedProjects.filter((item) => +item === +id).length;

  const renderPreview = () => {
    if (sketchImage && !renderImageUrl) {
      return (
        <Icon
          src={`${REACT_APP_PROJECTS_URL}/${dir}/${sketchImage}`}
          className="user-project__preview-img"
        />
      );
    }

    if (!renderImageUrl) {
      return <EmptyProject />;
    }

    if (renderImageUrl) {
      return (
        <Icon
          src={renderImageUrl}
          className="user-project__preview-img"
        />
      );
    }
  };

  const renderLicensesType = () => {
    if (rate.type === 'hourlyWithoutLicense') {
      return (
        <div className="user-project-licennses__icon">
          <Icon src={CrownValume} alt="crown-mark" />
        </div>
      );
    }
  };

  const renderExecutors = (creator) => {
    if (!creator) {
      return (
        <Text as="span" className="user-project-supname">
          Brief :
        </Text>
      );
    }

    return (
      <Text as="span" className="user-project-supname">
        {`${creator?.firstName || 'Brief '}:`}
      </Text>
    );
  };

  const renderExecutorsContent = () => {
    if (!executorContent) {
      return;
    }

    let subText = '';

    if (executorContent?.type === 'text') {
      subText = renderExecutors(executorContent.creator);
    }

    if (executorContent?.type === 'img') {
      subText = <Text as="span" className="user-project-supname">File</Text>;
    }

    return (
      <>
        {subText}
        <Text as="h5" className="user-project-executor_content ">
          {`${executorContent?.text || ''}`}
        </Text>
      </>
    );
  };

  const checkPaymentLink = () => {
    if (!notificationsToUser.length) return;

    const { markType, hours, minutes } = notificationsToUser[0];
    const paymentsType = ['sketchReady', 'illustrationReady', 'sourceReady'];

    const paymentTypeFound = paymentsType.find((type) => type === markType);

    const hasTimeSpent = !!(hours + minutes);

    if (paymentTypeFound && hasTimeSpent) {
      return (
        <Link
          to={`/checkout/projects/${id}`}
          theme="text"
          className="notification__link"
        >
          Go to the payment
        </Link>
      );
    }
  };

  const onParentClick = (e) => {
    if (e.target.localName !== 'a') {
      onClick(project);
    }
  }

  const classes = ['user-project__container'];

  if (isSelected && !isMobileScreenView) {
    classes.push('current');
  }

  return (
    <div className={classes.join(' ')} onClick={onParentClick}>
      <div className="pr user-project__wrapper">
        {renderPreview()}
        {renderLicensesType()}
      </div>

      <div className="user-project__descr">
        <div className='project-status'>
          <Text as="h5" className="user-project-name">
            {title || 'New project'}
          </Text>
          {updatedProject && <Status isUserOnline={true} size="xl" />}
        </div>
        
        <div className="d-flex ai-center user-project-descr">
          {renderExecutorsContent()}
        </div>
        {checkPaymentLink()}
      </div>
    </div>
  );
};

export default UserProjectItem;
