import React, { useState, useCallback } from "react";
import "./briefInfo.scss";

import { Text, Accordion } from "@ui-kit";
import { BRIEFINFO } from "./brief-info.consts";

const BriefInfoComponent = () => {
  const [accordionState, setAccordionState] = useState(BRIEFINFO);

  const choosingItem = useCallback(
    (value) => {
      const newState = accordionState.map((item) => {
        item.value === value
          ? (item.active = !item.active)
          : (item.active = false);
        return item;
      });

      setAccordionState(newState);
    },
    [accordionState]
  );

  return (
    <div className="info-panel">
      <Text as="h3" className="brief-info__tittle">
        Tips for writing a brief
      </Text>
      <Text as="h6" className="brief-info__suptittle">
        Here you can find examples of questions that arise while working on your
        project, and some tips on how to answer them. By providing detailed
        answers to these questions in advance, you will speed up the evaluation
        process.
      </Text>
      <Accordion size="sm" onClick={choosingItem} items={BRIEFINFO} />
    </div>
  );
};

export default BriefInfoComponent;
