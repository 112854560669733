import React from "react";
import "./cartLimitPopup.scss";

import { setCartLimitpopupAC } from "@entities";
import { useDispatch } from 'react-redux';
import { IconFeatured, Icon, Text, Button } from "@ui-kit";

import Warning from "@ui-kit/icons/alert-triangle-warning600.svg";


const CartLimitPopup = () => {
    const dispatch = useDispatch();

    const onBackgroundClick = (e) => {
        if (e.target.id === 'cart-limit-background') {
            closePopup();
        }
    };

    const closePopup = () => {
        dispatch(setCartLimitpopupAC(false));
    }

    return  (
        <div
            id="cart-limit-background"
            className="background-default limit-popup__bkg"
            onMouseDown={onBackgroundClick}
        >
             <div className="limit-popup__wrapper">
                <div className="limit-popup__imgs">
                    <div className="limit__icon-feature">
                        <IconFeatured src={Warning} theme="warning" size="lg"/>
                    </div>
                    <Icon src={"/images/tiger_suprise.png"} className="limit__img"/>
                </div>
                <Text as="h4" className="limit__title">Oops, the cart limit is reached</Text>
                <Text as="h6"  className="limit__suptitle">You can put only 50 items in the cart. If you free some space in it, 
                you will be able to add this illustration.</Text>
                <div className="limit__actions">
                    <Button size="lg" onClick={closePopup}>Okay, got it</Button>
                </div>
            </div>
        </div>
    )
}

export default CartLimitPopup;