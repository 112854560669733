export const LICENSES = [
  {
    title: 'One purchase = 1 project',
    description:
      'One purchase allows you to use the Digital Product in one project. For multiple projects buy it as many times as it is necessary.',
    type: 'extended',
  },
  {
    title: 'Copyright Restrictions',
    description:
      'It is forbidden to represent that the Digital Product was created by you. Please, mention the creator of the illustration in the product description. Take necessary information on the author’s page.',
    type: 'extended',
  },
  {
    title: 'No trademarks',
    description:
      'You can’t use the Digital Product (in whole or in part) as a trademark, service mark, logo, or other indication of origin, or as part thereof.',
    type: 'extended',
  },
  {
    title: 'Resales are not possible',
    description:
      'You can sell any items with our Digital Product, however you cannot resell the Digital Product itself, including with superficial modifications.',
    type: 'extended',
  },
  {
    title: 'Your product, your rules',
    description: 'No restrictions',
    type: 'exclusive',
  },
];
