import React from 'react';
import './header-navigation.scss';
import { Link, Icon } from '@ui-kit';

const HeaderNavigation = ({ headerLinks = [], className = '' }) => {
  const classes = ['header__navigation', ...className.split(' ')];

  const renderLink = ({ title, ico = '' }) => {
    if (ico) {
      return <Icon src={ico} />;
    }

    return <>{title}</>;
  };

  const renderPath = (link) => {
    const { path } = link;
    
    return path;
  };

  return (
    <>
      <ul className={classes.join(' ')}>
        {headerLinks.map((link, index) => (
          <li key={index}>
            <Link
              key={link.title}
              to={renderPath(link)}
              theme="tertiary-gray"
              className={link.classes && link.classes}
            >
              {renderLink(link)}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default HeaderNavigation;
