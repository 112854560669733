import React from 'react';
import './cart-item.scss';

import { Text, Icon } from '@ui-kit';
import { FilesTypes, AvatarLabel, LicenseLine } from '@features';
import Trash from '@ui-kit/icons/trash-gray500.svg';

const { REACT_APP_PRODUCTS_URL } = process.env;

const CartItem = ({ 
  product = {},
  showBacket = true,
  removeProduct =()=>{},
  //onSelect = ()=>{},
}) => {
  const {
    id,
    pathToBrowse,
    price,
    illustration_formats,
    //isChecked = false,
    user = {},
    ava,
    firstName,
    lastName,
  } = product;

  if (!user.firstName) {
    user.ava = ava;
    user.firstName = firstName;
    user.lastName = lastName;
  }

  const removeItemFromCart = () => {
    if (!id) {
      return;
    }
    removeProduct(id);
  };


  return (
    <div className="d-flex cart__item">
      {/* <CheckBox checked={isChecked?"checked":false} onChange={()=> onSelect(id, !isChecked)}/>
       */}
      <div className="d-flex ai-center jc-sb price price_mobile">
        <AvatarLabel author={user} />

        <Text as="h4" className="cost">
          {price} $
        </Text>
      </div>
      <div className="cart__img">
        <Icon
          src={`${REACT_APP_PRODUCTS_URL}/${pathToBrowse}`}
          className=""
          alt={`cart-item-${id}`}
        />
      </div>

      <div className="cart__item-description">
        <div className="d-flex ai-center jc-sb price">
          <AvatarLabel author={user} />

          <Text as="h4" className="cost">
            {price} $
          </Text>
        </div>
        <div className="cart-item__container">
          <LicenseLine position='bottom' ancorPosition='anchor-top-middle'/>
        </div>

        <div className="cart-item__container">
          <FilesTypes illustrationFormats={illustration_formats} />
        </div>

        {showBacket && (
          <div className="d-flex ai-center jc-fe cart-item__trash">
            <Icon
              src={Trash}
              className="trash__icon"
              onClick={removeItemFromCart}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CartItem;
