import React from 'react';
import { useDispatch } from 'react-redux';
import { Typing } from '@widgets';
import { Text, Input, Button } from '@ui-kit';
import { setModalLoginShowAC } from '@entities';
import Search from '@ui-kit/icons/search-gray500.svg';
import { GreetingsMessage } from '@features';

const PanelLogin = () => {
  const dispatch = useDispatch();

  const goToLogin = () => {
    dispatch(setModalLoginShowAC(true));
  };

  return (
    <div className="login__chat-body d-flex fd-column">
      <div className="login__search">
        <Input
        placeholder="Search by projects"
        iconLeft={Search}
        onChange={() => {}}
      />
      </div>
      <Text as="h4" className="chats__tablet-header-title">
        Your project can be here
      </Text>
      <div className="login__chat-body-main">
        <GreetingsMessage />
      </div>
      <div className="chat__typing">
        <Typing
          isDisabled={true}
        />
      </div>
      <div className="login__create-project">
        <Button
          theme="secondary-gray"
          size="md"
          onClick={goToLogin}
        >
          Create New Project
        </Button>
      </div>
    </div>
  )
};

export default PanelLogin;