import React, { useEffect, useRef, memo, useState } from 'react';
import './admin-aside.scss';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import UserProjectItem from './UserProject';
import { Button } from '@ui-kit';
import { LoadingAdmin } from '@shared/ui';
import {
  setChatMessagesAC,
  setCurrentProjectAC,
  setNewProjectsAC,
  setSelectedProjectAC,
  setActivatedEmailShowAC,
  getUserProjectTC,
  getHourlyrateValue,
  getCustomer,
  projectInstance,
  Bot,
  getUserProjectsTC,
  isAuth,
  getProjectsIsLoading,
  setProductToProjectAC,
  getIsMobileScreenView,
  isProjectsLoadingAC,
  setProjectsSearchValueAC,
  getProjectsSearchValue,
} from '@entities';
import { 
  GreetingsMessage, 
  SearchFormBg, 
  NotFoundMessage 
} from '@features';


export const Asside = ({
  projects = [],
  currentProject = {},
  setActivePanelAndQuery = () => {},
  isDisabledCreateProject = false,
  setDisabledChat = ()=>{},
  setIsChatView,
}) => {
  const dispatch = useDispatch();

  const asside = useRef();
  const assideContent = useRef();
  const createProject = useRef();

  const currentUser = useSelector(getCustomer);
  const hourleyRateValue = useSelector(getHourlyrateValue);
  const isUserAuth = useSelector(isAuth);
  const projectsIsLoading = useSelector(getProjectsIsLoading);
  const isMobileScreenView = useSelector(getIsMobileScreenView);
  let projectsSearchValue = useSelector(getProjectsSearchValue);

  const [forceRender, setForceRender] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const updatedProjects = currentUser?.updatedProjects;
  const productToProject = JSON.parse(localStorage.getItem('productToNewProject'));
  const isCreateNewExternal = searchParams.get('create-new');

  const loginClass = !isUserAuth ? "login" : "";
  const userStyles = ["admin__aside",  loginClass];


  useEffect(() => {
    const search = searchParams.get('search');
    if (search && !projectsSearchValue) {
      // eslint-disable-next-line
      projectsSearchValue = search;
    }
  }, []);

  useEffect(() => {
    if(projectsIsLoading) {
      return setForceRender(true);
    }

    setTimeout(() => setForceRender(false), 1000);
  }, [projectsIsLoading]);

  useEffect(() => {
    if (projects.length || isMobileScreenView) {
      createProject.current.classList.add('fixed');
      assideContent.current.classList.add('bottom-align');
    }
  }, [projects, isMobileScreenView]);

  useEffect(() => {
    if(isCreateNewExternal && isUserAuth && !forceRender) {
      dispatch(setProductToProjectAC(productToProject));
      setTimeout(()=> onCreateProject(), 1000);
      localStorage.removeItem('productToNewProject');
    }
    // eslint-disable-next-line
  },[forceRender])

  const onClick = (project) => {
    if (currentProject.id === project.id) {
      if (isMobileScreenView) setIsChatView(true);
      return;
    }

    dispatch(setChatMessagesAC([]));
    dispatch(getUserProjectTC(project.id));
    dispatch(setSelectedProjectAC(project.id));

    setActivePanelAndQuery('info');
    searchParams.set('project_asset_id', project.id);
    setSearchParams(searchParams, {replace: true});
    setIsChatView(true);
  };

  const onCreateProject = () => {
    const isActivatedEmail = +currentUser.isActivated;
    if (!isActivatedEmail) {
      return dispatch(setActivatedEmailShowAC(true));
    };

    const isEptyProjectConsist = projects.find(
      (item) => item.type === 'emptyProject'
    );

    if (isEptyProjectConsist) {
      return;
    }

    const emptyProject = projectInstance.create({
      priceValue: hourleyRateValue, // from redux settings - 23
      followingId: 2,
    });

    dispatch(setNewProjectsAC(emptyProject));
    dispatch(setCurrentProjectAC(emptyProject));
    dispatch(setSelectedProjectAC(emptyProject.id));

    const bot = new Bot({ firstName: currentUser.firstName, product: productToProject });
    const messages = bot.getAllMessages();
    dispatch(setChatMessagesAC(messages));
    
    setDisabledChat(false);

    searchParams.set('user_info_active', 'brief');
    searchParams.set('project_asset_id', emptyProject.id);
    searchParams.delete('create-new');
    return setSearchParams(searchParams, {replace: true});
  };

  const onSubmitSearch = (event) => {
    event.preventDefault();
  };

  let projectsSearchTimeout = null;

  const onSearchProject = async(event) => {
    const value = event?.target?.value || '';
    const projectAssetId = searchParams.get('project_asset_id');

    if (projectsSearchValue && !value) {
      dispatch(isProjectsLoadingAC(true));
    };

    dispatch(setProjectsSearchValueAC(value));
    clearInterval(projectsSearchTimeout);

    projectsSearchTimeout = setTimeout(() => {
      dispatch(getUserProjectsTC({ limit: 100, page: 0, search: value }, projectAssetId));
    }, 1000);

    event.preventDefault();
  };

  const renderProjectsList = () => {
    if (isMobileScreenView) {
      if (forceRender) {
        return <LoadingAdmin />
      }

      if(!projects.length && projectsSearchValue) {
        return <NotFoundMessage value={projectsSearchValue} />
      };

      if(!projects.length) {
        return <GreetingsMessage />
      };
    };

    return projects.map((project, index) => (
        <UserProjectItem
          key={`user-project-${index}`}
          project={project}
          onClick={onClick}
          updatedProjects={updatedProjects}
          isMobileScreenView={isMobileScreenView}
        />
      ));
  };

  return (
    <div className={userStyles.join(" ")}>
      <div className="admin__aside-search d-flex">
        <SearchFormBg
          onSubmit={onSubmitSearch}
          onChange={onSearchProject}
          placeholder="Search by projects"
          className="mr-12"
          value={projectsSearchValue}
        />
      </div>
      <div className="admin__aside-content" ref={asside}>
        <div className="project__data" ref={assideContent}>
          {renderProjectsList()}
        </div>
      </div>
      <div className="project__create" ref={createProject}>
          <Button
            theme="secondary-gray"
            isDisabled={isDisabledCreateProject}
            onClick={onCreateProject}
          >
            Create New Project
          </Button>
        </div>
    </div>
  );
};

export default memo(Asside);
