import React from "react";
import Slider from 'react-slick';
import { CarouselArrow, Icon, Badge, Text, TooltipWithoutAnchor } from '@ui-kit';
import Clock from '@ui-kit/icons/clock-gray500.svg'

const SliderHourley = () => {

  var sliderSettings = {
    infinite       : true,
    arrows         : true,
    fade           : true,
    dots           : true,
    prevArrow: <CarouselArrow direction="prev" />,
    nextArrow: <CarouselArrow direction="next" />
  };

    return (
        <div className="rates-slider__wrapper custom__dots pr">
            <Slider {...sliderSettings} >   
              <div className="rates__slider-online">
                <div className="rates__wrapper rates__wrapper-oneline pr">
                    <Badge text='4 hours' icon={Clock} className="rates__badge"/>
                    <TooltipWithoutAnchor>
                      <Text as="h6" className="title">Character</Text>
                      <Text as="h6" className="sub__title">Minimum details</Text>
                    </TooltipWithoutAnchor>
                    <Icon src={"/images/carusel/rates/rate1.1.jpg"} alt="slider_rates1.1"/>
                </div>
                <div className="rates__wrapper rates__wrapper-oneline pr">
                    <Badge text='6 hours' icon={Clock} className="rates__badge"/>
                    <TooltipWithoutAnchor>
                      <Text as="h6" className="title">Character</Text>
                      <Text as="h6" className="sub__title">High level of detail and <br/>
                        additional accessories
                      </Text>
                    </TooltipWithoutAnchor>
                    <Icon src={"/images/carusel/rates/rate1.2.jpg"} alt="slider_rates1.2"/>
                </div>
              </div>
    
              <div className="">
                <div className="rates__wrapper mb-24 pr">
                  <Badge text='4 hours' icon={Clock} className="rates__badge"/>
                  <TooltipWithoutAnchor>
                    <Text as="h6" className="title">Background</Text>
                    <Text as="h6" className="sub__title">Interior with minimum details
                    </Text>
                  </TooltipWithoutAnchor>
                  <Icon src={"/images/carusel/rates/rate2.1.jpg"} alt="slider_rates2.1"/>
                </div>
                <div className="rates__wrapper pr">
                  <Badge text='8 hours' icon={Clock} className="rates__badge"/>
                  <TooltipWithoutAnchor>
                    <Text as="h6" className="title">Background</Text>
                    <Text as="h6" className="sub__title">Interior with a high level of detail <br/>
                    and city view from the window
                    </Text>
                  </TooltipWithoutAnchor>
                  <Icon src={"/images/carusel/rates/rate2.2.jpg"} alt="slider_rates2.1"/>
                </div>
              </div>
            </Slider>
      </div>
    )
}

export default SliderHourley;