import React from "react";
import './background.scss';

const Background = ({className = '', children}) => {
    const classes = ['background-default', ...className.split(' ')]
    return (
        <div className={classes.join(' ')}>
        {children}
        </div>
    )
}

export default Background;