import React, { useState } from 'react';
import './deleteProject.scss';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Text, Button, IconFeatured } from '@ui-kit';
import {
  projectInstance,
  setChatMessagesAC,
  removeUserProjectTC,
  setCurrentProjectAC,
} from '@entities';
import onDeleteFormValid from './deleteProject-validation.hook';

import InfoDanger from '@ui-kit/icons/alert-circle-error600.svg';

const DeleteProjectPopup = ({
  projectId = null,
  setDeleteProjectShow = ()=>{},
  setUserActiveInfo = ()=>{},
  onSubmitMessage = ()=>{},
}) => {
  const [value, setValue] = useState();
  const [errors, setErrors] = useState({});
  const [isDisabled, setDisabled] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const onBackgroundClick = (e) => {
    if (e.target.id === 'notification-background') {
      setDeleteProjectShow(false);
    }
  };

  const setTextAreaValue = (e) => {
    const value = e.target?.value;
    setValue(value);

    const formErrors = onDeleteFormValid(value);

    if (!value || value === '' || value.trim(' ') === '') {
      return setDisabled(true);
    };

    if (Object.keys(formErrors).length) {
      setDisabled(true);
      return setErrors(formErrors);
    };

    setErrors({});
    setDisabled(false);
  };

  const onDelete = () => {
    if (Object.keys(errors).length) {
      return;
    };

    let clearMessage = value.trim();

    const property = {
      projectId,
      reason: clearMessage,
    };

    onSubmitMessage(clearMessage);
    dispatch(removeUserProjectTC(property, afterRemovingPreparing));
  };

  const afterRemovingPreparing = () => {
    setDeleteProjectShow(false);

    searchParams.delete('project_asset_id');
    setUserActiveInfo('faq');

    setSearchParams(searchParams, {replace: true});

    const emptyProject = projectInstance.create();
    dispatch(setCurrentProjectAC(emptyProject));
    dispatch(setChatMessagesAC([]));
  };

  const onCancel = () => {
    setDeleteProjectShow(false);
  };

  return (
    <div
      id="notification-background"
      className="background-default delete-modal"
      onMouseDown={onBackgroundClick}
    >
      <div className="notification-popup__wrapper delete-project">
        <IconFeatured src={InfoDanger} theme="error" size="lg" alt="no-result" />
        <Text as="h4" className="notification-popup__title">
          Delete project
        </Text>
        <Text as="h6" className="notification-popup__subtitle">
          Are you sure you want to cancel the order? Project cancellation
          negatively affects our team. Please be sure you want to do it. If the
          project was completed by BriefStock on part or on whole and you
          haven’t paid for it, you can’t place one more.
        </Text>
        <div className="notification-popup__list">
          <Text as="h6" className="notification-popup__list-title">
            Reason for deleting the project
          </Text>
        </div>
        <div className="delete-project-text-area">
          <textarea
            value={value}
            onChange={setTextAreaValue}
            placeholder="Please, state the reason for order cancellation"
            className={errors.textarea && 'error'}
          />
          {errors.textarea && (
            <Text as="span" className="error">
              {errors.textarea}
            </Text>
          )}
        </div>

        <div className="delete-project-actions">
          <Button
            className="notification__btn publish"
            theme="primary-danger"
            onClick={onDelete}
            isDisabled={isDisabled}
          >
            Delete
          </Button>
          <Button
            className="notification__btn"
            theme="secondary-gray"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteProjectPopup;
