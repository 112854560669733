export const SET_LOADING                          = 'SET_LOADING';
export const SET_PROJECTS                         = 'SET_PROJECTS';
export const SET_SELECTED_PROJECTS                = 'SET_SELECTED_PROJECTS';
export const SET_CURRENT_PROJECT_TO_PROJECTS_LIST = 'SET_CURRENT_PROJECT_TO_PROJECTS_LIST';
export const SET_PROJECT_PAGINATION_AMOUNT        = 'SET_PROJECT_PAGINATION_AMOUNT';
export const SET_TO_EMPTY_PROJECTS                = 'SET_TO_EMPTY_PROJECTS';
export const SET_NEW_PROJECT                      = 'SET_NEW_PROJECT';
export const SET_NEW_PROJECT_CLEAR_EMPTY          = 'SET_NEW_PROJECT_CLEAR_EMPTY';
export const REMOVE_PROJECT_FROM_LIST             = 'REMOVE_PROJECT_FROM_LIST';
export const SET_PROJECT_PROPERTIE_IN_LIST        = 'SET_PROJECT_PROPERTIE_IN_LIST';
export const SET_PROJECTS_SEARCH                  = 'SET_PROJECTS_SEARCH';
