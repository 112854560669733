import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import './userProjectInfo.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Text, Badge, Button } from '@ui-kit';
import { AvatarLabel } from '@features';
import { Stepper } from '@widgets';
import { getCurrentProject, uploadBinaryFileTC } from '@entities';
import { ProjectsContext } from '@shared/hooks';
import { ROLES } from '@entities/user';
import CrownWarningSmal from '@ui-kit/icons/crown-warning500.svg';
import TrashDanger from '@ui-kit/icons/trash-error700.svg';
import DownloadIcon from '@ui-kit/icons/download-white.svg';

const UserProjectInfo = ({setActiveUserInfo = ()=>{}}) => {
  const dispatch = useDispatch();
  const currentProject = useSelector(getCurrentProject);
  const projectsContext = useContext(ProjectsContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    rate,
    followingId,
    executors,
    type,
    workingHoursSum,
    currentStepsHours,
    followingData,
    price,
    isPaid,
    paidHours,
    dir,
    archive,
  } = currentProject;

  const workingHoursSumToInt = (time) => {
    if(time) {
      const hour = +time.split('.')[0];
      let minute = +time.split('.')[1];
      minute = 1 / (60 / minute);

      return hour + minute;
    };

    return 0;
  };
  
  const unpaidHours = workingHoursSumToInt(workingHoursSum) - +paidHours;
  const fullSumHoursPaid = unpaidHours <= 0;
  const isFullPaid = +isPaid && fullSumHoursPaid && +followingId === 8;
  const isArchiveIsString = archive?.includes('http');

  let setDeleteProjectShow;

  if (projectsContext) {
    setDeleteProjectShow = projectsContext.setDeleteProjectShow;
  }

  if (!currentProject.id) {
    return;
  }

  const executorsWithoutLastName = executors.map((executor) => {
    delete executor.lastName;

    return executor;
  });

  const isEmptyProject = type && type === 'emptyProject';
  const reateType = (rate && rate.type) || null;

  const downloadProject = async () => {
    if (!isArchiveIsString) {
      const file = await dispatch(uploadBinaryFileTC({dir, name: archive}));

      return downloadArchive(file);
    }

  
     window.open(
       archive,
       '',
       'toolbar=yes,scrollbars=yes,resizable=yes,top=0,bottom=0,left=2800,width=700,height=600'
     );
  };

  const downloadArchive = (blob) => {
    const link = document.createElement('a');
    link.setAttribute('download', blob.name);

    const href = URL.createObjectURL(blob);
    link.href = href;
    link.setAttribute('target', '_blank');
    link.click();
    URL.revokeObjectURL(href);
  };

  const deleteProject = () => {
    searchParams.set('user_info_active', 'hidden');
    setSearchParams(searchParams, {replace: true});
    setActiveUserInfo('hidden');

    setDeleteProjectShow(true);
  };

  const renderDownload = () => {
    if (isFullPaid) {
      return (
        <Button 
          iconLeft={DownloadIcon} 
          size="sm"
          className="mt-24 mb-16"
          onClick={downloadProject}
        >
          Download illustration
        </Button>
      );
    };
    return (
      <div className="pb-16"></div>
    )
  };

  const renderBadge = () => {
    //hourlyRate -> we provid licenses for use illustration
    //hourlyWithoutLicense -> max rate
    if (reateType === 'hourlyRate') {
      return (
        <>
          <Badge status={true} theme="blue-gray" text="Extended commercial license" />
          <Text as="h6" className="user__info-license-descr">
            Write to your manager in the project chat if you want to upgrade
            your license to an{' '}
            <a href="/licenses#exclusive" target="_blank">
              exclusive
            </a>{' '}
            one.
          </Text>
        </>
      );
    }

    return <Badge icon={CrownWarningSmal} theme="warning" text="Exclusive license" />;
  };

  const renderActionButton = () => {
    if (isEmptyProject) {
      return;
    }

    return (
      <div className="info__delete">
        <Button
          size="lg"
          theme="link-danger"
          iconLeft={TrashDanger}
          onClick={deleteProject}
        >
          Delete project
        </Button>
      </div>
    );
  };

  return (
    <div className="info-panel">
      <div className="divider">
        <Text as="h6" className="guffy__title">
          About
        </Text>
        <Text as="h6" className="info__suptittle">
          Here are current project milestones. Info is updated as work
          progresses.
        </Text>
      </div>

      <div className="divider">
        <Text as="h6" className="info__tittle">
          Project stages
        </Text>
        <div className="info__steps ">
          <Stepper
            followingId={followingId}
            currentStepsHours={currentStepsHours}
            workingHoursSum={workingHoursSum}
            unpaidHours={unpaidHours}
            followingData={followingData}
            cost={price?.value || 0}
          />
        </div>
        {renderDownload()}
      </div>

      <div className="user__info-license divider">
        <Text as="h6" className="info__tittle">
          License
        </Text>
        {renderBadge()}
      </div>

      <div className="user__info-team divider">
        <Text as="h6" className="info__title">
          The project team
        </Text>
        {executorsWithoutLastName.map((executor, index) => (
          <AvatarLabel
            key={`project-info-${index}`}
            author={executor}
            showText={true}
            text={`#${executor?.subRole || ROLES.EXECUTOR}`}
          />
        ))}
      </div>

      {renderActionButton()}
    </div>
  );
};

export default UserProjectInfo;
