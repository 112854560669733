import React, { useEffect, useRef } from 'react';
import './cart-summary-project.scss';

import { Text, Icon, Button, Tooltip } from '@ui-kit';
import { useOnScreen } from '@shared';
import Lock from '@ui-kit/icons/lock-gray400.svg';
import Question from '@ui-kit/icons/help-circle-gray400.svg';

const CartSummaryProject = (props) => {
  const { 
    tax = 0,
    title = "",
    payLabel = '',
    description = "",
    currencySymbol = '$',
    isConfirmData = true,
    payProcess = () => {},
    setIsSummaryVisible = () => {},
    totalPrice,
    text
  } = props;
  const refResume = useRef();
  const isVisibleSummary = useOnScreen(refResume);  
  const symbol = currencySymbol || '$';
  const cartPrice = Math.ceil((totalPrice - tax) * 100) / 100;

  useEffect(() => {
    setIsSummaryVisible(isVisibleSummary);
    // eslint-disable-next-line
  }, [isVisibleSummary]);

  const onClickPay = () => {
    payProcess()
  };

  const vatTooltipContent = (
    <>
      <Text as="h6" className="tooltipe__title">
        The amount of tax depends on the selected <br />
        country when placing an order.
      </Text>
    </>
  );

  return (
    <div className="cart__summary cart__summary-project">
      <div className="cart-summary__item">
        <Text as="h3" className="cart-summary__title">
          {title}
        </Text>
      </div>

      <div className="cart-summary__item p-t-b divider">
        <Text as="h5" className="cart-summary__subtitle">
         {description}
        </Text>

        <Text as="h5" className="cart-summary__subtitle-desc">
          {`${cartPrice} ${symbol}`}
        </Text>
      </div>


      <div className="cart-summary__item p-t-b divider">
        <Text as="h5" className="cart-summary__subtitle">
          VAT/GST/Sale taxes
        </Text>
        <Text as="h5" className="cart-summary__subtitle-desc">
          {tax ? `${tax} ${symbol}` : text}
        </Text>
      </div>

      <div ref={refResume} className="cart-summary__item p-t-b cart-summary__item-total">
        <div className="d-flex ai-center cart-summary__total_descr">
          <Text as="h5" className="cart-summary__total">
            Total
          </Text>

          <Tooltip
            content={vatTooltipContent}
            position="top"
            direction="revers"
            ancorPosition="anchor-right-bottom"
          >
            <Icon
              src={Question}
              className="question__icon"
              alt="question__icon"
            />
          </Tooltip>
        </div>
        <Text as="h5" className="cart-summary__total-desc">
          {totalPrice || ''}{` ${symbol}`}
        </Text>
      </div>

      <Button
        size="lg"
        className="pay__link"
        onClick={onClickPay}
        isDisabled={!isConfirmData}
      >
        {payLabel}
      </Button>

      <div className="d-flex ai-center jc-center cart-security">
        <Icon src={Lock} className="cart-summary-lock" />
        <Text as="h5">Secure payment with the Stripe</Text>
      </div>
    </div>
  );
};

export default CartSummaryProject;
