import React, {useState} from "react";
import './modal.scss';

import { Icon } from '@ui-kit';
import CloseIcon from '@ui-kit/icons/x-gray500.svg'

const Modal = ({ children, content, classNames = [] }) => {
    const [ isOpen, setOpen ] = useState(false)

    const classesBkg = ['modal__background'];
    const classes = ['modal__content', ...classNames];

    if (isOpen) {
        classesBkg.push('open');
    }

    const openModal = () => {
        setOpen(true)      
    };

    const closeModal = () => {
        setOpen(false)
    }

    const onBackgroundClick = (e) => {
        if (e.target.id === 'modal__background') {
            closeModal();
        }
    };


    return (
        <div className="modal__wrapper">
            <div onClick={openModal} className="modal__children">
                {children}
            </div>
            <div 
                id="modal__background"
                className={classesBkg.join(' ')}
                onMouseDown={onBackgroundClick}
            >
                <div className="modal__container">
                    <div className="modal__close">
                        <Icon src={CloseIcon} onClick={closeModal} />
                    </div>
                    <div className={classes.join(' ')}>
                        {content}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;