import {
  SET_USER_LOADING,
  SET_AUTH,
  SET_USER,
  DEL_CART,
  SET_NETWORK_STATUS,
  SET_SERVER_ERROR,
  SET_SEARCH,
  SET_SHOWROMS_PRODUCT,
  SET_RESULT_SEARCH,
  SET_CART,
  SET_CART_LIMIT,
  SET_PRODUCT_INVOICE,
  DEL_CART_PRODUCT,
  DEL_SHOWROOMS_PRODUCT,
  SELECT_ALL_ITEMS_CART,
  SELECT_ONE_ITEMS_CART,
  SET_CURRENT_USER,
  SET_USER_AVATAR,
  SET_PUBLIC_STRIPE_TOKEN,
  SET_CART_SHOWROOMS_PRODUCTS,
  SET_CART_LIMIT_POPUP,
  SET_LOCALE,
  SET_PAYMENT_STATUS,
  SET_GET_FREE_MODAL_SHOW,
  SET_ACTIVATED_EMAIL_MODAL,
  SET_PURCHASES_LIMIT,
  SET_LOGIN_MODAL_SHOW,
  SET_SIGNUP_MODAL_SHOW,
  SET_RECOVERY_MODAL_SHOW,
  SET_PAYMENT_DATA,
  SET_USER_BILLS_DATA,
  SET_BILLS_AMOUNT,
  SET_PAID_PROJECTS,
  SET_PAID_PRODUCTS,
  SET_BILLS_LOADING,
  SET_PURCHASES_LOADING,
  SET_SEARCH_PAGE,
  SET_DEVICE_TYPE,
  SET_MOBILE_SCREEN,
  SET_VISIBLE_SUBFOOTER,
  SET_CART_LOADING,
} from './user.constants';

const initialState = {
  cart: [],
  user: {},
  search: '',
  isAuth: false,
  locale: navigator.language,
  isOnline: false,
  currentUser: {},
  searchResult: '',
  serverErrors: [],
  notifications: [],
  paidProjects: [],
  paidProducts: [],
  purchasesLimit: '',
  currentUserAvatar: {},
  showroomsProducts: [],
  isUserPreloader: false,
  isCartLimitPopupShow: false,
  isCartLimit: false,
  publicStripeToken: null,
  invoice: { cartPrice: 0, priceWithTax: null },
  paymentStatus: '',
  isActivatedEmailModalShow: false,
  isModalLoginShow: false,
  isModalSignupShow: false,
  isModalRecoveryShow: false,
  isModalGetFreeShow: false,
  currentFreeProduct: {},
  paymentData: {},
  bills: [],
  billsPaginationAmount: 1,
  billsLoading: false,
  purchasesLoading: false,
  searchPage: '',
  deviceType: {},
  isMobileScreen: false,
  isVisibleSubfooter: false,
  isCartLoading: true,
};

const userReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case SET_USER_LOADING: {
      return {
        ...state,
        isUserPreloader: payload,
      };
    }

    case SET_AUTH: {
      return {
        ...state,
        isAuth: payload,
      };
    }

    case SET_USER: {
      let data = {
        isAuth: false,
        user: {},
      };

      if (payload.id) {
        data = { isAuth: true, user: payload };
      }

      return {
        ...state,
        ...data,
      };
    }

    case SET_NETWORK_STATUS: {
      return {
        ...state,
        isOnline: payload,
      };
    }

    case SET_CART: {
      return {
        ...state,
        cart: [...payload],
      };
    }

    case SELECT_ALL_ITEMS_CART: {
      return {
        ...state,
        cart: state.cart.map((item) => {
          item.isChecked = payload;

          return item;
        }),
      };
    }

    case SELECT_ONE_ITEMS_CART: {
      return {
        ...state,
        cart: state.cart.map((item) => {
          if (item.id === payload.id) {
            item.isChecked = payload.isChecked;
          }

          return item;
        }),
      };
    }

    case DEL_CART_PRODUCT: {
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== payload),
      };
    }

    case DEL_SHOWROOMS_PRODUCT: {
      return {
        ...state,
        showroomsProducts: state.showroomsProducts.map((item) => {
          if (item.id === payload) {
            item.inCart = false;
          }
          return item;
        }),
      };
    }

    case DEL_CART: {
      return {
        ...state,
        cart: [],
        showroomsProducts: state.showroomsProducts.map((item) => {
          item.inCart = false;
          return item;
        }),
      };
    }

    case SET_SHOWROMS_PRODUCT: {
      return {
        ...state,
        showroomsProducts: payload,
      };
    }

    case SET_CART_SHOWROOMS_PRODUCTS: {
      return {
        ...state,
        showroomsProducts: state.showroomsProducts.map((item) => {
          const isPresent = payload.cart.find(
            (product) => product.id === item.id
          );

          if (isPresent) {
            item.inCart = payload.flag;
          }

          return item;
        }),
      };
    }

    case SET_SEARCH: {
      return {
        ...state,
        search: payload,
      };
    }

    case SET_RESULT_SEARCH: {
      return {
        ...state,
        searchResult: payload,
      };
    }

    case SET_SERVER_ERROR: {
      return {
        ...state,
        serverErrors: payload,
      };
    }

    case SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: payload,
      };
    }

    case SET_USER_AVATAR: {
      return {
        ...state,
        currentUserAvatar: payload,
      };
    }

    case SET_PUBLIC_STRIPE_TOKEN: {
      return {
        ...state,
        publicStripeToken: payload,
      };
    }

    case SET_PRODUCT_INVOICE: {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          ...payload,
          tax: payload?.tax / 100 || 0,
          priceWithTax: payload.priceWithTax / 100,
        },
      };
    }

    case SET_PAYMENT_STATUS: {
      return {
        ...state,
        paymentStatus: payload,
      };
    }

    case SET_CART_LIMIT_POPUP: {
      return {
        ...state,
        isCartLimitPopupShow: payload,
      };
    }

    case SET_CART_LIMIT: {
      return {
        ...state,
        isCartLimit: payload,
      };
    }

    case SET_LOCALE: {
      return {
        ...state,
        locale: payload,
      };
    }

    case SET_ACTIVATED_EMAIL_MODAL: {
      return {
        ...state,
        isActivatedEmailModalShow: payload,
      };
    }

    case SET_PURCHASES_LIMIT: {
      return {
        ...state,
        purchasesLimit: payload,
      };
    }

    case SET_LOGIN_MODAL_SHOW: {
      return {
        ...state,
        isModalLoginShow: payload,
      };
    }

    case SET_SIGNUP_MODAL_SHOW: {
      return {
        ...state,
        isModalSignupShow: payload,
      };
    }

    case SET_RECOVERY_MODAL_SHOW: {
      return {
        ...state,
        isModalRecoveryShow: payload,
      };
    }

    case SET_GET_FREE_MODAL_SHOW: {
      return {
        ...state,
        isModalGetFreeShow: payload.show,
        currentFreeProduct: payload.product,
      }
    }

    case SET_PAYMENT_DATA: {
      let data = {}
      if (Object.keys(payload).length) {
        data = {...state.paymentData, ...payload};
      }
      return {
        ...state,
        paymentData: data,
      };
    }

    case SET_USER_BILLS_DATA: {
      return {
        ...state,
        bills: payload,
      };
    }

    case SET_BILLS_AMOUNT: {
      return {
        ...state,
        billsPaginationAmount: payload,
      };
    }

    case SET_PAID_PROJECTS: {
      return {
        ...state,
        paidProjects: payload,
      };
    }

    case SET_PAID_PRODUCTS: {
      return {
        ...state,
        paidProducts: payload,
      };
    }

    case SET_BILLS_LOADING: {
      return {
        ...state,
        billsLoading: payload,
      };
    }

    case SET_CART_LOADING: {
      return {
        ...state,
        isCartLoading: payload,
      };
    }

    case SET_PURCHASES_LOADING: {
      return {
        ...state,
        purchasesLoading: payload,
      };
    }

    case SET_SEARCH_PAGE: {
      return {
        ...state,
        searchPage: payload,
      };
    }

    case SET_DEVICE_TYPE: {
      return {
        ...state, 
        deviceType: payload,
      };
    }

    case SET_MOBILE_SCREEN: {
      return {
        ...state, 
        isMobileScreen: payload,
      };
    }

    case SET_VISIBLE_SUBFOOTER: {
      return {
        ...state,
        isVisibleSubfooter: payload,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
