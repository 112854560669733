import React, { useRef } from 'react';
import './typing.scss';

import { Icon, Button, Tooltip } from '@ui-kit';
import { TypingForm } from '@features';
import { UploadImage } from '@widgets';

import AttachIcon from '@ui-kit/icons/paperclip-gray400.svg';
//import Smile from '@ui-kit/icons/smile-gray400.svg';
import Send from '@ui-kit/icons/send-white.svg';
import Hotkeys from 'react-hot-keys';

const Typing = ({
  onSubmit = ()=>{},
  formValue = '',
  setFormValue = ()=>{},
  showError = ()=>{},
  uploadFiles,
  isMobile,
  isDisabled,
  onTypingHeightChange = ()=>{},
}) => {
  const refTyping = useRef();
  const refCounterElement = useRef(); 
  const classes = ['chat-typing__container'];

  if (isDisabled) {
    classes.push('disabled');
  }

  const onFormChange = (text) => {
    onTypingHeightChange();

    setFormValue(text);
  };

  const filePreviewWasUploaded = (files) => {
    uploadFiles(files);
  };

  const renderUploadContent = () => {
    const component =  (
      <UploadImage
      multiple={true}
      multipleLimit={5}
      maxSize={1572864} //1,5MB
      accept={'image/jpeg, image/png'}
      wasUploaded={filePreviewWasUploaded}
      errorCallback={showError}
        className = {`${isDisabled && 'disabled'}`}
      >
        <div className="button__image-add">
          <Icon src={AttachIcon} className="chat__typing-attach" />
        </div>
      </UploadImage>
   )


    if (isDisabled) {
      return (
        <Tooltip
          position="top"
          content="You will be able to send files after the first chat message"
          key={`typing-tooltip`}
        >
         {component}
        </Tooltip>
      )
    }

    return component;
  }

  function onKeyDown(hotKey, event) {
    if (isDisabled) return;

    if (isMobile) {
      document.execCommand('insertLineBreak');
      event.preventDefault();
      return;
    }

    onSubmit();
  }

  function hotKeyFilter(event) {
    const target = event.target || event.srcElement;
    const tagName = target.tagName;

    return !(tagName === 'INPUT' || tagName === 'SELECT' || tagName === 'TEXTAREA');
  }

  return (
    <div className={classes.join(' ')} ref={refTyping}>
      {renderUploadContent()}
      <Hotkeys 
         keyName="enter" 
         filter={hotKeyFilter}
         onKeyDown={onKeyDown}
      >
        <TypingForm
          counterElement={refCounterElement}
          value={formValue}
          onChange={onFormChange}
          onTypingHeightChange={onTypingHeightChange}
          className="chat__typing-form"
          placeholder="Write a message"
          isDisabled={isDisabled}
        />
      </Hotkeys>
      {/* <Icon src={Smile} className="chat__typing-smile" /> */}
      <Button className="chat__typing-send-btn" onClick={onSubmit}>
        <Icon src={Send} />
      </Button>
      <div className="chat__counter-container">
        <div ref={refCounterElement} className="chat__counter"></div>
      </div>
    </div>
  );
};

export default Typing;
