import React from 'react';
import './icon.scss';

/*
 * <Icon styles={{paddingRight: '15px'}} src={Temp} alt="React Logo"/>
 */
const Icon = ({
  src,
  text,
  alt,
  className = '',
  styles: propsStyles,
  ...options
}) => {
  const styles = {
    ...propsStyles,
  };

  const classes = ['icon-ava__img', ...className.split(' ')];

  const renderSrc = () => {
    const spanText = 'DF';

    if (text) {
      return <span className="icon-ava">{spanText}</span>;
    }

    if (src) {
      return (
        <img
          {...options}
          style={styles}
          className={classes.join(' ')}
          src={src}
          alt={alt}
        />
      );
    }

    return <span className="icon-ava">{spanText}</span>;
  };

  return renderSrc();
};

export default Icon;
