import React, {useState} from 'react';
import './header-active-lang.scss';
import { DropdownMenu } from '@ui-kit';
import { MENU_LANG_LIST } from './header-active.consts';

const HeaderActiveLang = ({ title = 'En', selectLanguage, className = '' }) => {
    const [showMenu, setShowMenu] = useState(false);
    const classes = ["lang__menu", ...className.split(' ')];

    const onClickInternal = (value) => {
        setShowMenu(value);
    };

    const onVisibleChange = (event) => {
        const newLocale = event?.target?.id;

        if (newLocale) {
            selectLanguage(newLocale);
        };

        setShowMenu(false);
    };
    
    return (
        <div className={classes.join(" ")} > 
            <DropdownMenu
                menu_list={MENU_LANG_LIST}
                onVisibleChange={onVisibleChange}
                classes="menu-lang"
                isVisible={showMenu}
            >
                <div onClick={() => onClickInternal(!showMenu)} className={showMenu ? "open" : "close"} >{title}</div>
            </DropdownMenu>
        </div>
    );
};

export default HeaderActiveLang;