import React from "react";

const StepDuration = ({status = false, extreme}) => {
    if (extreme) {
        return;
    }

    const classes = ["step-duration"];

    if (status) {
        classes.push('passed');
    }

    return (
        <div className="step-duration_container">
            <div className={classes.join(" ")}></div>
        </div>
    )
}

export default StepDuration;