import React from 'react';
import { Provider } from 'react-redux';
import store from '@app/store';

const Store = (component) => {
    if (process.env.REACT_APP_MODE === "local") {
      window.store = store;
    }

    const reactElement = React.createElement(component, null, '')
    
    return (
        <Provider store={store}>
            {reactElement}
        </Provider>
    )
}

export default Store;