import React from "react";
import { Input } from '@ui-kit';

const SearchFormDefault = ({
    onSubmit = () => {}, 
    placeholder = '', 
    className = '', 
    ...options
}) => {
    const classes = ['header__form-container', ...className.split(' ')];

    return (
        <form onSubmit={onSubmit} className={classes.join(' ')}>
            <Input 
                type="search"
                placeholder={placeholder} 
                name="search"
                {...options}
            />
      </form>
    )
}

export default SearchFormDefault;