import React from 'react';
import { Text } from '@ui-kit';


const MinutelyRate = () => {
  return (
    <div className="rate__wrapper-content d-flex ai-center mb-34">
      <div className='rate__description'>
        <Text as="h3" className="rates__title-tab">
         Illustration edit
        </Text>
        <Text as="span" className="rates__description-tab align">
        It’s now quick and easy to create unique illustrations! Choose one of&nbsp;ready-made solutions 
        from our gallery and BriefStock talented artists will edit it for you. 
        </Text>
        <Text as="span" className="rates__description-tab">
        *Time indicated in the example is estimated. Fill the brief and our specialist will calculate 
        the time needed to complete your project.
        </Text>
      </div>
    </div>
  );
};

export default MinutelyRate;
