export class FollowingDataDTO {
  MAX_STEP_LENGTH = 8;
  state;

  constructor(option) {
    this.state = [];
  }

  generateData() {
    for (let step = 0; step < this.MAX_STEP_LENGTH; step++) {
      const date = new Date();
      const currentStep = step + 1;

      if (currentStep >= 5 && currentStep < 7) {
        date.setDate(date.getDate() + 1);
      }

      if (currentStep >= 7) {
        date.setDate(date.getDate() + 2);
      }

      this.state.push({ step: currentStep, date: date.getTime() });
    }

    return this;
  }

  getState() {
    return this.state;
  }

  setState(state) {
    this.state = state;

    return this;
  }

  setData(stage, startDate, endDate) {
    const startD = new Date(startDate);
    const endD = new Date(endDate);
    let nextStep = endD.getDate();

    this.state[stage].date = startD.getTime();

    if (stage === 3) {
      for (let step = 1; step < this.MAX_STEP_LENGTH-stage; step++) {
        if (step >=3 ) { // steps 7,8
          endD.setDate(nextStep + 2);
        }
        
        this.state[stage+step].date = endD.getTime();
      }
    }

    if (stage === 5) {
      endD.setDate(nextStep);
      this.state[stage+1].date = endD.getTime();
      this.state[stage+2].date = endD.getTime();


    }
    
    return this;
  }
}
