import React from 'react';
import './radiobuttons.scss';
import RadioItem from './radioitem';
import { Tooltip } from '@ui-kit';

const RadioButtons = ({ radioItems = [], checkItem, name = "radio" }) => {
  const nameItem = `radio-${Date.now()}`;

  const renderRadio = (radioItem) => {
    if (radioItem.isDisabled && radioItem.tooltipText) {
      return (
        <Tooltip
          position="right"
          ancorPosition="anchor-left-middle"
          content={radioItem.tooltipText}
        >
          <RadioItem
            key={`radio-${radioItem.value}`}
            name={nameItem}
            {...radioItem}
            checkItem={checkItem}
          />
        </Tooltip>
      );
    }

    return (
      <RadioItem
        key={`radio-${radioItem.value}`}
        name={nameItem}
        {...radioItem}
        checkItem={checkItem}
      />
    );
  };

  return (
    <div className="d-flex fd-column" name={name}>
      {radioItems.map((radioItem) => renderRadio(radioItem))}
    </div>
  );
};

export default RadioButtons;
