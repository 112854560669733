import projectsService from '../api';
import {
  SET_LOADING,
  SET_PROJECTS,
  SET_NEW_PROJECT,
  SET_TO_EMPTY_PROJECTS,
  SET_SELECTED_PROJECTS,
  REMOVE_PROJECT_FROM_LIST,
  SET_NEW_PROJECT_CLEAR_EMPTY,
  SET_PROJECT_PROPERTIE_IN_LIST,
  SET_PROJECT_PAGINATION_AMOUNT,
  SET_CURRENT_PROJECT_TO_PROJECTS_LIST,
  SET_PROJECTS_SEARCH,
} from './projects.constants';
import {
  projectInstance,
  getUserProjectTC,
  getAdminProjectTC,
  setCurrentProjectAC,
  setAlertAC,
  Snackbar,
} from '@entities';

export const getProjectsTC = (params, projectAssetId) => {
  return async (dispatch) => {
    try {
      dispatch(isProjectsLoadingAC(true));
      const data = await projectsService.getProjects(params);

      if (!data) {
        return;
      }

      let { rows } = data.data; // rows, count
      const clearDataProject = rows.map((project) =>
        projectInstance.create(project, 'adminProject')
      );

      if (projectAssetId) {
        clearDataProject.forEach((project) => {
          project.isSelected = project.id === +projectAssetId ? true : false;
        });
        dispatch(getAdminProjectTC(projectAssetId));
      };

      dispatch(setProjectsAC(clearDataProject));
    } catch (e) {
      console.log('[Error]', e);
    } finally {
      dispatch(isProjectsLoadingAC(false));
    }
  };
};

export const getArchiveProjectsTC = (params, projectAssetId) => {
  return async (dispatch) => {
    try {
      dispatch(isProjectsLoadingAC(true));
      const data = await projectsService.getArchiveProjects(params);
      if (!data) {
        return;
      }

      let { rows, count } = data.data; // rows, count

      if (!count) {
        count = 1;
      };

      const clearDataProject = rows.map((project) =>
        projectInstance.create(project, 'adminProject')
      );

      if (projectAssetId) {
        clearDataProject.forEach((project) => {
          project.isSelected = project.id === +projectAssetId ? true : false;
        });
        dispatch(getAdminProjectTC(projectAssetId));
      };

      dispatch(setProjectsPaginationAmountAC(count));
      dispatch(setProjectsAC(clearDataProject));
    } catch (e) {
      console.log('[Error]', e);
    } finally {
      dispatch(isProjectsLoadingAC(false));
    }
  };
};

export const getUserProjectsTC = (params, projectAssetId) => {
  return async (dispatch) => {
    try {
      dispatch(isProjectsLoadingAC(true));
      const data = await projectsService.getUserProjects(params);

      if (!data) {
        return;
      }

      let { rows, count } = data.data;

      if (!count) {
        count = 1;
      }

      if (!rows.length && !params?.search) {
        const emptyProject = projectInstance.create({ followingId: 0 });

        return dispatch(setCurrentProjectAC(emptyProject));
      }

      const clearDataProject = rows.map((project) =>
        projectInstance.create(project, 'userProject')
      );

      if (projectAssetId) {
        clearDataProject.forEach((project) => {
          project.isSelected = project.id === +projectAssetId ? true : false;
        });
        dispatch(getUserProjectTC(projectAssetId));
      }

      dispatch(setProjectsAC([...clearDataProject]));
      dispatch(setProjectsPaginationAmountAC(count));
    } catch (e) {
      console.log('[Error]', e);
    } finally {
      dispatch(isProjectsLoadingAC(false));
    }
  };
};

export const addProjectToArchiveTC = (projectId) => {
  return async (dispatch) => {
    try {
      const data = await projectsService.projectArchive(projectId, 1); // 1-> isDeleted: 1

      if (!data) {
        return;
      }

      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            <h5 className="snack__header">Project was removed successfully!</h5>
          </div>
        ),
      });

      dispatch(setAlertAC(snackbar));

      dispatch(removeProjectFromListAC(projectId));
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const removeProjectFromArchiveTC = (projectId) => {
  return async (dispatch) => {
    try {
      const data = await projectsService.projectArchive(projectId, 0); // 1-> isDeleted: 1

      if (!data) {
        return;
      }

      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            <h5 className="snack__header">
              Project was restored successfully!
            </h5>
          </div>
        ),
      });

      dispatch(setAlertAC(snackbar));

      dispatch(removeProjectFromListAC(projectId));
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const uploadChatFilesTC = (file) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        const { currentProject } = getState().projectData;

        formData.set('chatFile', file);
        formData.set('dir', currentProject.dir);

        const response = await projectsService.uploadChatFiles(formData);
        const { data } = response;

        return resolve(data.name);
      } catch (e) {
        const message = e?.response?.data?.message || 'Error';

        const snackbar = new Snackbar({
          status: 500,
          message: (
            <div className="">
              <h5 className="snack__header">{message}</h5>
            </div>
          ),
        });

        dispatch(setAlertAC(snackbar));
        console.log('[Error]', e);
      }
    });
  };
};

export const removeChatFilesTC = (name) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { currentProject } = getState().projectData;

        await projectsService.removeChatFiles({
          dir: currentProject.dir,
          name,
        });

        return resolve(name);
      } catch (e) {
        console.log('[Error]', e);
      }
    });
  };
};

export const deleteProjectTC = (projectId) => {
  return async (dispatch) => {
    try {
      const data = await projectsService.deleteArchiveProject(projectId);

      if (!data) {
        return;
      }

      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            <h5 className="snack__header">Project was removed successfully!</h5>
          </div>
        ),
      });

      dispatch(setAlertAC(snackbar));

      dispatch(removeProjectFromListAC(projectId));
    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

//ACTIONS
export const isProjectsLoadingAC = (data) => {
  return {
    type: SET_LOADING,
    payload: data,
  };
};

export const setProjectsAC = (data) => {
  return {
    type: SET_PROJECTS,
    payload: data,
  };
};

// for entire nulling
export const emptyProjectsAC = (data) => {
  return {
    type: SET_TO_EMPTY_PROJECTS,
    payload: data,
  };
};

export const setNewProjectsAC = (data) => {
  return {
    type: SET_NEW_PROJECT,
    payload: data,
  };
};

export const setNewProjectsClearEmptyAC = (data) => {
  return {
    type: SET_NEW_PROJECT_CLEAR_EMPTY,
    payload: data,
  };
};

//for updating after admin changing
export const setProjectToProjectsListAC = (data) => {
  return {
    type: SET_CURRENT_PROJECT_TO_PROJECTS_LIST,
    payload: data,
  };
};

export const setSelectedProjectAC = (id) => {
  return {
    type: SET_SELECTED_PROJECTS,
    payload: id,
  };
};

export const setProjectsPaginationAmountAC = (amount) => {
  return {
    type: SET_PROJECT_PAGINATION_AMOUNT,
    payload: amount,
  };
};

export const removeProjectFromListAC = (projectId) => {
  return {
    type: REMOVE_PROJECT_FROM_LIST,
    payload: projectId,
  };
};

export const setProjectPropertieInListAC = (projectId) => {
  return {
    type: SET_PROJECT_PROPERTIE_IN_LIST,
    payload: projectId,
  };
};

export const setProjectsSearchValueAC = (value) => {
  return {
    type: SET_PROJECTS_SEARCH,
    payload: value,
  }
}
