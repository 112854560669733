import React from "react";
import './licensesBanner.scss';

import {Text, Icon} from '@ui-kit';
import Check from '@ui-kit/icons/check-accent500-md.svg';
import {HEADER_BANNER_OPTIONS} from './header-banner.consts';

const LicensesHeaderBunner = () =>{
    return (
       <div className="licenses__container-banner d-flex ai-center">
        <div className="licenses__banner">
            <div className="banner_bg pr">
                <div className="banner__perspective"></div>
                <div className="banner__clip"></div>
                <Icon src={'/images/banner_licenses.png'} className="banner__img" alt="banner"/>
            </div>
        </div>
        <div className="licenses__title">
            <Text as='h1'>
                <Text as="span" className="highlight">Breakthrough</Text>
                 in the world of illustrations
            </Text>
            <div className="licenses__sup-title">{
                HEADER_BANNER_OPTIONS.map((item, index) => (
                    <div key={`licenses__sup-title-${index}`} className="d-flex ai-center mt-20">
                        <Icon src={Check} className="banner__ico" alt="banner"/>
                        <Text as="h4">{item.title}</Text>
                    </div>
                ))
            }
            </div>
        </div>
       </div>
    )
}

export default LicensesHeaderBunner;