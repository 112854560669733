import { ROLES } from '@entities/user';

export class User {
  id;
  ava;
  role;
  email;
  subRole;
  country;
  address;
  postCode;
  basketId;
  lastName;
  firstName;
  isActivated;
  organization;
  isMessagesSend;
  isConfirmAgreement;

  constructor(options) {
    this.id = options?.id;
    this.email = options?.email;
    this.country = options?.country;
    this.address = options?.address;
    this.postCode = options?.postCode;
    this.lastName = options?.lastName;
    this.basketId = options?.basketId;
    this.firstName = options?.firstName;
    this.subRole = options?.subRole || '';
    this.role = options?.role || ROLES.USER;
    this.isActivated = +options?.isActivated;
    this.organization = options?.organization;
    this.isMessagesSend = options?.isMessagesSend;
    this.isConfirmAgreement = options?.isConfirmAgreement;
    this.createdAt = new Date(options?.createdAt).getTime();
    this.createdAt = new Date(options?.createdAt).getTime();
    // this.notifications = options?.notifications;
    this.updatedProjects = options?.updatedProjects || [];

    if (!options?.ava || options?.ava === 'null') {
        this.ava = null;
    } else {
        this.ava = options?.ava;
    }
  }
}
