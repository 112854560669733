export const PRODUCTS_TAGS = [
    {title: 'Cartoon', value: 'cartoon'},
    {title: 'Isometric', value: 'isometric'},
    {title: 'Realistic', value: 'realistic'},
    {title: 'Background', value: 'background'},
    {title: 'Icon', value: 'icon'},
    {title: 'Character', value: 'character'},
    {title: 'Book', value: 'book'},
    {title: 'Fluorescent', value: 'fluorescent'},
    {title: 'Girl', value: 'girl'},
    {title: 'Boy', value: 'boy'},
    {title: 'Animal', value: 'animal'},
    {title: 'Science', value: 'science'},
    {title: 'Social', value: 'social'},
    {title: 'Beach', value: 'beach'},
    {title: 'Magic', value: 'magic'},
    {title: 'Set', value: 'set'},
]