import { Validation } from '@shared/lib/utils';

const onFreeProductValid = (formData) => {
    let errorsState = {};

    const validationProjectName = new Validation({
        field: formData.projectName,
        title: 'Project name',
    })
        .isRequired()
        .minLenght(3)
        .maxLenght(100)
        .containLetters()
        .getErrors();

    if (validationProjectName.length) {
        errorsState = {
            ...errorsState,
            projectName: validationProjectName[0],
        };
    }

    return errorsState;
};

export default onFreeProductValid;
