import { ROLES } from '@entities/user';

export class Executor {
  id;
  ava;
  role;
  subRole;
  country;
  lastName;
  firstName;
  organization;
  isMessagesSend;

  constructor(options) {
    this.id = options.id;
    this.country = options.country;
    this.lastName = options.lastName;
    this.firstName = options.firstName;
    this.subRole = options?.subRole || '';
    this.role = options?.role || ROLES.EXECUTOR;
    this.organization = options.organization;
    this.isMessagesSend = options.isMessagesSend;
    this.createdAt = new Date(options.createdAt).getTime();
    
    if (!options.ava || options.ava === 'null') {
        this.ava = null;
    } else {
        this.ava = options.ava;
    }
  }
}
