import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import './modalEditTimeSpent.scss';

import { Text, Button, Input } from '@ui-kit';
import {
  getWorkingHoursSum,
  setProjectPropertieAC,
  setProjectCostAC,
} from '@entities';
import TrashIcon from '@ui-kit/icons/trash-gray500.svg';
import ReturnIcon from '@ui-kit/icons/rotate-ccw-gray500.svg';

const ModalEditTimeSpent = ({ 
    stage, 
    timeSpent = [], 
    paidHours, 
    getEditTimeSpentPopUp = ()=>{}, 
    editTimeCallback = ()=>{}
}) => {
    const dispatch = useDispatch();
    const refItem = useRef();
    const workingHoursSum = useSelector(getWorkingHoursSum);
    const { hours, minutes } = timeSpent[stage] || {};
    const [errors, setErrors] = useState({});
    const [stageValue, setStageValue] = useState({[stage]: {hours: hours || 0, minutes: minutes || 0}});
    const [isDeleteSpent, setDeleteSpent] = useState(false);
    const [isEdit, setEdit] = useState(false);

    const onBackgroundClick = (e) => {
        if (e.target.id === 'edit-time-background') {
            getEditTimeSpentPopUp({ state: false });
        }
    };

    const deleteTime = () => {
        const currentHours = timeSpent[stage]?.hours || 0;
        const currentMinutes = timeSpent[stage]?.minutes || 0;
        const currentWorkingHoursSum = workingHoursSum;
        let currentSumHours = Number(+currentWorkingHoursSum.split('.')[0]) || 0;
        let currentSumMinutes = Number(+currentWorkingHoursSum.split('.')[1]) || 0;

        currentSumHours = currentSumHours - +currentHours;
        currentSumMinutes = currentSumMinutes - +currentMinutes;
        const numberSumHours = currentSumHours + (1 / (60 / currentSumMinutes));

        if(numberSumHours < +paidHours) {
            return setErrors({ hours: ' ', minutes: `The amount of hours is less than the paid time: ${paidHours}` });
        };

        refItem.current.classList.add('delete');
        setDeleteSpent(true);
        setEdit(true)
    };

    const returnTime = () => {
        refItem.current.classList.remove('delete');
        setDeleteSpent(false);
    };

    const saveTime = () => {
        if (isDeleteSpent) {
            setLocalStage('hours', 0);
            setLocalStage('minutes', 0);
        }

        const currentHours = timeSpent[stage]?.hours || 0;
        const currentMinutes = timeSpent[stage]?.minutes || 0;
        const currentWorkingHoursSum = workingHoursSum;
        let currentSumHours = Number(+currentWorkingHoursSum.split('.')[0]) || 0;
        let currentSumMinutes = Number(+currentWorkingHoursSum.split('.')[1]) || 0;

        const newValues = stageValue[stage];

        if (newValues && (newValues.hours || newValues.minutes)) {
            if (newValues?.hours?.length >= 3) {
                return setErrors({ hours: 'Maximum field value is 100' });
            };

            if (+newValues?.minutes > 60) {
                return setErrors({ minutes: 'Maximum field value is 60' });
            };

            const step = 15;
            if (!+newValues?.hours && +newValues?.minutes < step) {
                return setErrors({ minutes: `The minimum value is ${step}` });
            };

            if (!!(+newValues?.minutes % step)) {
                return setErrors({
                minutes: `Step between values must be ${step} (15, 30, 45)`,
                });
            };
        };

        currentSumHours = currentSumHours - +currentHours + +newValues.hours;
        currentSumMinutes = currentSumMinutes - +currentMinutes + +newValues.minutes;

        if (currentSumMinutes >= 60) {
            const moduleHours = Math.round(currentSumMinutes / 60);
            const moduleMinutes = currentSumMinutes % 60;

            currentSumHours += moduleHours;
            currentSumMinutes = moduleMinutes;
        };

        if (currentSumMinutes < 0) {
            const moduleHours = currentSumHours - 1;
            const moduleMinutes = 60 + currentSumMinutes;

            currentSumHours = moduleHours;
            currentSumMinutes = moduleMinutes;
        };

        const numberMinutes = 1 / (60 / currentSumMinutes);
        const numberSumHours = currentSumHours + numberMinutes;

        if(numberSumHours < +paidHours) {
            return setErrors({ hours: ' ', minutes: `The amount of hours is less than the paid time: ${paidHours}h` });
        };

        dispatch(
            setProjectPropertieAC({
            key: 'workingHoursSum',
            value: `${currentSumHours}.${currentSumMinutes}`,
            })
        );

        timeSpent[stage].hours = +newValues.hours;
        timeSpent[stage].minutes = +newValues.minutes;
        setStageValue({});
        dispatch(setProjectCostAC());
        getEditTimeSpentPopUp({ state: false });
        editTimeCallback();
    };

    const closePopup = () => {
        getEditTimeSpentPopUp({ state: false });
    };

    const setLocalStage = (key, value) => {
        const newValue = {[stage]: stageValue[stage]};        
        newValue[stage][key]= value;
        newValue[stage].date = +new Date();

        setStageValue(newValue);
        setEdit(true);
    };

    const renderDeleteButton = () => {
        if (isDeleteSpent) {
            return (
                <Button 
                    iconLeft={ReturnIcon} 
                    theme="tertiary-gray" 
                    size="sm" 
                    className="delete"
                    onClick={returnTime}
                />
            )
        }

        return (
            <Button 
                iconLeft={TrashIcon} 
                theme="tertiary-gray" 
                size="sm" 
                className="delete"
                onClick={deleteTime}
            />
        )
    }

    const checkDate = (date) => {
        if (!date) return;
    
        return (
          <Text as="h6" className="time_milestone">
            ({format(date, 'dd LLLL', { locale: enUS })})
          </Text>
        );
    };

    const renderHourseMinutes = (item) => {
        const itemHours = item[stage]?.hours || hours;
        const itemMinutes = item[stage]?.minutes || minutes;
        const itemDate = item[stage]?.date || timeSpent[stage]?.date;

        if (!itemHours && !itemMinutes) return;

        if (!itemHours && itemMinutes) {
            return (
            <div className="content_milestone">
                <Text as="h6" className="hours_milestone">
                {itemMinutes} minutes
                </Text>
                {checkDate(itemDate)}
            </div>
            );
        }

        if (itemHours && !itemMinutes) {
            return (
            <div className="content_milestone">
                <Text as="h6" className="hours_milestone">
                {itemHours} hour
                </Text>
                {checkDate(itemDate)}
            </div>
            );
        }

        return (
            <div className="content_milestone">
                <Text as="h6" className="hours_milestone">
                    {itemHours} hour {itemMinutes} minutes
                </Text>
                {checkDate(itemDate)}
            </div>
        );
    };

    return (
        <div
            id="edit-time-background"
            className="background-default"
            onMouseDown={onBackgroundClick}
        >
            <div className="edit-time-popup__wrapper">
                <Text as="h4" className="edit-time-popup__title">
                    Edit time spent
                </Text>
                <Text as="h6" className="edit-time-popup__subtitle">
                    You can change the number of hours spent or 
                    delete it. The already paid time is not shown.
                </Text>
                <div className="edit-time-popup__content space">
                    <div 
                        ref={refItem}
                        className="edit-time__item"
                    >
                        <div className="edit-time__info">
                            <div className="edit-time__time">
                                {renderHourseMinutes(stageValue)}
                            </div>
                            <div className="edit-time__actions">
                                {renderDeleteButton()}
                            </div>
                        </div>

                        <div className="edit-time__hours-minutes">
                            <Input
                                placeholder="Hours"
                                name="hours"
                                type="number"
                                label="Hours"
                                isDisabled={isDeleteSpent}
                                defaultValue={hours}
                                maxLengthCustom={99}
                                onChange={(e) => setLocalStage('hours', e.target.value)}
                                className={errors.hours && 'form-item__error'}
                            />
                            <Input
                                placeholder="Minutes"
                                name="minutes"
                                type="number"
                                label="Minutes"
                                isDisabled={isDeleteSpent}
                                defaultValue={minutes}
                                maxLengthCustom={59}
                                onChange={(e) => setLocalStage('minutes', e.target.value)}
                                className={errors.minutes && 'form-item__error'}
                            />
                        </div>

                        {errors.hours && (
                        <Text as="span" className="error">
                            {errors.hours}
                        </Text>
                        )}

                        {errors.minutes && (
                        <Text as="span" className="error">
                            {errors.minutes}
                        </Text>
                        )}
                    </div>
                </div>
                <div className="edit-time__buttons">
                    <Button
                        className="edit-time__btn"
                        theme="secondary-gray"
                        size="lg"
                        onClick={closePopup}
                    >
                        Cancel
                    </Button>
                    <Button 
                        className="edit-time__btn publish" 
                        size="lg"
                        onClick={saveTime}
                        isDisabled={!isEdit}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ModalEditTimeSpent;