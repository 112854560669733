import React from 'react';

import { Icon, Dropdown, Text } from '@ui-kit';
import { countriesWithIcons } from './countriesIFasade';

const CountriesDropDown = ({
  current = '',
  errors = {},
  showArrow = true,
  isDisabled = false,
  onChooseCountry,
  iconRight,
  isInPopup = false,
}) => {

  const className = [];

  if (errors?.country) {
    className.push('form-item__error');
  }

  const renderCountryPreview = (event, index) => {
    const name = event?.name;
    const code = event?.code;
    const icon = event?.icon || null;
    let classes = ['input-search__item'];

    if (!code || !name) return;

    if (code === current) {
      classes.push('active');
    }

    const ico = icon ? (
      <Icon src={icon} alt="flag" />
    ) : (
      <Icon text={code} alt="flag" />
    );

    return (
      <div
        className={classes.join(' ')}
        onClick={() => onChooseCountry(event)}
      >
        {ico}
        <Text as='span'>{name}</Text>
      </div>
    );
  };

  return (
    <div className="profile__input">
      <Dropdown
        search={true}
        value={current}
        iconLeft={true}
        iconRight={iconRight}
        id="input-search"
        className={className}
        isDisabled={isDisabled}
        data={countriesWithIcons}
        placeholder="Select country"
        onChooseCountry={onChooseCountry}
        renderOption={renderCountryPreview}
        autoComplete="false"
        isInPopup={isInPopup}
      />
      {errors?.country && (
        <Text as="span" className="error">
          {errors?.country}
        </Text>
      )}
    </div>
  );
};

export default CountriesDropDown;
