import React from 'react';
import './text.scss';

/*
 * styles={{padding: '14px'}}
 */
const Text = ({ 
  as: Component, 
  children, 
  styles: propsStyles, 
  ...options 
}) => {
  const styles = {
    ...propsStyles,
  };

  return (
    <Component style={styles} {...options}>
      {children}
    </Component>
  );
};

export default Text;

/* <Text as='h4' styles={{paddingLeft: '200px'}}>Icon + Label</Text> */
