import React from 'react';
import './license_line.scss';
import { Text, Icon, Tooltip, Modal } from '@ui-kit';
import { Rights } from '@features';
import Qestion from '@ui-kit/icons/help-circle-gray400.svg';


const LicenseLine = ({ position = 'bottom', ancorPosition = 'anchor-right-top' }) => {
  return (
    <div className="license_line mb-20 d-flex ai-center">
      <Text as="h5" className="info__header mr-8">
        License:
      </Text>
      <Text as="h5" className="info__content mr-8">
        Extended commercial
      </Text>
      <Tooltip
        content={<Rights />}
        position={position}
        ancorPosition={ancorPosition}
      >
        <Icon src={Qestion} className="question__icon" alt="question__icon" />
      </Tooltip>

      <Modal
        content={<Rights />}
      >
        <Icon src={Qestion} className="question__icon" alt="question__icon" />
      </Modal>
    </div>
  );
};

export default LicenseLine;
