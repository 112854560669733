import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './profile-purchases.scss';

import { Text, Button } from '@ui-kit';
import { PaginationInput } from '@widgets';
import { 
  getPaidProductsTC,
  getProjectsPaginationAmount,
  getPaginationAmount,
  setPurchasesLimitAC,
  getPurchasesLimit,
  getPaidProjectsTC,
} from '@entities';
import { ROLES } from '@entities/user';
import PurchasedProducts from './purchasedProducts';
import CompletedProjects from './completedProjects';

const ProfilePurchases = ({
  products = [],
  projects = [],
  current = 'products', 
  user = {}
}) => {
  const dispatch = useDispatch();
  const projectPaginationAmount = useSelector(getProjectsPaginationAmount);
  const productPaginationAmount = useSelector(getPaginationAmount);
  const purchasesLimit = useSelector(getPurchasesLimit);

  const [currentPage, setCurrentPage] = useState();
  const [currentLimit, setCurrentLimit] = useState();
  const [currentIllustration, setCurrentIllustration] = useState(current);
  // eslint-disable-next-line
  const [currentField, setCurrentField] = useState();
  // eslint-disable-next-line
  const [currentOrder, setCurrentOrder] = useState();
  const [allPage, setAllPage] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();

  const isUser = user.role === ROLES.USER;
  const isActiveProduct = currentIllustration === 'products' ? 'active' : '';
  const isActiveProject = currentIllustration === 'projects' ? 'active' : '';
  const isEmpty = currentIllustration === 'products' ? !+products.length : !+projects.length;

  const limitList = [
    {name: '20', value:20}, 
    {name: '50', value:50}, 
    {name:'100', value:100}
  ];
  
  useEffect(() => {
    if (currentIllustration === 'products') {
      return setAllPage(productPaginationAmount);
    }
    setAllPage(projectPaginationAmount);
    // eslint-disable-next-line
  }, [projectPaginationAmount, productPaginationAmount]);

  useEffect(() => {
    setCurrentLimit(purchasesLimit);
  }, [purchasesLimit]);

  useEffect(() => {
    const params = getSearchParams();
    const searchByArray = [];

    params.forEach(({ key, value }) => {
      switch (key) {
        case 'page': {
          if (!+value || +value < 1) {
            searchParams.set('page', 1);
            setSearchParams(searchParams, {replace: true});
            return setCurrentPage(1);
          }

          setCurrentPage(value);
          break;
        }

        case 'limit': {
          if (!+value || +value < 1) {
            searchParams.set('limit', limitList[0].value);
            setSearchParams(searchParams, {replace: true});
            if (currentIllustration === 'products') {
              setAllPage(Math.ceil(+productPaginationAmount));
            } else {
              setAllPage(Math.ceil(+projectPaginationAmount));
            }
            return setCurrentLimit(limitList[0].value);
          }

          let allPages = 1;
          if (currentIllustration === 'products') {
            allPages = Math.ceil(+productPaginationAmount / +value);
          } else {
            allPages = Math.ceil(+projectPaginationAmount / +value);
          }
          setAllPage(allPages);
          setPurchasesLimitAC(+value);
          setCurrentLimit(+value);

          if (allPages < currentPage) {
            setCurrentPage(allPages);
            searchParams.set('page', allPages);
            setSearchParams(searchParams, {replace: true});
          }

          break;
        }

        case 'fieldType': {
           // role, email, createdAt
          if (!value || value === '') {
            searchParams.set('fieldType', 'createdAt');
            setSearchParams(searchParams, {replace: true});
            setCurrentField('createdAt');
            return;
          }

          setCurrentField(value);
          //setPurchasedFieldAC(value);
          break;
        }

        case 'orderType': {
           // DESC, ASC
          if (!value || value === '') {
            searchParams.set('orderType', 'DESC');
            setSearchParams(searchParams, {replace: true});
            setCurrentOrder('DESC');
            return;
          }

          setCurrentOrder(value);
          //setPurchasedOrderAC(value);
          break;
        }

        case 'searchBy': {
          searchByArray.push(value);
          break;
        }

        case 'illustrationType': {
          if (!value || value === '') {
            searchParams.set('illustrationType', 'products');
            setSearchParams(searchParams, {replace: true});
            setCurrentIllustration('products');
            return;
          }

          setCurrentIllustration(value);
          break;
        }

        default:
          break;
      }
    });

    if (searchByArray.length) {
      //const actualSearchValue = searchByArray.join(',');
      //dispatch(setSearchAC(actualSearchValue));
      //dispatch(setResultSearchAC(actualSearchValue));
    }

    return () => {
      //dispatch(setSearchAC(''));
      //dispatch(setResultSearchAC(''));
    };

    // eslint-disable-next-line
  }, [searchParams, projectPaginationAmount, productPaginationAmount]);

  const onChooseTypeIllustration = (options) => {
    searchParams.set('illustrationType', options);
    setSearchParams(searchParams, {replace: true});
    setCurrentIllustration(options);
  };

  const getMyPurchases = () => {
    const search = window.location.search;
    const result = search.slice(search.indexOf('?') + 1).split('&').reduce((params, hash) => {
      let [key, val] = hash.split('=')
      return Object.assign(params, {[key]: decodeURIComponent(val)})
    }, {})
    const options = {
      id: user.id,
      limit: result.limit,
      page: result.page,
      orderType: 'DESC',
    };

    if(currentIllustration === 'products') {
      dispatch(getPaidProductsTC(options));
    } else {
      dispatch(getPaidProjectsTC(options));
    }    
  };

  const onLimitChange = (value) => {
    let allPages = 1;
    if (currentIllustration === 'products') {
      allPages = Math.ceil(+productPaginationAmount / +value);
    } else {
      allPages = Math.ceil(+projectPaginationAmount / +value);
    }
    setAllPage(allPages);
    setCurrentLimit(+value);

    setPurchasesLimitAC(+value);
    searchParams.set('limit', +value)

    if (allPages < currentPage) {
      setCurrentPage(allPages);
      searchParams.set('page', allPages);
    }
    setSearchParams(searchParams, {replace: true});
    getMyPurchases();
  };
  
  const getSearchParams = () => {
    const params = [];

    searchParams.forEach((value, key) => {
      params.push({ key, value });
    });

    return params;
  };

  const onChangeValue = (e) => {
    const value = e.target.value;
    setCurrentPage(value);
  };

  const onPageChange = (event) => {
    event.preventDefault();

    const page = +event.target.page.value;

    if (!page) {
      return;
    }

    if (page > allPage) {
      setCurrentPage(allPage);
      searchParams.set('page', allPage);
      setSearchParams(searchParams, {replace: true});
      return;
    }

    setCurrentPage(page);
    searchParams.set('page', page);
    setSearchParams(searchParams, {replace: true});

    getMyPurchases();
  };

  const onClickPrev = () => {
    document.querySelector('#root').scrollIntoView();

    const params = getSearchParams();
    let numberPage = 0;

    params.forEach((param) => {
      if (param.key === 'page') {
        numberPage = param.value;
      }
    });

    if (!numberPage || numberPage <= 1) {
      return;
    }

    const page = +numberPage - 1;
    searchParams.set('page', page);
    setSearchParams(searchParams, {replace: true});
    setCurrentPage(page);
    getMyPurchases();
  };

  const onClickNext = () => {
    document.querySelector('#root').scrollIntoView();

    const params = getSearchParams();
    let numberPage = 0;

    params.forEach((param) => {
      if (param.key === 'page') {
        numberPage = +param.value;
      }
    });

    if (!numberPage || numberPage >= allPage) {
      return;
    }

    const page = +numberPage + 1;
    searchParams.set('page', page);

    setSearchParams(searchParams, {replace: true});
    setCurrentPage(page);
    getMyPurchases();
  };

  return (
    <>
      <div className="purchases__header">
        <Text as="h1" className="title mb-4">
          Purchased illustrations
        </Text>
      </div>
      <div className="purchases__content">
        <div className="admin__table">
          <div className="admin__table-title">
            <div className="purchases__switcher">
              <Button 
                size="sm" 
                theme="horizontal-gray"
                onClick={() => onChooseTypeIllustration('products')}
                className={isActiveProduct}
              >
                Products
              </Button>
              <Button 
                size="sm" 
                theme="horizontal-gray"
                onClick={() => onChooseTypeIllustration('projects')}
                className={isActiveProject}
              >
                Projects
              </Button>
            </div>
          </div>
          {currentIllustration === 'products' ? 
            <PurchasedProducts products={products} isUser={isUser} /> : 
            <CompletedProjects projects={projects} isUser={isUser} />
          }
          <PaginationInput 
            allPage={allPage}
            onSubmit={onPageChange}
            onClickPrev={onClickPrev}
            onClickNext={onClickNext}
            currentPage={currentPage}
            onChangeValue={onChangeValue}
            isDisabled={isEmpty}
            limitList={limitList}
            currentLimit={currentLimit}
            onLimitChange={onLimitChange}
            listTop={true}
          />
        </div>
      </div>
    </>
  );
};

export default ProfilePurchases;
