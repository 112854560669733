import { ApiService } from '@shared/api';

class ProjectsService extends ApiService {
  baseUrl;
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.baseUrl = '/projects';
    this.baseArchiveUrl = `${this.baseUrl}/projects_archive`;
    this.baseUserUrl = 'user/projects';
  }

  async getProjects(params) {
    return await this.instance.get(
      `${this.baseUrl}?${this.generateQueryString(params)}`
    );
  }

  async getUserProjects(params) {
    return await this.instance.get(
      `${this.baseUserUrl}?${this.generateQueryString(params)}`
    );
  }

  async getAmount(from) {
    if (!Object.keys(from).length) {
      return await this.instance.get(`${this.baseUrl}/amount`);
    }

    return await this.instance.get(
      `${this.baseUrl}/amount?${this.generateQueryString(from)}`
    );
  }

  async getArchiveProjects(params) {
    return await this.instance.get(
      `${this.baseArchiveUrl}?${this.generateQueryString(params)}`
    );
  }

  async projectArchive(projectId, flag) {
    return await this.instance.patch(
      `${this.baseUrl}/to_archive/${projectId}`,
      { isDeleted: flag }
    );
  }

  async deleteArchiveProject(projectId, params) {
    return await this.instance.delete(
      `${this.baseArchiveUrl}/${projectId}?${this.generateQueryString(params)}`
    );
  }

  async uploadChatFiles(formData) {
    this.setFormDataInterceptors();

    return await this.instance.post(
      `${this.baseUrl}/upload_chat_files`,
      formData
    );
  }

  async removeChatFiles(params) {
    return await this.instance.delete(
      `${this.baseUrl}/upload_chat_files?${this.generateQueryString(params)}`
    );
  }
}

const projectsService = new ProjectsService();

export default projectsService;
