import React, {useState, useEffect} from 'react';
import './adminPanelWrapper.scss';

import { HorizontalTabsAdmin, Footer } from '@widgets';
import { Text, Icon } from '@ui-kit';


const AdminPanelWrapper = ({children}) => {
  const MIN_WIDTH = 670;
  const [isSmallWidth, setIsSmallWidth] = useState(window.innerWidth <= MIN_WIDTH);

  useEffect(() => {
    const handleResize = (event) => {
      const eventWidth = event.target.innerWidth;
      if (eventWidth <= MIN_WIDTH) {
        setIsSmallWidth(true);
      } else {
        setIsSmallWidth(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderAdminPanel = () => {
    if(isSmallWidth) {
      return (
        <>
          <div className="mobile-user-panel">
            <div className="mobile-user-panel__header">
              <div className="title">Mobile version</div>
              <Text as="h4" className="sub-title">
                Our techies are currently working on the 
                mobile version of the chat and it will 
                soon be ready to use. Meanwhile, 
                we are eager to cooperate with you 
                on tablet. Turn your phone horizontally 
                to switch to the tablet version.
              </Text>
            </div>
            <div className="mobile-user-panel__banner">
              <Icon src="/images/banner-mobile-user-panel.jpg" />
            </div>
          </div>
          <Footer />
        </>
      )
    }

    return (
      <>
        <HorizontalTabsAdmin />
        <div className="d-flex jc-sb">
            {children}
        </div>
      </>
    )
  };

  return (
    <div className="admin d-flex">
      <div className='w-100'>
        {renderAdminPanel()}
      </div>
    </div>
  );
};

export default AdminPanelWrapper;
