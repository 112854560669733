import React, { useRef } from "react";
import "./techWorksPopup.scss";
import { Portal } from '@shared';
import { Text, Icon } from "@ui-kit";
import CloseIcon from "@ui-kit/icons/x-gray700.svg";

const TechWorks = () => {
    const techWorksEl = useRef(true);

    const closeTechWorks = () => {
        localStorage.setItem('accept_tech_works', true);
        techWorksEl.current.classList.add("hidden");
    }

    return (
        <Portal className="portal-tech-works">
           <div className="tech-works__container" ref={techWorksEl}>
                <div className="tech-works__content">
                    <Text as="h5" className="tech-works__title">The site is currently under maintenance.
                        During this time, you may experience intermittent service disruptions. We apologize 
                        for any inconvenience this may cause and appreciate your understanding. If you have 
                        any questions or need assistance, please reach out to us at{' '}
                        <a 
                            href="mailto:info@myteam.art&subject=website under maintenance" 
                            theme="text" 
                            className="tech-works_link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >info@myteam.art</a>.
                    </Text>
                </div>
                <div className="tech-works__actions">
                    <div className="tech-works-close" onClick={closeTechWorks}>
                        <Icon src={CloseIcon} />
                    </div>
                </div>
           </div>
        </Portal>
    )
}

export default TechWorks;