import usersService from '../api';

import { 
  SET_USERS, 
  SET_EXECUTORS, 
  SET_USERS_PAGINATION_AMOUNT,
  SET_SELECTED_USER,
  SET_USERS_LIMIT
} from './users.constants';
import { User, Executor, getUserTC, setCurrentUserAC } from '@entities';

/*
 * get csrf token to cookeies
 * get tokens
 **/
export const getUsersTC = (params, userAssetId) => {
  return async (dispatch) => {
    try {
      const data = await usersService.getUsers(params);

      if (!data) {
        return;
      }

      let { rows, count } = data.data; // rows, count
      const usersDTO = rows.map(item=> new User(item));

      if (!count) {
        count = 1;
      }

      dispatch(setUsersAC(usersDTO));
      dispatch(setUsersPaginationAmountAC(count))

      if (!userAssetId && usersDTO?.length) {
        dispatch(getUserTC(usersDTO[0].id))
      }

    } catch (e) {
      console.log('[Error]', e);
    }
  };
};


export const updateLocalUserTC = (user) => {
  return async (dispatch) => {
    try {

      const userAssetId = new URLSearchParams(window.location.search).get("user_asset_id");

      dispatch(getUsersTC(window.location.search, userAssetId));

      if (user?.id === +userAssetId) dispatch(setCurrentUserAC(user));

    } catch (e) {
      console.log('[Error]', e);
    }
  };
};

export const getExecutorsTC = () => {
  return async (dispatch) => {
    try {
      const { data } = await usersService.getExecutors();

      if (!data) {
        return;
      }

      let executorKeys = {};

      data.forEach((executor) => {
        const modelExecutor = new Executor(executor);

        executorKeys[modelExecutor.id] = modelExecutor;
      });

      dispatch(setExecutorsAC(executorKeys));
    } catch (error) {}
  };
};

export const setUsersAC = (payload) => {
  return {
    type: SET_USERS,
    payload,
  };
};

export const setExecutorsAC = (payload) => {
  return {
    type: SET_EXECUTORS,
    payload,
  };
};

export const setUsersPaginationAmountAC = (amount) => {
  return {
    type: SET_USERS_PAGINATION_AMOUNT,
    payload: amount,
  };
};

export const setSelectedUserAC = (id) => {
  return {
    type: SET_SELECTED_USER,
    payload: id,
  };
};

export const setAdminSectionLimitAC = (amount) => {
  return {
    type: SET_USERS_LIMIT,
    payload: amount,
  };
};
