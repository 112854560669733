import React from "react";
import "./files_types.scss";
import { Text } from '@ui-kit';
import { map } from 'underscore';

const FilesTypes = ({illustrationFormats = []}) => {

    return (
        <div className="info__filestype mb-20 d-flex">
        <Text as="h5" className="info__header mr-8">File type:</Text>
        {map(illustrationFormats, (item, index) => (
          <Text as="h5" key={`file-type-${index}`} className="info__content mr-8">
            {item}{index !== illustrationFormats.length -1? ",": ""}
          </Text>
        ))}
      </div>
    )
};

export default FilesTypes;