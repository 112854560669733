import React from "react";
import "./compatible_with.scss";
import { Text, Icon } from '@ui-kit';
import { map } from 'underscore';
import Ai from '@ui-kit/icons/integrations/ai.svg';
import Sketch from '@ui-kit/icons/integrations/sketch.svg';
import Figma from '@ui-kit/icons/integrations/figma.svg';
import Ps from '@ui-kit/icons/integrations/ps.svg';
import Question from '@ui-kit/icons/help-circle-gray400.svg';

const CompatibleWith = ({compatibility_draw = []}) => {

  const renderIcon = (flag) =>{
    switch (flag) {
      case 'illustrator':
        return Ai;

      case 'photoshop':
        return Ps;

      case 'figma':
        return Figma;

      case 'sketch':
        return Sketch;

      default: 
        return Question;
    }
  }
 
    return (
        <div className="info__compatible mb-20 d-flex ai-center">
        <Text as="h5" className="info__header mr-8">Compatible with:</Text>
        {map(compatibility_draw, (item, index) => (
          <div key={`compatible-${index}`} className="info__content mr-8 d-flex ai-center">
            <Icon src={renderIcon(item)} />
          </div>
        ))}
      </div>
    )
}

export default CompatibleWith;