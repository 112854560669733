import React from "react";
import "./downloadInfoElement.scss";

import { Icon, Text } from '@ui-kit';
import DownloadIcon from "@ui-kit/icons/download-gray500.svg";
import CloseIcon from "@ui-kit/icons/x-gray700.svg";

const DownloadInfoElement = ({uploadArchive=()=>{}, deleteArchive=()=>{}}) => {
    return (
        <div className='info__archive divider'>
        <div className='info-archive__wrapper'>
          <div className='upload__content' onClick={uploadArchive}>
            <Icon src={DownloadIcon} className="info-archive__upload"/>
            <Text as="h6" className="info-archive__text"> Download illustration </Text>
          </div>
          <Icon src={CloseIcon} className="info-archive__close" onClick={deleteArchive}/>
        </div>
      </div>
    );
}

export default DownloadInfoElement;