import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './cart-scroll-resume.scss';

import { payInvoiceTC, getPaymentData } from '@entities';
import { Text, Button } from '@ui-kit';

const CartScrollResume = ({
  text = "",
  price = 0,
  payLabel = '',
  currencySymbol = '$',
  isConfirmData = true,
  payProcess = () => {},
  step
}) => {
  const dispatch = useDispatch();
  const paymentData = useSelector(getPaymentData);
  const isCart = step === 'cart';
  const symbol = currencySymbol || '$';

  const onClickPay = () => {
    if(isCart) {
      return payProcess();
    };

    dispatch(payInvoiceTC(paymentData));
  };

  return (
    <div className="cart__scroll-resume">
      <div className="cart-resume__item p-t-b cart-resume__item-total">
        <div className='d-flex ai-center '>
          <Text as="h5" className="cart__scroll-resume__total">
            Total
          </Text>
          <Text as="h5" className="cart__scroll-resume_vat">
          {text}
          </Text>
        </div>
        <Text as="h5" className="cart__scroll-resume__total-desc">
          {price || ''}{` ${symbol}`}
        </Text>
      </div>

      <Button 
        className="pay__link" 
        onClick={onClickPay}
        isDisabled={!isConfirmData}
      >
        {payLabel}
      </Button>
    </div>
  );
};

export default CartScrollResume;
