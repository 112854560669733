import React, { useState } from 'react';
import './modalSetStage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Button, Input } from '@ui-kit';
import {
  setCurrentStepsHoursAC,
  getWorkingHoursSum,
  setProjectPropertieAC,
  setProjectCostAC,
  setisProjectChangeAC,
} from '@entities';

const ModalSetStage = ({ stage, getStagePopUp, stageCallback }) => {
  const dispatch = useDispatch();
  const workingHoursSum = useSelector(getWorkingHoursSum);
  const [stageValue, setStageValue] = useState({
    stage: { hours: 0, minutes: 123, date: Date().now },
  });

  const { hours, minutes } = stageValue[stage] || {};
  const [errors, setErrors] = useState({});

  const STAGES = {
    1: { title: 'Brief' },
    3: { title: 'Sketching' },
    5: { title: 'Illustrating' },
    7: { title: 'Completed' },
  };

  const onBackgroundClick = (e) => {
    if (e.target.id === 'set-stage-background') {
      getStagePopUp({ state: false });
    }
  };

  const renderTitle = () => {
    if (!stage) {
      return STAGES[1].title;
    }

    const title = STAGES[stage]?.title || 'Unknown title';

    return title;
  };

  const saveState = () => {
    const currentWorkingHoursSum = workingHoursSum;
    const currentValues = stageValue[stage];

    if (currentValues && (currentValues.hours || currentValues.minutes)) {
      if (currentValues?.hours?.length >= 3) {
        return setErrors({ hours: 'Maximum field value is 100' });
      }

      if (+currentValues?.minutes > 60) {
        return setErrors({ minutes: 'Maximum field value is 60' });
      }

      const step = 15;
      if (!+currentValues?.hours && +currentValues?.minutes < step) {
        return setErrors({ minutes: `The minimum value is ${step}` });
      }

      if (!!(+currentValues?.minutes % step)) {
        return setErrors({
          minutes: `Step between values must be ${step} (15, 30, 45)`,
        });
      }

      let currentHours = Number(+currentWorkingHoursSum.split('.')[0]) || 0;
      let currentMinutes = Number(+currentWorkingHoursSum.split('.')[1]) || 0;

      if (currentValues.hours) {
        currentHours += +currentValues.hours;
      }

      if (currentValues.minutes) {
        currentMinutes += +currentValues.minutes;
      }

      if (currentMinutes >= 60) {
        const moduleHours = Math.round(currentMinutes / 60);
        const moduleMinutes = currentMinutes % 60;

        currentHours += moduleHours;
        currentMinutes = moduleMinutes;
      }

      dispatch(
        setProjectPropertieAC({
          key: 'workingHoursSum',
          value: `${currentHours}.${currentMinutes}`,
        })
      );

      dispatch(setisProjectChangeAC());
      dispatch(setProjectCostAC());
    }

    dispatch(setCurrentStepsHoursAC(stageValue));
    getStagePopUp({ state: false, stage });
    setStageValue({});
    stageCallback();
  };

  const closePopup = () => {
    getStagePopUp({ state: false });
  };

  const setLocalStage = (key, value) => {
    // {1 : {hours: 2, minutes: 2}}
    setStageValue({
      [stage]: { ...stageValue[stage], [key]: value, date: Date.now() },
    });
  };

  return (
    <div
      id="set-stage-background"
      className="background-default"
      onMouseDown={onBackgroundClick}
    >
      <div className="stage-popup__wrapper">
        <Text as="h4" className="stage-popup__title">
          {renderTitle()}
        </Text>
        <Text as="h6" className="stage-popup__subtitle">
          Please record the time spent working.
        </Text>
        <div className="stage-hours-minutes">
          <Input
            placeholder="Hours"
            name="hours"
            type="number"
            label="Hours"
            defaultValue={hours}
            maxLengthCustom={99}
            onChange={(e) => setLocalStage('hours', e.target.value)}
            className={errors.hours && 'form-item__error'}
          />
          <Input
            placeholder="Minutes"
            name="minutes"
            type="number"
            label="Minutes"
            defaultValue={minutes}
            maxLengthCustom={59}
            onChange={(e) => setLocalStage('minutes', e.target.value)}
            className={errors.minutes && 'form-item__error'}
          />
        </div>

        {errors.hours && (
          <Text as="span" className="error">
            {errors.hours}
          </Text>
        )}

        {errors.minutes && (
          <Text as="span" className="error">
            {errors.minutes}
          </Text>
        )}
        <Button className="stage__btn publish" onClick={saveState}>
          Ok
        </Button>
        <Button
          className="stage__btn"
          theme="secondary-gray"
          onClick={closePopup}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ModalSetStage;
