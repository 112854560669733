import React from 'react';
import { useDispatch } from 'react-redux';
import './hide-product.scss';

import { updateProductsSettingsTC, deleteCheckedProductsAC } from '@entities';
import { Text, Button, IconFeatured } from '@ui-kit';
import WarningIcon from '@ui-kit/icons/alert-triangle-warning600.svg';

const ModalHideProduct = ({productsId = [], setModalHideProduct, onUpdateData = ()=>{}}) => {
    const dispatch = useDispatch();

    const onBackgroundClick = (e) => {
        if (e.target.id === 'hide-product-background') {
          return setModalHideProduct({status:false});
        }
      };

    const onClickHide = async() => {
        console.log(productsId)
        if(!productsId.length) return;
        
        await dispatch(updateProductsSettingsTC({ ids: productsId, update: {isPublic: 0}}));
        setModalHideProduct({status:false});
        dispatch(deleteCheckedProductsAC(productsId));
        return onUpdateData();
    }

    const onCancel = () => {
        return setModalHideProduct({status:false});
      }

    return (
        <div
            id="hide-product-background"
            className="background-default hide-product-background"
            onMouseDown={onBackgroundClick}
        >
        <div className="hide-product-popup__wrapper">
            <IconFeatured src={WarningIcon} theme="warning" size="lg" />

            <div className="hide-product-popup__content">
                <Text as="h4" className="hide-product-popup__title">
                    Hide product
                </Text>
                <Text as="h6" className="hide-product-popup__subtitle">
                    Are you sure you want to hide selected product? This product <br/>
                    will no longer be active and generate income. It will <br />
                    be moved to the "Hidden" section.
                </Text>                
                <div className="hide-product-popu__actions">
                    <Button
                        theme="secondary-gray"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onClickHide}
                    >
                        Hide
                    </Button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ModalHideProduct;