import { v4 as uuidv4 } from 'uuid';
import BriefAva from '@ui-kit/icons/brief.svg';

export class MessageImgDTO {
  id;
  type;
  text;
  toId;
  edited;
  status;
  roomId;
  userId;
  fromId;
  isDeleted;
  createdAt;
  utcOffset;
  creatorId;
  projectId;
  recipientId;
  attachments;
  isMessagesSend;

  constructor(options) {
    let date = new Date();

    this.toId = options?.toId || null;
    this.type = options?.type || 'img';
    this.isRead = options?.isRead || 0;
    this.edited = options?.edited || 0;
    this.userId = options?.userId || null;
    this.roomId = options?.roomId || null;
    this.fromId = options?.fromId || null;
    this.isDeleted = options?.isDeleted || 0;
    this.projectId = options?.projectId || null;
    this.recipientId = options?.recipientId || {};
    this.status = options?.status ? +options.status : 0;
    this.createdAt = options?.createdAt || date.toISOString();
    this.utcOffset = -1 * (new Date(this.createdAt)).getTimezoneOffset();
    this.attachments = options?.attachments || [];
    this.isMessagesSend = options?.isMessagesSend
      ? +options.isMessagesSend
      : true;
    this.creatorId = options?.creatorId || {
      id: uuidv4(),
      ava: BriefAva,
    };
  }
}
