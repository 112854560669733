import React from 'react';
import './not-found.scss';

import { Text, IconFeatured } from '@ui-kit';
import SearchIcon from '@ui-kit/icons/search-accent600.svg';

const NotFoundMessage = ({value}) => {
  return (
        <div className="not-found-message">
            <IconFeatured src={SearchIcon} theme="primary" size="lg" />

            <div className="not-found__content">
                <Text as="h4" className="not-found__title">
                    Project not found
                </Text>
                <Text as="h6" className="not-found__subtitle">
                    Your search "{value}" did not match any projects. 
                    Please try again or try changing the search term.
                </Text>
            </div>
        </div>
    )
};

export default NotFoundMessage;