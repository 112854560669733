import React from 'react';
import './recovery.scss';

import { Text, Icon, Button } from '@ui-kit';
import Arrow from '@ui-kit/icons/arrow-gray700.svg';
import Mail from '@ui-kit/icons/mail-accent600.svg';

const PassRecovered = ({ setPasswordIsRecovered, email, goToLogin }) => {
    return (
        <div className="form__container recovery__form-container">
            <div className="form__header">
                <div className="form__header-logo-container">
                    <div className="form__header-icon">
                        <Icon src={Mail} />
                    </div>
                </div>
                <Text as="h2" className="form__header-title">
                    Check you email
                </Text>
                <Text as="h5" className="login__header-subtitle">
                    We sent a password reset link to
                </Text>
                <Text as="h5" className="login__header-subtitle bold">
                    {email}
                </Text> 
            </div>
            <div className="form__content">
                <div className="form__content-line">
                    <Text as="span" className="login__header-subtitle">
                        Didn’t receive the email?
                    </Text>
                    <Button 
                        onClick={() => setPasswordIsRecovered(false)} 
                        size="md" 
                        theme="link-color"
                    >
                        Click to resend
                    </Button>
                </div>
                <div>
                    <Button 
                        iconLeft={Arrow} 
                        onClick={goToLogin} 
                        size="md" 
                        theme="link-gray"
                    >
                        Back to log in
                    </Button>
                </div>
            </div>
        </div>
    )
};

export default PassRecovered;
