export class Rate {
  value = 0;
  type;

  constructor(value, type = 'hourlyRate') {

    if (type === 'hourlyRate'){
      this.value = value || 23;
      this.type = type;
    }

    if (type === 'hourlyWithoutLicense'){
      this.value = value || 80;
      this.type = type;
    }
  }
}
