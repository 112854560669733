import React, { useState } from "react";
import './fileUpload.scss';

import { UploadImage } from '@widgets';
import { FileLoaderWithProgress } from '@widgets';
import {
    Text,
    IconFeatured
  } from '@ui-kit';
import CloudIcon from '@ui-kit/icons/download-cloud-gray600.svg';

const FileUpload = ({ text = '', setErrors = ()=>{}, onFileUpload = ()=>{} }) => {
    const [currentUpload, setCurrentUpload] = useState(null);

    const filePreviewWasUploaded = (files) => {
        setCurrentUpload(files[ 0 ]);
        onFileUpload(files);
    };

    return (
        <div className="uploader__wrapper">
            <div className="dropdown-uploader__container">
                <UploadImage 
                    wasUploaded={filePreviewWasUploaded}
                    maxSize={1572864} //1,5MB
                    accept={'image/jpeg, image/png'}
                    errorCallback={(error) => setErrors(error)}
                >
                <div className="uploader-wrapper d-flex">
                    <IconFeatured src={CloudIcon} theme="gray"/>
                    <div className="dropdown-uploader__text">
                        <Text as="h6" className="marked">
                        Click to upload
                        </Text>
                        {/*<Text as="h6">or drag and drop</Text>*/}
                    </div>
                    <Text as="p" className="uploader-text">SVG, PNG, JPG or GIF (max. 800x400px)</Text>
                </div>
                </UploadImage>
            </div>

            {currentUpload && (
              <FileLoaderWithProgress  key={text} file={currentUpload} />
            )}
        </div>
    );
};

export default FileUpload;