import { Validation } from '@shared/lib/utils';

const productFormValid = (formData) => {
  let errorsState = {};

  const validationTitle = new Validation({
    field: formData.title,
    title: 'Name',
  })
    .isRequired()
    .minLenght(8)
    .maxLenght(50)
    .getErrors();

  const validationDescription = new Validation({
    field: formData.html_description,
    title: 'Description',
  })
    .isRequired()
    .minLenght(20)
    .maxLenght(255)
    .getErrors();

  const validationKeyWords = new Validation({
      field: formData.key_words,
      title: 'Key words',
    })
      .isRequired()
      .isKeyWords()
      .getErrors();

  if (validationTitle.length) {
    errorsState = {
      ...errorsState,
      title: validationTitle[0],
    };
  }

  if (validationDescription.length) {
    errorsState = {
      ...errorsState,
      html_description: validationDescription[0],
    };
  }

  if (validationKeyWords.length) {
    errorsState = {
      ...errorsState,
      key_words: validationKeyWords[0],
    };
  }

  return errorsState;
};

export default productFormValid;
