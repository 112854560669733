import { Validation } from '@shared/lib/utils';

const onResetFormValid = (formData) => {
  let errorsState = {};

  const validationCurrent = new Validation({
    field: formData.currentPassword,
    title: 'Current password',
  })
    .isRequired()
    .getErrors();

  const validationNew = new Validation({
    field: formData.newPassword,
    title: 'New password',
  })
    .isRequired()
    .minLenght(8)
    .maxLenght(32)
    .containLettersAndNumbers()
    .getErrors();

  const validationConfirm = new Validation({
    field: formData.confirmPassword,
    title: 'Confirm password',
  })
    .isRequired()
    .getErrors();
  
  if (formData.newPassword !== formData.confirmPassword) {
    errorsState = {
      ...errorsState,
      confirmPassword: 'Passwords do not match',
    };
  }

  if (formData.newPassword === formData.currentPassword) {
    errorsState = {
      ...errorsState,
      newPassword: 'New password must be different from the old password',
    };
  }

  if (validationCurrent.length) {
    errorsState = {
      ...errorsState,
      currentPassword: validationCurrent[0],
    };
  }

  if (validationNew.length) {
    errorsState = {
      ...errorsState,
      newPassword: validationNew[0],
    };
  }

  if (validationConfirm.length) {
    errorsState = {
      ...errorsState,
      confirmPassword: validationConfirm[0],
    };
  }

  return errorsState;
};

export default onResetFormValid;
