import React from "react";
import Slider from 'react-slick';
import { CarouselArrow, Icon, Badge, Text, TooltipWithoutAnchor } from '@ui-kit';
import Clock from '@ui-kit/icons/clock-gray500.svg'

const SliderMinutely= () => {
  const sliderSettings = {
    infinite       : true,
    arrows         : true,
    fade           : true,
    dots           : true,
    prevArrow: <CarouselArrow direction="prev" />,
    nextArrow: <CarouselArrow direction="next" />
  };

    return (
        <div className="rates-slider__wrapper custom__dots pr">
            <Slider {...sliderSettings}>   
              <div className="rates__slider-online">
                <div className="rates__wrapper rates__wrapper-oneline pr">
                    <Badge text='Before' className="rates__badge"/>
                    <TooltipWithoutAnchor>
                      <Text as="h6" className="title">Character</Text>
                      <Text as="h6" className="sub__title">Illustration from the <br/>
                      Briefstock gallery
                      </Text>
                    </TooltipWithoutAnchor>
                    <Icon src={"/images/carusel/rates/rate3.1.jpg"} alt="slider_rates3.1"/>
                </div>
                <div className="rates__wrapper rates__wrapper-oneline pr">
                    <Badge text='After 15 minutes' icon={Clock} className="rates__badge"/>
                    <TooltipWithoutAnchor>
                      <Text as="h6" className="title">Character</Text>
                      <Text as="h6" className="sub__title">
                        Modified illustration <br/> meeting client needs
                      </Text>
                    </TooltipWithoutAnchor>
                    <Icon src={"/images/carusel/rates/rate3.2.jpg"} alt="slider_rates3.2"/>
                </div>
              </div>
    
              <div className="">
                <div className="rates__wrapper mb-24 pr">
                  <Badge text='Before' className="rates__badge"/>
                  <TooltipWithoutAnchor>
                      <Text as="h6" className="title">Background</Text>
                      <Text as="h6" className="sub__title">
                        Illustration from the Briefstock gallery
                      </Text>
                    </TooltipWithoutAnchor>
                  <Icon src={"/images/carusel/rates/rate4.1.jpg"} alt="slider_rates4.1"/>
                </div>
                <div className="rates__wrapper pr">
                  <Badge text='After 30 minutes' icon={Clock} className="rates__badge"/>
                  <TooltipWithoutAnchor>
                      <Text as="h6" className="title">Background</Text>
                      <Text as="h6" className="sub__title">
                        Modified illustration meeting <br/>client needs
                      </Text>
                    </TooltipWithoutAnchor>
                  <Icon src={"/images/carusel/rates/rate4.2.jpg"} alt="slider_rates4.1"/>
                </div>
              </div>
            </Slider>
      </div>
    )
}

export default SliderMinutely;