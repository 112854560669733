import React from 'react';
import './howitworks.scss';

import { ADMIN_PROJECT_CREATE_URL, BROWSE_SEARCH_URL } from '@app/routes';
import { Text, Link, Icon } from '@ui-kit';

const HowItWorks = () => {
  return (
    <div className="main__container">
      <div className="how_it_works__container d-flex">
        <div className='d-flex how_it_works__wrapper'>
          <div className="how_it_works__item d-flex fd-column jc-sb">
            <div>
              <div className="how_it_works__preview">
                <Icon src={'/images/img_how_it_work1.jpg'} />
              </div>
              <Text as="h3" className="how_it_works__title">
                Brief
              </Text>
              <Text as="h4" className="how_it_works__suptitle">
                Without prepayment
              </Text>
              <Text as="h5" className="how_it_works__description">
                Leave your brief and create the illustration with the artist. Just
                order. Reasonable cost, high quality.
              </Text>
            </div>
            <Link to={ADMIN_PROJECT_CREATE_URL}>Fill out the brief</Link>
          </div>

          <div className="how_it_works__item d-flex fd-column jc-sb">
            <div>
              <div className="how_it_works__preview">
                <Icon src={'/images/img_how_it_work2.jpg'} />
              </div>
              <Text as="h3" className="how_it_works__title">
                Stock
              </Text>
              <Text as="h4" className="how_it_works__suptitle">
                Just $2 per illustration
              </Text>
              <Text as="h5" className="how_it_works__description">
                Experience unparalleled value with our extended license! Rest assured, 
                it's the best price you can find anywhere.
              </Text>
            </div>
            <Link to={BROWSE_SEARCH_URL} theme="secondary">
              Search
            </Link>
          </div>
        </div>
        <div className="how_it_works--no-ilustator__container d-flex jc-center fd-column">
          <Text as="h1">No Illustrator? Don’t worry.</Text>
          <Text as="h3">
            Don't waste time searching. Get unique illustrations from our
            creators at a reasonable price.
          </Text>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
