import AiIcon from '@ui-kit/icons/integrations/ai.svg';
import PsIcon from '@ui-kit/icons/integrations/ps.svg';
import FigmaIcon from '@ui-kit/icons/integrations/figma.svg';
import SketchIcon from '@ui-kit/icons/integrations/sketch.svg';
import FileIcon from '@ui-kit/icons/integrations/file.svg';

export const getExtentionIcon = (file, url) => {
    const fileName = file?.name || file;
    const fileNameArr = fileName.split('.');
    const fileExtension = fileNameArr[fileNameArr.length - 1].toLowerCase();

    switch (fileExtension) {
      case 'jpg': case 'png': case 'gif': case 'jpeg': case 'jfif': case 'pjpeg':
        return url;

      case 'ai': case 'eps': case 'svg': 
        return AiIcon;

      case 'psdt': case 'psd': case 'pdd':
        return PsIcon;

      case 'fig':
        return FigmaIcon;

      case 'sketch':
        return SketchIcon;

      default: return FileIcon;
    };
  };