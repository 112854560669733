import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

const Preloading = () => {
    return (
         <div className="main__container d-flex jc-center">
             {/* <Text as="h4" className="mt-20">Loading...</Text> */}
         </div>
    );
}

const withRouter = (component) => () => (
    <BrowserRouter>
        <Suspense fallback={<Preloading />}>
            {component}
        </Suspense>
    </BrowserRouter>
);

export default withRouter;