import React from "react";
import './productsTags.scss';
import { Button } from "@ui-kit";
import Slider from 'react-slick';
import { CarouselArrow } from '@ui-kit';
import { PRODUCTS_TAGS } from './tags.consts';

const Tags = ({onSelectTag}) => {
    const settings = {
        infinite       : true,
        speed          : 100,
        variableWidth  : true,
        arrows         : true,
        prevArrow: <CarouselArrow direction="prev" />,
        nextArrow: <CarouselArrow direction="next" />
    };

    return (
        <div className="pr">
            <div className='tags__container pr'>
                <Slider {...settings} >
                    {PRODUCTS_TAGS.map((tag, index) => 
                        <div key={`tag-${index}`}>
                            <Button 
                                key={`tags-${index}`}
                                className="products-tags__item mr-11"
                                theme='secondary-gray'
                                size="sm"
                                onClick={(e)=> onSelectTag(tag?.value || "")}
                            >
                                {tag.title}
                            </Button>
                        </div>
                    )}
                </Slider>
            </div>
        </div>
    );
}

export default Tags;
   
      
 