import HourlyRate from './hourleyrate';
import MinutelyRate from './minutelyrate';
import ExclusiveRate from './exclusive';
import {Text} from '@ui-kit';

export const RATES_TABS = [
 {
   title   : '$23 /hour',
   value   : 'hourley',
   isActive: true,
   content : <HourlyRate />
 },
 {
  title   : '$80 /hour',
  value   : 'max_rate',
  isActive: false,
  content : <ExclusiveRate />
},
 {
   title   : '$6 /15 min',
   value   : 'minutely',
   isActive: false,
   content : <MinutelyRate />
 },
];

export const RADIO_ITEMS = [
  {
    value    : 'extended',
    title    :  <Text as="h5">Extended commercial license</Text>, 
    isActive : true,
    isDisabled: false,
  },
  {
    value    : 'exclusive',
    title    : <Text as="h5">Exclusive license</Text>,
    isActive : false,
    isDisabled: false,
    tooltipText   : (<>
        <Text as="h6" className="rates-tooltip__title">Option not available</Text>
        <Text as="h6" className="rates-tooltip__subtitle">An exclusive license is only <br/>
        available for a new project</Text>
      </>)
  }
]