import React from 'react';

import { Text, Switcher, Icon } from '@ui-kit';
import Calendar from '@ui-kit/icons/calendar-gray500.svg';

const StepMilestoneToggle = ({
  title = '',
  date = '',
  status = false,
  extreme,
  state,
  switchStep,
  step,
  showCalendar,
}) => {
  const classes = ['step-switcher'];
  const classTitle = ['step-milestone__title'];
  const classSuptitle = ['step-milestone__suptitle'];

  if (!extreme) {
    classes.push('notextrimе');
  }

  if (status) {
    classes.push('passed');
    classTitle.push('passet');
    classSuptitle.push('passet');
  }

  const onSwitcherClick = () => {
    switchStep(step, !state);
  };

  return (
    <div className="step-toggle-milestone__container">
      <div>
        <div className={classes.join(' ')}>
          <Switcher size="sm" condition={state} onSwitcherClick={onSwitcherClick} />
        </div>
        <div className="step-milestone__description">
          <Text as="h6" className={classTitle.join(' ')}>
            {title}
          </Text>
          <Text as="h6" className={classSuptitle.join(' ')}>
            {date}
          </Text>
        </div>
      </div>
      {step !== 8 && (
        <div className="step-milestone-datepicker__icon">
          <Icon
            src={Calendar}
            alt="date-range-picker"
            onClick={() => showCalendar(true, step)}
          />
        </div>
      )}
    </div>
  );
};

export default StepMilestoneToggle;
