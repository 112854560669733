import React, {useState, useEffect} from 'react';
import './promoted.scss';

import { Icon, CheckBox } from '@ui-kit';

const PromotedItem = ({ item = {}, checkedItems = [], setCheckedItems = ()=>{}}) => {
  const [isItemCheck, setItemCheck] = useState();

  const { 
    pathToBrowse = '',
    id = 0,
  } = item;

  const PRODUCT_URL = process.env.REACT_APP_PRODUCTS_URL;
  const isChecked = checkedItems.filter((item) => item.id === id);


  useEffect(() => {
    setItemCheck(!!isChecked.length);
    // eslint-disable-next-line
  }, [checkedItems]);

  const setCheck = (event) => {
    const value = event.target.checked;
    setItemCheck(value);
    
    if(value) {
      setCheckedItems([...checkedItems, item])
    } else {
      const newChecked = checkedItems.filter((item) => item.id !== id)
      setCheckedItems(newChecked)
    };
  };

  return (

    <div className="promoted-ceil d-flex ai-center">
        <div className="promoted-ceil__icon__wrapper">
            <Icon src={`${PRODUCT_URL}/${pathToBrowse}`} />
        </div>
        <CheckBox
            className="checkbox__item"
            checked={isItemCheck}
            onChange={setCheck}
        />
    </div>
  );
};

export default PromotedItem;
