import React, { useEffect, useState } from 'react';
import './billing.scss';

import Card from './card';
import Legal from './legal';
import { useForm } from '@shared';
import { CountriesDropDown } from '@widgets';
import { ADMIN_USER_SETTINGS_URL } from '@app/routes';
import { useDispatch, useSelector } from 'react-redux';
import onBillingFormValid from './billing-validation.hook';
import { Input, Text, Switcher, CheckBox, Tooltip, Icon, Link } from '@ui-kit';
import { getCustomer, updateUserTC } from '@entities';
import InfoRedIcon from '@ui-kit/icons/alert-circle-error500.svg';
import QuestionIcon from '@ui-kit/icons/help-circle-gray400.svg';
import CountriesWithTaxPlaceholder from './countries_tax_example.json';

const BillingForm = ({ confirmBillingData = ()=>{}, setConfirmData = ()=>{} }) => {
  const dispatch = useDispatch();
  const [card, setCard] = useState({});
  const user = useSelector(getCustomer);
  const [errors, setErrors] = useState({});
  const [isIamLegal, setIsIamLegal] = useState(false);

  const mapUser4Form = user => {
    return {
      id: user.id,
      country: user?.country,
      address: user?.address,
      postCode: user.postCode,
      lastName: user?.lastName,
      firstName: user?.firstName,
    };
  };

  const [isAcceptPrivacyPolicy, setIsAcceptPrivacyPolicy] = useState(false);
  const { formData, onChangeInput, onSubmitForm } = useForm(
    mapUser4Form(user),
    handlerBeforeSubmitingForm
  );

  useEffect(() => {
    Object.assign(formData, mapUser4Form(user));
    // eslint-disable-next-line
  }, [user]);

  const [isPersonalInformationSaved, setIsPersonalInformationSaved] = useState(
    true
  );

  useEffect(() => {
    setConfirmData(false);
    setIsAcceptPrivacyPolicy(false);
    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    const errorsArr = Object.keys(errors)
    if (errorsArr.length) {
        const inputElement = document.getElementsByName(errorsArr[0])[0];
        inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };
  }, [errors]);

  let countryVatPlaceholderArray;

  if (user.country) {
    countryVatPlaceholderArray = CountriesWithTaxPlaceholder.filter(
      (country) => country.code === user?.country
    );
  }

  /*
   * 'function declaration' is necessary to call after describing the hook
   */
  async function handlerBeforeSubmitingForm() {
    if (formData.hasOwnProperty('notCheckTax') && formData.notCheckTax) {
      delete formData['tax'];
    }
    if (formData.hasOwnProperty('notCheckTax') && !formData.notCheckTax) {
      formData['tax'] = formData.tax || '';
    };

    const formErrors = onBillingFormValid(formData);
    const keysArrayError = Object.keys({ ...formErrors });

    if (keysArrayError.length && !card.complete) {
      setIsAcceptPrivacyPolicy(false)
      return setErrors({ ...formErrors, card: 'Your card data is incorrect' });
    }

    if (keysArrayError.length) {
      setIsAcceptPrivacyPolicy(false)
      return setErrors({ ...formErrors });
    }

    if (!card.complete) {
      setIsAcceptPrivacyPolicy(false)
      return setErrors({ card: 'Your card data is incorrect' });
    }

    setErrors({});
    delete formData['notCheckTax'];
    formData['firstName'] = user?.firstName;
    formData['country'] = user?.country;

    const keysArray = Object.keys(formData);
    const clearFormData = keysArray.filter((key) => formData[key]);
    const candidate = {};

    clearFormData.forEach((key) => (candidate[key] = formData[key]));

    if (isPersonalInformationSaved) {
      dispatch(updateUserTC(candidate.id, candidate));
    }

    confirmBillingData(candidate);
  }

  const onSwitcherClick = (value) => {
    if (value) {
      formData['tax'] = '';
      formData['notCheckTax'] = false;
      formData['company'] = '';
      formData['taxType'] = countryVatPlaceholderArray[0]?.tax_type || null;
    } else {
      delete formData['tax'];
      delete formData['notCheckTax'];
      delete formData['company'];
      delete formData['taxType'];
    }

    setConfirmData(false);
    setIsAcceptPrivacyPolicy(false);
    setIsIamLegal(value);
  };

  const setAcceptPrivacyPolicy = (event) => {
    const value = event.target.checked;
    event.stopPropagation();

    setIsAcceptPrivacyPolicy(value);

    if(value) {
      handlerBeforeSubmitingForm();
    }
  };

  const setPersonalInformationSaved = (event) => {
    const value = event.target.checked;

    setIsPersonalInformationSaved(value);
  };

  const renderAdressIcons = () => {
    let icon = QuestionIcon;

    if (errors?.address) {
      icon = InfoRedIcon;
    }

    return <Icon src={icon} alt="question__icon" className="question__icon" />;
  };

  const setCardErrors = (e) => {
    if (e?.card) {
      setErrors({ ...errors, ...e });
    }
  };

  const setCardData = (e) => {
    setCard(e);
    const error = { ...errors };
    delete error['card'];

    setErrors({ ...errors });
  };

  return (
    <div className="form__container billing__form">
      <div className="form__content">
        <form onSubmit={onSubmitForm} className="billing__form">
          <div className="billing-form__row mt-correction">
            <div className="billing-form__item">
              <Tooltip
                position="top"
                direction="revers"
                ancorPosition="anchor-right-bottom"
                content={ucantChangeeTooltipContent}
              >
                <Input
                  required
                  type="text"
                  name="firstName"
                  isDisabled={true}
                  label="First name"
                  placeholder="First name"
                  onChange={onChangeInput}
                  iconRight={QuestionIcon}
                  isError={errors?.firstName}
                  errorText={errors?.firstName}
                  value={formData?.firstName || ''}
                />
              </Tooltip>
            </div>
            <div className="billing-form__item">
              <Input
                required
                type="text"
                name="lastName"
                label="Last name"
                placeholder="Last name"
                onChange={onChangeInput}
                value={formData?.lastName || ''}
              />
            </div>
          </div>

          <div className="billing-form__row mt-correction">
            <div className="pr billing-form__item">
              <label className="billing-form__label">Country</label>
              <Tooltip
                position="top"
                direction="revers"
                ancorPosition="anchor-right-bottom"
                content={ucantChangeeTooltipContent}
              >
                <CountriesDropDown
                  isDisabled={true}
                  showArrow={false}
                  current={user?.country}
                  iconRight={QuestionIcon}
                  onChooseCountry={() => {}}
                />
              </Tooltip>
            </div>
            <div className="pr billing-form__item postalcode">
              <Input
                required
                type="text"
                name="postCode"
                label="Postal code *"
                onChange={onChangeInput}
                placeholder="Postal code"
                isError={errors?.postCode}
                errorText={errors?.postCode}
                value={formData?.postCode || ''}
              />
            </div>
          </div>

          <div
            className={`billing-form__row-full mt-correction ${
              errors?.address && 'error-row'
            }`}
          >
            <Input
              required
              type="text"
              name="address"
              label="Address *"
              className="pright"
              onChange={onChangeInput}
              isError={errors?.address}
              errorText={errors?.address}
              value={formData?.address || ''}
              placeholder="Enter your address"
            />

            <Tooltip
              position="top"
              direction="revers"
              ancorPosition="anchor-right-bottom"
              content={addressChangeeTooltipContent}
            >
              {renderAdressIcons()}
            </Tooltip>
          </div>

          <div className="billing-form__row-full mt-24 mb-24">
            <CheckBox
              className="checkbox__item"
              checked={isPersonalInformationSaved}
              onChange={setPersonalInformationSaved}
              label="Save my details for next time purchase."
            />
          </div>

          <div className="billing-form__row-full legal jc-start">
            <div className="d-flex jc-start ai-center">
              <Switcher
                size="sm"
                className="mt-24"
                condition={isIamLegal}
                onSwitcherClick={onSwitcherClick}
              />
              <Text as='span'>I am a legal entity</Text>
            </div>
          </div>

          {isIamLegal && (
            <Legal
              errors={errors}
              formData={formData}
              onChangeInput={onChangeInput}
              setConfirmData={setConfirmData}
              setIsAcceptPrivacyPolicy={setIsAcceptPrivacyPolicy}
              countryVatPlaceholderArray={countryVatPlaceholderArray}
            />
          )}

          <div className="billing-form__row-full card mb-24">
            <Card
              email={user.email}
              setCard={setCardData}
              cardErrors={errors?.card}
              setCardError={setCardErrors}
              name="card"
            />
            {errors?.card && (
              <Text as="span" className="error">
                {errors.card}
              </Text>
            )}
          </div>

          <div
            className={`billing-form__row-full ${
              errors?.privacyPolicy && 'error-checkbox'
            }`}
          > 
            <CheckBox
              checked={isAcceptPrivacyPolicy}
              className="checkbox__item"
              onChange={setAcceptPrivacyPolicy}
              label="I confirm the entered data and agree to the privacy policy."
            />
            {errors?.privacyPolicy && (
              <Text as="span" className="error">
                {errors.privacyPolicy}
              </Text>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const ucantChangeeTooltipContent = (
  <Text as="h6" className="tolltipe__content">
    You can change this data <br /> in your{' '}
    <Link to={ADMIN_USER_SETTINGS_URL} theme="text" size="md">
      personal account
    </Link>
  </Text>
);

const addressChangeeTooltipContent = (
  <>
    <Text as="h6" className="tolltipe__title">
      Please specify your full postal address
    </Text>
    <Text as="h6" className="tolltipe__subtitle">
      This is necessary for the correct payment <br />
      of taxes according to the requirements <br />
      of the EU tax service.
    </Text>
  </>
);

export default BillingForm;
