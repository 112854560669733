import React, { useState, forwardRef } from 'react';
import './rates.scss';
import { Text } from '@ui-kit';
import { RADIO_ITEMS, RATES_TABS } from './rates.constants';
import SliderHourley from './slider';
import SliderMinutely from './slider_minutely';
import { Licenses } from "@widgets";

const ProjectsRates = forwardRef((props, ref) => {
  const [radioItems, setRadioItems] = useState(RADIO_ITEMS);

  const filterTabs = filterArray(RATES_TABS, 'max_rate');
  const [tabs, setTabs] = useState(filterTabs);

  const [tabContent, setTabContent] = useState(filterTabs[0].content);
  const [slider, setSlider] = useState();

  const renderTabs = (value) => {
    let currentTabs = [];
    let currentRadios = setDisabled(radioItems, '');
    let currentSlider;

    switch (value) {
      case 'hourley':
        currentTabs = filterArray(RATES_TABS, 'max_rate');
        currentSlider = null;
        break;

      case 'max_rate':
        currentTabs = filterArray(RATES_TABS, 'hourley', true);
        currentRadios = setActive(radioItems, 'exclusive');
        currentSlider = null;
        break;

      default:
        currentTabs = tabs;
        currentRadios = setActive(radioItems, 'extended');
        currentRadios = setDisabled(radioItems, 'exclusive');
        currentSlider = 'minutely';
    }

    currentTabs = setActive(currentTabs, value);
    const currentContent = getTabContent(currentTabs, value);

    setTabs(currentTabs);
    setTabContent(currentContent);
    setRadioItems(currentRadios);
    setSlider(currentSlider);
  };

  const checkItem = (value) => {
    if (value === 'exclusive') {
      renderTabs('max_rate');
    }

    if (value === 'extended') {
      renderTabs('hourley');
    }

    const currentRadioItems = setActive(radioItems, value);

    setRadioItems(currentRadioItems);
  };

  const setActive = (array, tabName) => {
    //tabName -> minutely
    return array.map((item) => {
      if (item.value === tabName) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }

      return item;
    });
  };

  const setDisabled = (array, tabName) => {
    return array.map((item) => {
      if (item.value === tabName) {
        item.isDisabled = true;
      } else {
        item.isDisabled = false;
      }

      return item;
    });
  };

  const getTabContent = (currentTabs, key) => {
    const mainTab = currentTabs.find((item) => item.value === key);

    return mainTab.content;
  };

  function filterArray(array, value) {
    return array.filter((item) => item.value !== value);
  }

  const renderSlider = () => {
    if (!slider) {
      return <SliderHourley />;
    }

    return <SliderMinutely />;
  };

  return (
    <div className="rates__container" ref={ref}>
      <div className="main__container">
        <div className="rates__content d-flex ai-center">
          <div className="rates__right-container">
            <Text as="h1" className="rates__title">
              Our rates
            </Text>
            <div className="rates__tabs d-flex">
              {tabs.map((tab, index) => (
                <div
                  key={`tab` + index}
                  className={`rates__tabs-item ${tab.isActive && 'active'}`}
                  onClick={() => renderTabs(tab.value)}
                >
                  <Text as="h6">{tab.title}</Text>
                </div>
              ))}
            </div>
            {tabContent}
            <Licenses radioItems={radioItems} checkItem={checkItem} />
          </div>
          <div className="rates__slider-container">{renderSlider()}</div>
        </div>
      </div>
    </div>
  );
});

export default ProjectsRates;
