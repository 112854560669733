import React from "react";
import './preview_product_img.scss';

import { Icon} from '@ui-kit';

const { REACT_APP_PRODUCTS_URL } = process.env;

const PreviewProductImg = ({id, pathToPreview, classNames=''}) => {
    const classes= ['info__avatar', ...classNames.split(' ')];
    
    return (
        <div className={classes.join(' ')}>
            <Icon
                src={`${REACT_APP_PRODUCTS_URL}/${pathToPreview}`}
                alt={`info-avatar-${id}`}
            />
        </div>
    )
}

export default PreviewProductImg;