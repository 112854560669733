import React, { useEffect, useState } from 'react';
import './adminInfoMenu.scss';

import { Button } from '@ui-kit';

export const AdminInfoMenu = ({ setActive = ()=>{}, activeInfo = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const label = isOpen ? 'Close' : 'Edit';

  useEffect(() => {
    const status = activeInfo === 'info';
    setIsOpen(status);
  }, [activeInfo])

  const setStatus = () => {
    const active = isOpen ? 'chat' : 'info';
    
    setActive(active);
    setIsOpen(!isOpen);
  }

  return (
    <div className="admin__info">
      <div className={`admin__info-menu d-flex ${activeInfo}`}>
        <Button 
          theme="link-color" 
          size="md"
          onClick={() => setStatus()}
        >
          {label}
        </Button>
        {/* <Button
          theme="tertiary-gray" 
          size="md"
          onClick={() => setActive('costs')}
        >
          Costs
        </Button> */}
      </div>
    </div>
  );
};

export default AdminInfoMenu;
