import { ApiService } from '@shared/api';

class ProjectService extends ApiService {
  baseUrl;
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.baseUrl = '/projects';
    this.baseUserUrl = 'user/projects';
  }

  async getUserProject(id) {
    return await this.instance.get(`${this.baseUserUrl}/${id}`);
  }

  async getAdminProject(id) {
    return await this.instance.get(`${this.baseUrl}/${id}`);
  }

  async createProject(project) {
    this.setFormDataInterceptors();

    return await this.instance.post(`${this.baseUserUrl}`, project);
  }

  async updateAdminProject(id, formData) {
    this.setFormDataInterceptors();

    return await this.instance.put(`${this.baseUrl}/${id}`, formData);
  }

  async updateProjectProperties(project) {
    return await this.instance.patch(
      `${this.baseUrl}/properties/${project.id}`,
      project
    );
  }

  async updateAdminProjectProperty(id, property) {
    return await this.instance.patch(`${this.baseUrl}/${id}`, property);
  }

  async updateAdminBinaryProjectProperty(id, formData) {
    this.setFormDataInterceptors();

    return await this.instance.patch(`${this.baseUrl}/${id}`, formData);
  }

  async uploadBinaryArchive(options){
    return await this.instance.get(`${this.baseUrl}/upload?${this.generateQueryString(options)}`);
  }

  setFormDataInterceptors() {
    this.instance.interceptors.request.use((config) => {
      config.headers['Content-Type'] = 'multipart/form-data';
      config.headers['Accept'] = 'multipart/form-data';

      return config;
    });
  }

  async removeUserProject(id, property) {
    return await this.instance.patch(`${this.baseUserUrl}/${id}`, property);
  }
}

const projectService = new ProjectService();

export default projectService;
