import React from "react";
import './loading-admin.scss';
import { Text } from '@ui-kit';

const LoadingAdmin = () => {
    return (
        <div className="loading-admin__container">
            <Text as="h3">Loading...</Text>
        </div>
    )
}

export default LoadingAdmin;