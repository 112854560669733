import React, { memo, useState } from 'react';
import './modalProject.scss';
import { Text, Badge, Icon, Button, Link } from '@ui-kit';
import CrownWarningSmal from '@ui-kit/icons/crown-warning500.svg';
import TrashDanger from '@ui-kit/icons/trash-error700.svg';
import Clock from '@ui-kit/icons/clock-gray500.svg';
import Dollar from '@ui-kit/icons/dollar-gray500.svg';
import CheckFlex from '@ui-kit/icons/emoji/check-mark.svg';
import Petard from '@ui-kit/icons/emoji/party-popper.svg';
import WavingHand from '@ui-kit/icons/emoji/waving-hand.svg';
import Ok from '@ui-kit/icons/emoji/okay.svg';
import Selebration from '@ui-kit/icons/emoji/partying-face.svg';
import Crown from '@ui-kit/icons/emoji/crown.svg';
import ChevronIcon from '@ui-kit/icons/chevron-gray500.svg';

const ModalProject = ({
  className = '',
  notification = {},
  isUserRole = false,
  projectId = null,
  removeNotification = () => {}
}) => {
  const [isViewNotification, setViewNotification] = useState(!isUserRole);

  const { markType, hours, minutes, notificationValue, rate } = notification;
  const classes = ["notification-project__wrapper"];
  if(className) {
    classes.push(className);
  }

  const licensesBage =
    rate && rate === 'hourlyWithoutLicense' ? (
      <Badge icon={CrownWarningSmal} theme="warning" text="Exclusive license" />
    ) : (
      <Badge status={true} theme="blue-gray" text="Extended commercial license" />
    );

  let content = '';
  const showChevron = isViewNotification ? 'up' : 'down';
  const showButtonTitle = isViewNotification ? 'Show less' : 'Learn more';

  const viewNotification = () => {
    setViewNotification(!isViewNotification);
  }

  const renderTrash = () => {
    if (!isUserRole) {
      return (
        <Icon
          src={TrashDanger}
          alt="trash-danger"
          className="remove__btn"
          onClick={removeNotification}
        />
      );
    }

    const paymentsType = ['sketchReady', 'illustrationReady', 'sourceReady'];

    const paymentTypeFound = paymentsType.find((type) => type === markType);

    const hasTimeSpent = !!(hours + minutes);

    if (isUserRole && paymentTypeFound && hasTimeSpent) {
      return (
        <Link
          to={`/checkout/projects/${projectId}`}
          theme="text"
          className="notification__link"
        >
          Go to the payment
        </Link>
      );
    }
  };

  const renderTime = () => {
    return (
      <div className="notification-project__time">
        <Icon src={Clock} alt="clock" />
        <Text as="span">{hours ? hours : '00'}</Text>
        <Text as="span">{minutes ? `:${minutes}` : ':00'}</Text>
      </div>
    );
  };

  const renderPrice = () => {
    return (
      <div className="notification-project__cost">
        <Icon src={Dollar} alt="usd" />
        <Text as="span">{notificationValue}</Text>
      </div>
    );
  };

  switch (markType) {
    case 'sketchReady':
      content = (
        <>
          <div className="d-flex ai-center mb-9 notification-project__descr jc-sb pr">
            <Text as="h6" className="notification-project__title">
              The sketch is ready
              <Icon
                src={CheckFlex}
                alt="notification-check"
                className="title__icon"
              />
            </Text>
            {renderTrash()}
          </div>

          <Text as="h5" className={`notification-project__suptitle ${showChevron}`}>
            The sketch for your illustration is ready. You can suggest edits,
            if necessary. Please make a payment, and we will continue the
            project.
          </Text>
          <div className={`notification-project__info ${showChevron}`}>
            {licensesBage}
            {renderTime()}
            {renderPrice()}
          </div>
          <div className="notification__show-less">
              <Button 
                size="sm"
                theme="link-gray" 
                iconRight={ChevronIcon}
                onClick={viewNotification}
                className={showChevron}
              >
                {showButtonTitle}
              </Button>
          </div>
        </>
      );
      break;

    case 'illustrationReady':
      content = (
        <>
          <div className="d-flex ai-center mb-9 notification-project__descr jc-sb pr">
            <Text as="h6" className="notification-project__title">
              Your illustration is ready
              <Icon
                src={Petard}
                alt="notification-check"
                className="title__icon"
              />
            </Text>
            {renderTrash()}
          </div>
          <Text as="h5" className={`notification-project__suptitle ${showChevron}`}>
            Your illustration is ready. You can suggest edits, if necessary.
            Please make a payment, and we will continue the project.
          </Text>
          <div className={`notification-project__info ${showChevron}`}>
            {licensesBage}
            {renderTime()}
            {renderPrice()}
          </div>
          <div className="notification__show-less">
              <Button 
                size="sm"
                theme="link-gray" 
                iconRight={ChevronIcon}
                onClick={viewNotification}
                className={showChevron}
              >
                {showButtonTitle}
              </Button>
          </div>
        </>
      );
      break;

    case 'sourceReady':
      content = (
        <>
          <div className="d-flex ai-center mb-9 notification-project__descr jc-sb pr">
            <Text as="h6" className="notification-project__title">
              Just one more step to download the files
              <Icon
                src={Selebration}
                alt="notification-selebration"
                className="title__icon"
              />
            </Text>
            {renderTrash()}
          </div>
          <Text as="h5" className={`notification-project__suptitle ${showChevron}`}>
            The illustration is ready and it has been added to the set. It will
            be available for download after payment.
          </Text>
          <div className={`notification-project__info ${showChevron}`}>
            {licensesBage}
            {renderTime()}
            {renderPrice()}
          </div>
          <div className="notification__show-less">
              <Button 
                size="sm"
                theme="link-gray" 
                iconRight={ChevronIcon}
                onClick={viewNotification}
                className={showChevron}
              >
                {showButtonTitle}
              </Button>
          </div>
        </>
      );
      break;

    case 'waitingResponse':
      content = (
        <>
          <div className="d-flex ai-center mb-9 notification-project__descr jc-sb pr">
            <Text as="h6" className="notification-project__title">
              We are waiting for your answer
              <Icon
                src={WavingHand}
                alt="notification-check"
                className="title__icon"
              />
            </Text>
            {renderTrash()}
          </div>
          <Text as="h5" className={`notification-project__suptitle ${showChevron}`}>
            You have received a question from the Interviewer. Please answer
            in the chat below so that we can complete the brief and continue the
            work on your illustration.
          </Text>
          <div className="notification__show-less">
              <Button 
                size="sm"
                theme="link-gray" 
                iconRight={ChevronIcon}
                onClick={viewNotification}
                className={showChevron}
              >
                {showButtonTitle}
              </Button>
          </div>
        </>
      );
      break;

    //TODO
    case 'switchingToExclusive':
      content = (
        <>
          <div className="d-flex ai-center mb-9 notification-project__descr jc-sb pr">
            <Text as="h6" className="notification-project__title">
              Switching to an exclusive license
              <Icon
                src={Crown}
                alt="notification-check"
                className="title__icon"
              />
            </Text>
          {renderTrash()}
          </div>
          <Text as="h5" className={`notification-project__suptitle ${showChevron}`}>
            When the rate changes, the cost of work switches from{' '}
            <span>$23/hour</span>
            to <span>$80/hour.</span> Please confirm the rate change.
          </Text>
          {isUserRole && (
            <div className="notification__actions">
              <Button theme="secondary-gray">Decline</Button>
              <Button>Confirm</Button>
            </div>
          )}
          <div className="notification__show-less">
              <Button 
                size="sm"
                theme="link-gray" 
                iconRight={ChevronIcon}
                onClick={viewNotification}
                className={showChevron}
              >
                {showButtonTitle}
              </Button>
          </div>
        </>
      );
      break;

    case 'workProgress':
      content = (
        <>
          <div className="d-flex ai-center mb-9 notification-project__descr jc-sb pr">
            <Text as="h6" className="notification-project__title">
              We've passed all your wishes to the artis
              <Icon 
                src={Ok} 
                alt="notification-check" 
                className="title__icon" 
              />
            </Text>
          {renderTrash()}
          </div>
          <Text as="h5" className={`notification-project__suptitle ${showChevron}`}>
            When the work is ready, you'll see it in the chat.
          </Text>
          <div className="notification__show-less">
              <Button 
                size="sm"
                theme="link-gray" 
                iconRight={ChevronIcon}
                onClick={viewNotification}
                className={showChevron}
              >
                {showButtonTitle}
              </Button>
          </div>
        </>
      );
      break;

    default: {
    }
  }
  return <div className={classes.join(' ')}>{content}</div>;
};

export default memo(ModalProject);
