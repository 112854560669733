import React, {useState} from 'react';
import './paginationInput.scss';
import { Input, Icon, Dropdown, Text } from '@ui-kit';
import ChevronIcon from '@ui-kit/icons/chevron-gray500.svg';

const PaginationInput = ({
  currentPage = 1,
  allPage = 1,
  limitList = [],
  onClickPrev = () => {},
  onClickNext = () => {},
  onSubmit = () => {},
  onChangeValue = () => {},
  currentLimit,
  onLimitChange,
  isDisabled,
  viewList = true
}) => {
  const [limitValue, setLimitValue] = useState(currentLimit);
  const isNextActive = allPage > currentPage;
  const isPrevActive = currentPage > 1;

  let classes = ["pagination__container", "d-flex", "ai-center", "jc-center"];

  if (isDisabled) {
    classes.push('disabled');
  }

  const onSelectValue = (value) => {
    onLimitChange(value)
    setLimitValue(value);
  };

  const renderLimitPreview = (event, index) => {
    const name = event.name;
    const value = event.value;
    let classes = ['pr', 'executor__wrapper', 'input-search__item'];

    if (!name) return;

    if (value === limitValue) {
      classes.push('active');
    }

    return (
      <div
        className={classes.join(' ')}
        key={`limit-${index}`}
        onClick={() => onSelectValue(value)}
      >
        <Text as='span'>{name}</Text>
      </div>
    );
  };

  const limitRender = () => {
    if (!limitList.length || !viewList) {
      return;
    }

    return (
      <>
        <Dropdown
          id="input-limit"
          data={limitList}
          renderOption={renderLimitPreview}
          value={currentLimit}
          onChange={() => {}}
        />
      </>
    )
  }

  return (
    <div className={classes.join(' ')}>
      <div className="d-flex ai-center jc-center">
        <div className="pagination__arrow-left" onClick={onClickPrev}>
          {isPrevActive && (
            <Icon src={ChevronIcon} alt="arrow" className="pagination__arrow" />
          )}
        </div>
        <div>Page</div>
        <form onSubmit={onSubmit}>
          <Input
            value={currentPage}
            className="pagination__field"
            name="page"
            type="number"
            maxLengthCustom={allPage}
            minLengthCustom={1}
            onChange={onChangeValue}
          />
        </form>
        <div>Of {allPage}</div>
        <div className="pagination__arrow-right" onClick={onClickNext}>
          {isNextActive && (
            <Icon src={ChevronIcon} alt="arrow" className="pagination__arrow" />
          )}
        </div>
      </div>
      {limitRender()}
    </div>
  );
};

export default PaginationInput;
