import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
//import logger from 'redux-logger'

import reducer from './rootReducer';

const isProd = process.env.NODE_ENV !== 'production';
const middleware = [thunk];

if (isProd) {
  //middleware.push(logger);
}

const getProductionMode = () => {
  if (isProd) {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export default createStore(reducer, getProductionMode());