import { createSelector } from 'reselect';

export const getProduct = (state) => state.productData;

export const getCurrentProduct = createSelector(
    getProduct,
    (productData) => productData.product
);

export const getIsLoading = createSelector(
    getProduct,
    (productData) => productData.isLoading
);

export const getSimilarProducts = createSelector(
    getProduct,
    (productData) => productData.similarProducts
);

export const getEditedProduct = createSelector(
    getProduct,
    (productData) => productData.editedProduct
);
