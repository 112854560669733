import React from 'react';
import { useNavigate } from 'react-router-dom';
import './reset.scss';

import { Text, Icon, Button } from '@ui-kit';
import { ADMIN_LOGIN_URL } from '@app/routes';
import Arrow from '@ui-kit/icons/arrow-white.svg';
import CheckCircle from '@ui-kit/icons/check-circle-accent600.svg';

const PassReseted = ({ setPasswordIsReseted }) => {
    const navigate = useNavigate();

    const continueLogin = () => {
        setPasswordIsReseted(false);
        navigate(ADMIN_LOGIN_URL);
    };

    return (
        <div className="form__container reset__form">
            <div className="form__header">
                <div className="form__header-logo-container">
                    <div className="form__header-icon">
                        <Icon src={CheckCircle} />
                    </div>
                </div>
                <Text as="h2" className="form__header-title">
                    Password reset
                </Text>
                <Text as="h5" className="login__header-subtitle">
                    Your password has been successfully reset.
                    Click below to log.
                </Text>
            </div>
            <div className="form__content reseted">
                <Button 
                    onClick={() => continueLogin()}
                    size="lg"
                    iconLeft={Arrow}
                >
                    Back to log in
                </Button>
            </div>
        </div>
    )
};

export default PassReseted;
