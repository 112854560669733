import React, { useRef } from "react";
import './tooltip.scss';

/*
* position: top(default), bottom, right, left, middle-top
* ancorPosition: anchor-left-bottom(default), anchor-right-bottom, anchor-left-middle, 
* anchor-right-middle, anchor-bottom-middle, anchor-top-middle,  anchor-left-top, 
* anchor-right-top
* direction= "revers"
*/

const Tooltip = (props) => {
    const {
      position = "top",
      ancorPosition = "anchor-left-bottom",
      classNames = [],
      direction = "",
      children,
      content,
    } = props;
    const refComponent = useRef(null);

    const tooltipAction = (e) => {
       const sibling = e?.target?.previousSibling;

       if (!sibling) {
            return;
       }

       const {right, width} = sibling.getBoundingClientRect();
       const ancor = sibling.getElementsByClassName('tooltip__ancor')[0];
       const simblingOut = Math.round(right - width);

       if (simblingOut < 0) {
            const ancorDefaultRight = 12;
            const galleryDefaultPadding = 30;
            const rightStyle = simblingOut - galleryDefaultPadding;
            sibling.style.right= rightStyle + 'px';

            if (ancor) {
                ancor.style.right = `${ancorDefaultRight + (simblingOut * -1) + galleryDefaultPadding}px`;
                ancor.style.left  ="auto";
            }
       }

       if (right > window.innerWidth){
            sibling.style.right='0';

            if (ancor) {
                ancor.style.right ="12px";
                ancor.style.left  ="auto";
            }
       }
    }

    const holdTooltip = () => {
        const current = refComponent.current;
        if (!current) return;
        
        current.classList.add('view');
        setTimeout(() => {
          current.classList.remove('view');
        }, 200);
    };

    const TooltipComponent = () => {
        const classes = ['tooltipe__container', ...classNames, position, ancorPosition, direction]
        
        return (
            <div 
                className={classes.join(' ')}
            >
                <div className="tooltip__ancor"></div>
                {content}
            </div>
        )
    }

    return (
        <div
            className="tooltip__children pr"
            onMouseEnter = {tooltipAction}
            onMouseLeave={holdTooltip}
            ref={refComponent}
        >
            <TooltipComponent/>
            {children}
        </div>
       
    );
}

export default Tooltip;
