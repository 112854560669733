import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Recapcha, } from '@shared';
import './recovery.scss';

import { useForm } from '@shared';
import { Button, Input, Text, Icon } from '@ui-kit';
import { recoveryPasswordTC, getServerError, setServerErrorsAC } from '@entities';
import onRecoveryFormValid from './recovery-validation.hook';
import onCaptchaValid from '../security-form/captcha-validation.hook';
import { LoadingAdmin } from '@shared/ui';
import Arrow from '@ui-kit/icons/arrow-gray700.svg';
import Key from '@ui-kit/icons/key-accent600.svg';

//Autofill call onChangeInput form 2 time
const RecoveryPassForm = ({ setPasswordIsRecovered, setEmail, goToLogin }) => {
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const serverError = useSelector(getServerError);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const email = '';

  const { formData, onChangeInput, onSubmitForm } = useForm(
    email,
    handlerBeforeSubmitingForm
  );

  useEffect(() => {
    return () => dispatch(setServerErrorsAC([]));
  }, [dispatch]);

  async function handlerBeforeSubmitingForm() {
    // recaptchaRef.current.execute();

    await Recapcha.start(recaptchaRef);

    const formErrors = onRecoveryFormValid(formData);

    if (Object.keys(formErrors).length) {
      return setErrors(formErrors);
    }

    setErrors({});
  }

  const onSecuritySuccess = async (token) => {
    formData.captchToken = token;

    recaptchaRef.current.reset();
    setIsLoading(true);

    const isResult = await dispatch(recoveryPasswordTC(formData));

    if (!isResult) {
      setIsLoading(false);
      return;
    }

    setEmail(formData.email);
    setPasswordIsRecovered(true);
  };

  const onSecurityError = () => {
    const captchaError = onCaptchaValid({ captchToken: null });

    setErrors({ ...errors, ...captchaError });
  };

  const onRecaptchaChange = (token) => {
    if (!token) {
      onSecurityError();
    }

    return  onSecuritySuccess(token);
  } 

  return (
    <div className={`form__container recovery__form-container ${isLoading && 'pr'}`}>
      {isLoading && <LoadingAdmin />}
      <div className="form__header">
        <div className="form__header-logo-container">
          <div className="form__header-icon">
            <Icon src={Key} />
          </div>
        </div>
        <Text as="h2" className="form__header-title">
          Forgot password?
        </Text>
        <Text as="h5" className="login__header-subtitle">
          No worries, we’ll send you reset instructions.
        </Text>
      </div>
      <div className="form__content">
        <form onSubmit={onSubmitForm} className="login__form">
          <Input
            placeholder="Enter your email"
            name="email"
            type="email"
            required
            label="Email"
            value={formData?.email || ''}
            onChange={onChangeInput}
            className={errors.email && 'form-item__error'}
          />
          {errors.email && (
            <Text as="span" className="error">
              {errors.email}
            </Text>
          )}

          <Button type="submit" className="mt-24" size="lg">
            <Text as="span">Reset password</Text>
          </Button>

          <Recapcha
            recaptchaRef={recaptchaRef}
            className="g-recaptcha"
            sitekey={SITE_KEY}
            onChange={onRecaptchaChange}
            onErrored={onSecurityError}
            size= "invisible"
            //badge="inline"
          />
        </form>
        {errors.captcha && (
          <Text as="span" className="error">
            {errors.captcha}
          </Text>
        )}
        {/* server errors block */}
        {!!serverError.length && (
          <div className="server__error">
            {serverError.map((error) => (
              <Text as="span" key={error.param} className="error">
                {error.msg}
              </Text>
            ))}
          </div>
        )}
        <div>
          <Button 
              iconLeft={Arrow} 
              onClick={goToLogin} 
              size="md" 
              theme="link-gray"
          >
              Back to log in
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RecoveryPassForm;
