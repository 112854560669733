import React from 'react';
import './stepper-toggle.scss';
import { useDispatch} from 'react-redux';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import StepMilestone from './stepMilestoneToggle';
import StepDuration from './stepDuration';
import { 
  setProjectPropertieAC, 
  setisProjectChangeAC,
} from '@entities';
import { Tooltip, Text, Button } from '@ui-kit';

const StepperToggle = ({
  followingId = 0,
  showCalendar = () => {},
  followingData = [],
  callback = (intern) => {intern();},
  cost = 0,
  currentStepsHours = [],
  workingHoursSum = '',
  unpaidHours,
  editTimeCallback,
}) => {
  const dispatch = useDispatch();

  const switchStage = (step, value) => {
    callback({ step, value }, switchActiveStage(step, value));
  };

  const switchActiveStage = (step, value) => {
    return () => {
      if (!value) {
        dispatch(setProjectPropertieAC({ 
            key: 'followingId', value: step - 1 
          })
        );
        dispatch(setisProjectChangeAC());

        return;
      }

      dispatch(setisProjectChangeAC());
      dispatch(setProjectPropertieAC({ key: 'followingId', value: step + 1 }));
    };
  };

  const openEditTime = (stage, item) => {
    editTimeCallback({ stage: stage, data: item}, saveEditedTime())
  }

  const saveEditedTime = () => {
    return () => {
      dispatch(setisProjectChangeAC());
    }
  }

  const checkDate = (date) => {
    if (!date) return;

    return (
      <Text as="h6" className="time_milestone">
        ({format(date, 'dd LLLL', { locale: enUS })})
      </Text>
    );
  };

  const renderHourseMinutes = (item, key, index) => {
    const hours = item[key].hours;
    const minutes = item[key].minutes;
    const date = item[key].date;

    if (!hours && !minutes) return;

    if (!hours && minutes) {
      return (
        <div className="content_milestone" key={`hours_milestone-${index}`}>
          <Button
            theme="link-gray" 
            size="sm"
            onClick={() => {openEditTime(key, item)}}
          >            
            <Text as="h6" className="hours_milestone">
              {minutes} minutes
            </Text>
            {checkDate(date)}
          </Button>
        </div>
      );
    }

    if (hours && !minutes) {
      return (
        <div className="content_milestone" key={`hours_milestone-${index}`}>
          <Button
            theme="link-gray" 
            size="sm"
            onClick={() => {openEditTime(key, item)}}
          >   
            <Text as="h6" className="hours_milestone">
              {hours} hour
            </Text>
            {checkDate(date)}
          </Button>
        </div>
      );
    }

    return (
      <div className="content_milestone" key={`hours_milestone-${index}`}>
        <Button
          theme="link-gray" 
          size="sm"
          onClick={() => {openEditTime(key, item)}}
        >   
          <Text as="h6" className="hours_milestone">
            {hours} hour {minutes} minutes
          </Text>
          {checkDate(date)}
        </Button>
      </div>
    );
  };

  const getTimePartition = () => {
    const hours = +workingHoursSum.split('.')[0];
    const minute = +workingHoursSum.split('.')[1];

    if (!hours && !minute) {
      return 0;
    }

    if (hours && !minute) {
      return (
        <Text as="span">
          <Text as="span" className="time_milestone__highlight">
            {hours}
          </Text>{' '}
          hours
        </Text>
      );
    }

    if (!hours && minute) {
      return (
        <Text as="span">
          <Text as="span" className="time_milestone__highlight">
            {minute}
          </Text>{' '}
          minutes
        </Text>
      );
    }

    return (
      <Text as="span">
        <Text as="span" className="time_milestone__highlight">
          {hours}
        </Text>{' '}
        hours{' '}
        <Text as="span" className="time_milestone__highlight">
          {minute}
        </Text>{' '}
        minutes
      </Text>
    );
  };

  const getTimeUnpaid = () => {
    const unpaidModul = Math.abs(unpaidHours)
    const status = unpaidHours > 0 ? 'Unpaid' : 'Overpayment';
    const hours = Math.floor(unpaidModul);
    const minute = (unpaidModul % 1) * 60;

    if (!hours && !minute) {
      return (
        <Text as="span">Fully paid</Text>
      );
    };

    if (hours && !minute) {
      return (
        <Text as="span">
          {status}:{" "}
          <Text as="span" className="time_milestone__highlight">
            {hours}
          </Text>{" "}
          hours
        </Text>
      );
    };

    if (!hours && minute) {
      return (
        <Text as="span">
          {status}:{" "}
          <Text as="span" className="time_milestone__highlight">
            {minute}
          </Text>{" "}
          minutes
        </Text>
      );
    }

    return (
      <Text as="span">
        {status}:{" "}
        <Text as="span" className="time_milestone__highlight">
          {hours}
        </Text>{" "}
        hours{" "}
        <Text as="span" className="time_milestone__highlight">
            {minute}
        </Text>{" "}
        minutes
      </Text>
    );
  }

  const renderStep = (index, { type, title, date, status, state, key }) => {
    const extreme = index === 0 || index === STEPPER_STATE.length - 1;

    const currentHours = currentStepsHours.filter((item) => item[key]);

    const renderTooltipeContent = () => {
      if (key === 1) {
        return (
          <div className="">
            <Text as="h6" className="time_milestone__title">
              BriefStock clients do not need <br />
              to pay for this step
            </Text>
          </div>
        );
      }

      if (key === 7) {
        return (
          <div className="">
            <Text as="h6" className="time_milestone__title">
              Approximate cost of the project:
            </Text>
            <Text as="h6" className="cost_milestone">
              {cost}$
            </Text>
          </div>
        );
      }

      return (
        <>
          <Text as="h6" className="time_milestone__title">
            Total spent {getTimePartition()}
          </Text>
          <Text as="h6" className="time_milestone__title">
            {getTimeUnpaid()}
          </Text>
          <div>
            {currentHours.map((item, index) =>
              renderHourseMinutes(item, key, index)
            )}
          </div>
        </>
      );
    };

    return type === 'milestone' ? (
      <Tooltip
        position="top"
        content={renderTooltipeContent()}
        key={`stepper-${index}`}
      >
        <StepMilestone
          extreme={extreme}
          title={title}
          date={format(date, 'LLLL dd', { locale: enUS })}
          status={!!status}
          state={state}
          step={key}
          switchStep={switchStage}
          showCalendar={showCalendar}
        />
      </Tooltip>
    ) : (
      <StepDuration
        key={`stepper-${index}`}
        extreme={extreme}
        status={!!status}
      />
    );
  };

  const CURRENT_STEPPER_STATE = STEPPER_STATE.map((step) => {
    step.status = step.key <= followingId ? 1 : 0;
    step.state = step.status;

    const mapStep = followingData.find(
      (followingStep) => followingStep.step === step.key
    );
    step.date = mapStep.date;

    return step;
  });

  return (
    <div className="steps-toggle__container">
      {CURRENT_STEPPER_STATE.map((item, index) => renderStep(index, item))}
    </div>
  );
};

let STEPPER_STATE = [
  {
    key: 1,
    title: 'Brief',
    type: 'milestone',
    date: Date.now(),
    status: 0,
    state: false,
  },
  { key: 2, type: 'duration', date: Date.now(), status: 1 },
  {
    key: 3,
    title: 'Sketching',
    type: 'milestone',
    date: Date.now(),
    status: 0,
    state: false,
  },
  { key: 4, type: 'duration', date: Date.now(), status: 0 },
  {
    key: 5,
    title: 'Illustrating',
    type: 'milestone',
    date: Date.now(),
    status: 0,
    state: false,
  },
  { key: 6, type: 'duration', date: Date.now(), status: 0 },
  { key: 7, type: 'duration', date: Date.now(), status: 0 },
  {
    key: 8,
    title: 'Completed',
    type: 'milestone',
    date: Date.now(),
    status: 0,
    state: false,
  },
];

export default StepperToggle;
