import { AdminProjectDTO } from './adminProjectDTO';
import { UserProjectDTO } from './userProjectDTO';
import { EmptyProjectDTO } from './emptyProjectDTO';

class ProjectsFabrica {
    static list = {
        adminProject: AdminProjectDTO,
        userProject: UserProjectDTO,
        emptyProject: EmptyProjectDTO,
      };
    

    create(project, type) {
        const Project = ProjectsFabrica.list[type] || ProjectsFabrica.list.emptyProject;
        const instance = new Project(project);

        instance.type = type || "emptyProject";

        return instance
    }
}

const projectInstance = new ProjectsFabrica();
export { projectInstance };