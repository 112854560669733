import React from 'react';
import './admin-content.scss';

export const AdminContent = ({ children = ()=>{} }) => {
  return (
    <div className="admin__content">
      <div className="admin__content-screen d-flex">{children}</div>
    </div>
  );
};

export default AdminContent;
