import React, { useState } from 'react';
import './header-navigation-mobile.scss';
import { Link, Icon, DropdownMenu } from '@ui-kit';

import { MENU_LIST } from './header-navigation.const';
import Burger from '@ui-kit/icons/menu-gray500.svg';
import BurgerClose from '@ui-kit/icons/x-gray500.svg';

const HeaderNavigationMobile = ({ headerLinks = [], className = '' }) => {
  const [showMenu, setShowMenu] = useState(false);
  const classes = ['header__navigation-mobile', ...className.split(' ')];
  
  const onClickInternal = (value) => {
    setShowMenu(value);
  };

  const onVisibleChange = () => {
    setShowMenu(false);
  };
  
  const renderLink = ({ title, ico = '' }) => {
    if (ico) {
      return <Icon src={ico} />;
    }

    return title;
  };

  return (
    <ul className={classes.join(' ')}>
      {headerLinks.map((link, index) => (
        <li key={index}>
          <Link
            key={link.title}
            to={link.path}
            theme="tertiary-gray"
            className={link.classes && link.classes}
          >
            {renderLink(link)}
          </Link>
        </li>
      ))}
      <li className="burger__menu"> 
        <DropdownMenu
          menu_list={MENU_LIST}
          classes="menu-media"
          placement="bottom"
          onVisibleChange={onVisibleChange}
          isVisible={showMenu}
        >
          <Icon src={showMenu ? BurgerClose : Burger} alt="menu" onClick={() => onClickInternal(!showMenu)}/>
        </DropdownMenu>
      </li>
    </ul>
  );
};

export default HeaderNavigationMobile;
