import React, { useState, useEffect } from 'react';
import '../profile.scss';
import { Switcher, Text } from '@ui-kit';


const ProfileNotifications = ({ isMessagesSend, onChangeInput }) => {
    const [isMessages, setIsMessages] = useState(!!isMessagesSend);

    useEffect(() => {
      setIsMessages(!!isMessagesSend);
    }, [isMessagesSend]);

    const onSwitcherClick = (value) => {
        setIsMessages(value);
    };

    return (
        <div className="profile__notifications d-flex">
        <Switcher 
          size="sm" 
          condition={isMessages} 
          onSwitcherClick={onSwitcherClick} 
        />
        <div className="profile__notifications-content ml-8">
          <Text as="h6" className="content-title">Receive project notifications by email</Text>
          <Text as="h6">We recommend leaving it enabled so as not to miss private messages from the manager.</Text>
        </div>
      </div>
    )
};

export default ProfileNotifications;