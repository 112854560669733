import React from 'react';
import './questions.scss';

import { Text, Icon } from '@ui-kit';
import Clock from '@ui-kit/icons/clock-accent600.svg';
import Question from '@ui-kit/icons/help-circle-accent600.svg';
import Play from '@ui-kit/icons/play-accent600.svg';

const Questions = () => {
  return (
    <div className="questions__container">
      <div className="main__container questions__content">
        <div className="questions__header">
          <Text as="h1" className="mb-20">
            Frequently asked questions
          </Text>
          <Text as="h3">Have questions? We’re here to help.</Text>
        </div>
        <div className="questions__items d-flex">
          <div className="questions__item">
            <div className="questions__ico d-flex mb-22">
              <Icon src={Clock} alt="clock" />
            </div>
            <Text as="h3" className="mb-8">
              How long will my illustration take to render?
            </Text>
            <Text as="h5">
              We believe every project to be unique. To estimate the 
              time necessary to create the illustration you need, please, 
              send us a brief. We will calculate the time, carefully 
              taking into account all your requests.
            </Text>
          </div>

          <div className="questions__item">
            <div className="questions__ico d-flex mb-22">
              <Icon src={Question} alt="question" />
            </div>
            <Text as="h3" className="mb-8">
              What kind of license will I get for illustration?
            </Text>
            <Text as="h5">
              For custom illustration we estimate our working time, and you 
              choose the rate and therefore the license. If you have any 
              doubts or questions, our account manager helps you to make 
              a decision which license suits your project better.
            </Text>
          </div>

          <div className="questions__item">
            <div className="questions__ico d-flex questions__ico-play mb-22">
              <Icon src={Play} alt="play" />
            </div>
            <Text as="h3" className="mb-8">
              What do I need to start working on my project?
            </Text>
            <Text as="h5">
              To start we only need your brief. No prepayment: we get to 
              work right after we receive your agreement on the assessment.
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
