import React from 'react';
import './file.scss';

import Close from '@ui-kit/icons/x-gray700.svg';

import { Text, Icon } from '@ui-kit';
import { converterBiteTo } from '@shared';
import { getExtentionIcon } from '@lib/utils';

const FileMessage = ({ file = {}, removeFileMessage = () => {} }) => {
  if (file.uniqueName === 'loader') {
    return <FileMessageLoading />;
  }

  const originFile = file.file;
  const url = URL.createObjectURL(originFile);
  const extentionIcon = getExtentionIcon(originFile, url);

  return (
    <div className="message-file__item">
      <div
        className="message-file__close"
        onClick={() => removeFileMessage(file)}
      >
        <Icon src={Close} alt="message_close" />
      </div>
      <Text as="span" className="message-file__size">
        {converterBiteTo(originFile.size)}
      </Text>
      <div className="message-file__icon">
        <Icon src={extentionIcon} className="message-file__img" />
      </div>
    </div>
  );
};

const FileMessageLoading = () => {
  return (
    <div className="message-file__item loading">
      <Text as="span">Loading...</Text>
      <Text as="span" className="message-file__size">
        ... KiB
      </Text>
    </div>
  );
};

export default FileMessage;
