import {
  SET_EXECUTORS,
  SET_PROJECT_COST,
  SET_CHAT_MESSAGE,
  SET_CHAT_MESSAGES,
  SET_PROJECT_IMAGE,
  SET_CURRENT_PROJECT,
  SET_PROJECT_LOADING,
  SET_PROJECT_PROPERTIE,
  SET_PROJECT_EXECUTORS,
  SET_IS_PROJECT_CHANGED,
  SET_CURRENT_STEPS_HOURS,
  SET_IS_UNSAVED_PROJECT_SHOW,
  SET_PRODUCT_TO_PROJECT,
} from './project.constants';

import { Price } from '@entities';

const initialState = {
  isLoading: true,
  currentProject: {},
  currentProjectImage: {},
  messages: [],
  executors: {},
  isProjectWasChanged: false,
  isUnsavedProjectShow: false,
  productToProject: {},
};

const projectReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case SET_PROJECT_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case SET_CURRENT_PROJECT: {
      return {
        ...state,
        currentProject: payload,
      };
    }

    case SET_CHAT_MESSAGES: {
      return {
        ...state,
        messages: payload,
      };
    }

    case SET_CHAT_MESSAGE: {
      return {
        ...state,
        messages: [...state.messages, payload],
      };
    }

    case SET_PROJECT_PROPERTIE: {
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          [payload.key]: payload.value,
        },
      };
    }

    case SET_PROJECT_IMAGE: {
      return {
        ...state,
        currentProjectImage: payload,
      };
    }

    case SET_EXECUTORS: {
      return {
        ...state,
        executors: payload,
      };
    }

    case SET_IS_PROJECT_CHANGED: {
      return {
        ...state,
        isProjectWasChanged: payload,
      };
    }

    case SET_IS_UNSAVED_PROJECT_SHOW: {
      return {
        ...state,
        isUnsavedProjectShow: payload,
      };
    }

    case SET_PROJECT_EXECUTORS: {
      const { data, flag } = payload;

      let modifyExecutors = [];

      if (flag) {
        modifyExecutors = [...state.currentProject.executors, data];
      } else {
        modifyExecutors = [
          ...state.currentProject.executors.filter(
            (item) => item.id !== data.id
          ),
        ];
      }

      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          executors: modifyExecutors,
        },
      };
    }

    case SET_CURRENT_STEPS_HOURS: {
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          currentStepsHours: [
            ...state.currentProject.currentStepsHours,
            payload,
          ],
        },
      };
    }

    case SET_PROJECT_COST: {
      const { workingHoursSum, rate } = state.currentProject;
      const rateValue = rate.value;
      const hours = +workingHoursSum.split('.')[0];
      const minutes = +workingHoursSum.split('.')[1];


      const calculatedPrice = (
        hours * rateValue +
        (minutes * rateValue) / 60
      ).toFixed(2);
      const newPrice = new Price(calculatedPrice);

      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          price: newPrice,
        },
      };
    }

    case SET_PRODUCT_TO_PROJECT: {
      return {
        ...state,
        productToProject: payload,
      };
    }

    default:
      return state;
  }
};

export default projectReducer;
