import React, { useEffect } from 'react';
import './userInfoMenu.scss';
import { Text } from '@ui-kit';

export const UserInfoMenu = ({ setActive = ()=>{}, isActive, isDisabled = false }) => {
  const classes = ['user-menu__item'];

  useEffect(() => {
    if(isDisabled) {
      setActive('faq');
    };
    // eslint-disable-next-line
  }, [isDisabled]);

  if (isDisabled) {
    classes.push('disabled');
  };

  if (isActive === 'info') {
    classes.push('active');
  }

  const setActiveClass = (flag) => {
    if (flag === isActive) {
      return 'active';
    }

    return '';
  };

  return (
    <div className="user__info">
      <div className="user__info-menu d-flex">
        <Text
          as="h6"
          className={`user-menu__item ${setActiveClass('brief')}`}
          onClick={() => setActive('brief')}
        >
          Brief
        </Text>
        <Text
          as="h6"
          className={classes.join(' ')}
          onClick={() => setActive('info')} 
        >
          Info
        </Text>
        <Text
          as="h6"
          className={`user-menu__item ${setActiveClass('faq')}`}
          onClick={() => setActive('faq')}
        >
          FAQ
        </Text>
      </div>
    </div>
  );
};

export default UserInfoMenu;
