import React, { memo, useEffect } from 'react';
import './snackbar.scss';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getAlert, removeAlertAC } from '@entities';
import { Icon } from '@ui-kit';
import Close from '@ui-kit/icons/x-gray700.svg';

const Snackbar = () => {
  const dispatch = useDispatch();
  const alerts = useSelector(getAlert);
  const alert = alerts[0] || {};
  const { message, status, duration, showClose } = alert;

  useEffect(() => {
    if (!alert.id) return;

    if (Number.isFinite(duration) || !duration) {
      setTimeout(() => dispatch(removeAlertAC(alert.id)), duration || 3000);
    }
    
    // eslint-disable-next-line
  }, [alert.id]);

  if (!alert.id) {
    return;
  }

  const classes = ['snack__wrapper', `status-${status ? status : 'default'}`];

  const closeNotification = () => {
    dispatch(removeAlertAC(alert.id));
  };

  const snackbarElement = (
    <div className={classes.join(' ')}>
      <div className="d-flex">
        <div className="snack__status"></div>
        <div className="snack__content">{message}</div>
      </div>
      {showClose && (
        <div className="snack__close" onClick={closeNotification}>
          <Icon src={Close} alt="close" />
        </div>
      )}
    </div>
  );

  return ReactDOM.createPortal(
    snackbarElement,
    document.getElementById('snackbar-portal')
  );
};

export default memo(Snackbar);
