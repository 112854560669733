import {
  SET_SETTINGS,
} from './settings.constants';


const initialState = {
  hourlyRate: 0,
  hourlyWithoutLicense: 0,
  hoursNumber: 0, //???
  clientsCount: 0,
  ordersCount: 0,
  ordersProgressCount: 0,
  clientsCountAll: 0,
  ordersCountAll: 0,
  ordersProgressCountAll: 0
};

const userReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case SET_SETTINGS: {
      return {
        ...state,
        ...payload,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
