import React, { useEffect } from 'react';
import './productsTitle.scss';
import { useSelector, useDispatch } from 'react-redux';

import { Text } from '@ui-kit';
import {
  getMonthlyAmount,
  getProductsAmountTC,
  SET_MOUNTHLY_AMOUNT,
} from '@entities';

import { dateLib } from '@shared';

const ProductsTitle = ({isHide = false}) => {
  const dispatch = useDispatch();
  const monthlyAmount = useSelector(getMonthlyAmount);
  const currentMonth = dateLib.getCurrentMonth();
  const classes = ['products__container-title'];

  if (isHide) {
    classes.push('hide');
  };

  useEffect(() => {
    const mountlhlyAmount = getMonthStart();
    dispatch(getProductsAmountTC(mountlhlyAmount, SET_MOUNTHLY_AMOUNT));
  }, [dispatch]);

  const getMonthStart = () => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    return firstDay.getTime();
  };

  return (
    <div className={classes.join(' ')}>
      <div className="title-hide-box">
        <Text as="h1">Collection of upscale BriefStock illustrations </Text>
        <Text as="h3">
          In {currentMonth} we added
          <Text as="span" className="count">
            {monthlyAmount}
          </Text>
          unique illustrations to our gallery
        </Text>
      </div>
    </div>
  );
};

export default ProductsTitle;
