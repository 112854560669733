import React from 'react';
import './loader-progress.scss';

import { Text, Icon } from '@ui-kit';
import { converterBiteTo } from '@shared';
import Check from '@ui-kit/icons/check-circle-filled-accent600-sm.svg';

const FileLoaderWithProgress = ({ file = {} }) => {
  return (
    <div className="loader-progress__container">
      <div className="loader-progress__wrapper">
        <div className="loader-progress__content">
          <Text as="h6" className="progress__name">
            {file.name}
          </Text>

          <div className="d-flex ai-center">
            <progress className="progress" max="100" value="100" />
            <Text as="h6" className="progress__persents">
              100%
            </Text>
          </div>
          <Text as="h6" className="progress__size">
            {converterBiteTo(file.size)}
          </Text>
        </div>
        <Icon src={Check} alt="progress-check" />
      </div>
    </div>
  );
};

export default FileLoaderWithProgress;
