import './styles/main.scss';
import AuthSplitter from '@pages';
import { withProviders } from './providers';
import store from "../app/store";
import {
  checkAuthTC,
} from '@entities';

import version from './version';

class Application {
  constructor() {
    if (process.env.REACT_APP_MODE === 'local') {
      window.appVersion = version.combine();
    }
  }

  async init() {
    await store.dispatch(checkAuthTC());
    return withProviders(AuthSplitter);
  }
}

export default await (new Application()).init();
