import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './horizontalTabs.scss';

import { Link } from '@ui-kit';
import { 
    ADMIN_USER_SETTINGS_URL,
    ADMIN_USER_PASSWORD_URL,
    ADMIN_PURCHASES_URL,
    ADMIN_USER_BILLS_URL,
    //ADMIN_USER_REVIEWS_URL
} from '@app/routes';


const HorizontalTabs = () => {
    const location = useLocation();

    useEffect(()=> {
      const header = document.querySelector('.header');

      header.classList.add('non-border');
      return () =>  header.classList.remove('non-border');
    },[])

    const tabsLinks = [
        {
          url : ADMIN_USER_SETTINGS_URL,
          name: 'My details'
        },
        {
          url : ADMIN_USER_PASSWORD_URL,
          name: 'Password'
        },
        /*{
          url : ADMIN_USER_REVIEWS_URL,
          name: 'My favorites'
        },*/
        {
          url : ADMIN_PURCHASES_URL,
          name: 'My purchases'
        },
        {
          url : ADMIN_USER_BILLS_URL,
          name: 'My bills'
        },
    ];

    const isActiveButton = (url) => {
        const path = url.pathname;      
        if (path === location.pathname) {
            return 'active';
        }

        return '';
    }

    return (
        <div className="admin-navigate profile-navigate d-flex df-column jc-sb">
            <div className="d-flex df-column">
                <div className="admin-navigate__tab d-flex df-column">
                    {tabsLinks.map((item, index) => {
                        return (
                            <Link 
                                theme="text"
                                size="md"
                                key={index}
                                className={`tab-link ${isActiveButton(item.url)}`}
                                to={item.url}
                            >
                                {item.name}
                            </Link>
                    )})}
                </div>
            </div>
        </div>
    )
};

export default HorizontalTabs; 