import React from "react";
import { Footer } from '@widgets';

const FooterProvider = ({children}) => {
    return (
      <>
        {children}
        <Footer />
      </>
    )
  };


  export default FooterProvider;