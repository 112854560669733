import { createSelector } from 'reselect';

export const getProjectsData = (state) => state.projectsData;

export const getProjects = createSelector(
    getProjectsData,
    (projectsData) => projectsData.projects
);

export const getProjectsLength = createSelector(
    getProjectsData,
    (projectsData) => projectsData.projects.length
);

export const getProjectsIsLoading = createSelector(
    getProjectsData,
    (projectsData) => projectsData.isLoading
);

export const getProjectsPaginationAmount = createSelector(
    getProjectsData,
    (projectsData) => projectsData.paginationAmount
);

export const getProjectsSearchValue = createSelector(
    getProjectsData,
    (projectsData) => projectsData.projectsSearchValue
);
