import React from 'react';
import './profile-bills.scss';

import { Text, Link } from '@ui-kit';
import LinkIcon from '@ui-kit/icons/external-link-white.svg';

const BillsItem = ({ item = {} }) => {
  const {title, receiptNumber, receiptUrl, amountCaptured, currencySymbol, createdAt} = item;

  const date = createdAt ? new Date(createdAt).toLocaleString(undefined, {
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit'
  }) : '-';

  const time = createdAt ? new Date(createdAt).toLocaleTimeString(undefined, {
    hour: 'numeric', 
    minute: 'numeric',
    second: 'numeric',
  }) : '-';

  return (
    <div className="admin__table-row">
      <div className="bills-name">
        <Text as="h5">{title || ''}</Text>
        <Text as="h6">#{receiptNumber || ''}</Text>
      </div>

      <div className="bills-date">
          <Text as="h5">{date || ''}</Text>
          <Text as="h6">{time || ''}</Text>
      </div>

      <div className="bills-amount">
          <Text as="h6">{currencySymbol}{amountCaptured || ''}</Text>
      </div>

      <div className="bills-download">
          <Link 
            size="sm" 
            icon={LinkIcon}
            to={receiptUrl}
            target="_blank"
          >
            <Text as="span">Go to receipt</Text>
          </Link>
      </div>
    </div>
  );
};

export default BillsItem;
