import React from 'react';

import { Icon, Text } from '@ui-kit';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import Check from '@ui-kit/icons/check-def-accent600-sm.svg';

const StepMilestone = ({ 
  title = '',
  date = '',
  status = false,
  extreme 
}) => {
  const classes = ['step-milestone'];
  const classTitle = ['step-milestone__title'];
  const classSuptitle = ['step-milestone__suptitle'];

  if (!extreme) {
    classes.push('notextrimе');
  }

  if (status) {
    classes.push('passed');
    classTitle.push('passet');
    classSuptitle.push('passet');
  }

  return (
    <div className="step-milestone__container">
      <div className={classes.join(' ')}>{status && <Icon src={Check} />}</div>
      <div className="step-milestone__description">
        <Text as="h6" className={classTitle.join(' ')}>
          {title}
        </Text>
        <Text as="h6" className={classSuptitle.join(' ')}>
          {format(date, 'LLLL, dd', { locale: enUS })}
        </Text>
      </div>
    </div>
  );
};

export default StepMilestone;
