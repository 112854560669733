import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './profile-bills.scss';

import { Text } from '@ui-kit';
import { PaginationInput } from '@widgets';
import { 
  getUserBillsTC,
  getBillsPaginationAmount,
  setPurchasesLimitAC,
  getPurchasesLimit,
} from '@entities';
import BillsItem from './billsItem';

const ProfileBills = ({bills = []}) => {
  const dispatch = useDispatch();
  const billsPaginationAmount = useSelector(getBillsPaginationAmount);
  const billsLimit = useSelector(getPurchasesLimit);

  const [page, setPage] = useState();
  const [limit, setLimit] = useState();
  // eslint-disable-next-line
  const [field, setField] = useState();
  // eslint-disable-next-line
  const [order, setOrder] = useState();
  const [allPage, setAllPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const isEmpty = !+bills.length;
  const limitList = [
    {name: '20', value:20}, 
    {name: '50', value:50}, 
    {name:'100', value:100}
  ];
  
  useEffect(() => {
    setAllPage(billsPaginationAmount);
  }, [billsPaginationAmount]);

  useEffect(() => {
    setLimit(billsLimit);
  }, [billsLimit]);

  useEffect(() => {
    const params = getSearchParams();
    const searchByArray = [];

    params.forEach(({ key, value }) => {
      switch (key) {
        case 'page': {
          if (!+value || +value < 1) {
            searchParams.set('page', 1);
            setSearchParams(searchParams, {replace: true});
            return setPage(1);
          }

          setPage(value);
          break;
        }

        case 'limit': {
          if (!+value || +value < 1) {
            searchParams.set('limit', limitList[0].value);
            setSearchParams(searchParams, {replace: true});
            setAllPage(Math.ceil(+billsPaginationAmount));

            return setLimit(limitList[0].value);
          }

          const allPages = Math.ceil(+billsPaginationAmount / +value);
          
          setAllPage(allPages);
          setPurchasesLimitAC(+value);
          setLimit(+value);

          if (allPages < page) {
            setPage(allPages);
            searchParams.set('page', allPages);
            setSearchParams(searchParams, {replace: true});
          }

          break;
        }

        case 'fieldType': {
           // role, email, createdAt
          if (!value || value === '') {
            searchParams.set('fieldType', 'createdAt');
            setSearchParams(searchParams, {replace: true});
            setField('createdAt');
            return;
          }

          setField(value);
          //setPurchasedFieldAC(value);
          break;
        }

        case 'orderType': {
           // DESC, ASC
          if (!value || value === '') {
            searchParams.set('orderType', 'DESC');
            setSearchParams(searchParams, {replace: true});
            setOrder('DESC');
            return;
          }

          setOrder(value);
          //setPurchasedOrderAC(value);
          break;
        }

        case 'searchBy': {
          searchByArray.push(value);
          break;
        }

        default:
          break;
      }
    });

    if (searchByArray.length) {
      //const actualSearchValue = searchByArray.join(',');
      //dispatch(setSearchAC(actualSearchValue));
      //dispatch(setResultSearchAC(actualSearchValue));
    }

    return () => {
      //dispatch(setSearchAC(''));
      //dispatch(setResultSearchAC(''));
    };

    // eslint-disable-next-line
  }, [searchParams, billsPaginationAmount]);

  const getMyBills = () => {
    const search = window.location.search;
    const result = search.slice(search.indexOf('?') + 1).split('&').reduce((params, hash) => {
      let [key, val] = hash.split('=')
      return Object.assign(params, {[key]: decodeURIComponent(val)})
    }, {})
    const options = {
      limit: result.limit,
      page: result.page,
      orderType: 'DESC',
    };

    dispatch(getUserBillsTC(options));
  };

  const onLimitChange = (value) => {
    const allPages = Math.ceil(+billsPaginationAmount / +value);
    
    setAllPage(allPages);
    setLimit(+value);

    setPurchasesLimitAC(+value);
    searchParams.set('limit', +value)

    if (allPages < page) {
      setPage(allPages);
      searchParams.set('page', allPages);
    }
    setSearchParams(searchParams, {replace: true});
    getMyBills();
  };
  
  const getSearchParams = () => {
    const params = [];

    searchParams.forEach((value, key) => {
      params.push({ key, value });
    });

    return params;
  };

  const onChangeValue = (e) => {
    const value = e.target.value;
    setPage(value);
  };

  const onPageChange = (event) => {
    event.preventDefault();

    const page = +event.target.page.value;

    if (!page) {
      return;
    }

    if (page > allPage) {
      setPage(allPage);
      searchParams.set('page', allPage);
      setSearchParams(searchParams, {replace: true});
      return;
    }

    setPage(page);
    searchParams.set('page', page);
    setSearchParams(searchParams, {replace: true});

    getMyBills();
  };

  const onClickPrev = () => {
    document.querySelector('#root').scrollIntoView();

    const params = getSearchParams();
    let numberPage = 0;

    params.forEach((param) => {
      if (param.key === 'page') {
        numberPage = param.value;
      }
    });

    if (!numberPage || numberPage <= 1) {
      return;
    }

    const page = +numberPage - 1;
    searchParams.set('page', page);
    setSearchParams(searchParams, {replace: true});
    setPage(page);
    getMyBills();
  };

  const onClickNext = () => {
    document.querySelector('#root').scrollIntoView();

    const params = getSearchParams();
    let numberPage = 0;

    params.forEach((param) => {
      if (param.key === 'page') {
        numberPage = +param.value;
      }
    });

    if (!numberPage || numberPage >= allPage) {
      return;
    }

    const page = +numberPage + 1;
    searchParams.set('page', page);

    setSearchParams(searchParams, {replace: true});
    setPage(page);
    getMyBills();
  };

  return (
    <>
      <div className="bills__header">
        <Text as="h1" className="title mb-4">
          My bills
        </Text>
        <Text as="h5" className="sub-title">
          All receipts for the last 30 days are stored here
        </Text>
      </div>
      <div className="bills__container">
        <div className="admin__table">

          <div className="admin__table-header">
                <Text as="h6" className="bills-name">
                    Name
                </Text>
                <Text as="h6" className="bills-date">
                    Date
                </Text>
                <Text as="h6" className="bills-amount">
                    Amount paid
                </Text>
                <Text as="h6" className="bills-download">
                    {''}
                </Text>
            </div>
            <div className="admin__table-body">
                {bills.map((item, index) => (
                  <BillsItem
                    key={`profile-bills-${index}`}
                    item={item}
                  />
                ))}
            </div>

          <PaginationInput 
            allPage={allPage}
            onSubmit={onPageChange}
            onClickPrev={onClickPrev}
            onClickNext={onClickNext}
            currentPage={page}
            onChangeValue={onChangeValue}
            isDisabled={isEmpty}
            limitList={limitList}
            currentLimit={limit}
            onLimitChange={onLimitChange}
            listTop={true}
          />
        </div>
      </div>
    </>
  );
};

export default ProfileBills;
