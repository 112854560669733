import React, { useState, useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import "./vacancies.scss";

import { Text, Icon, Link, Button } from "@ui-kit";
import { setLocaleAC } from '@entities';
import ClockIcon from "@ui-kit/icons/clock-gray500.svg";
import DollarIcon from "@ui-kit/icons/dollar-gray500.svg";
import { VACANCIES } from "./vacancies.consts.js";

const Vacancies = ({ lang = 'en' }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isPosition , setIsPosition] = useState(false);
    const [isViewVacancies, setIsViewVacancies] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const domNode = document.getElementById("main__content");
        domNode.scrollIntoView({ behavior: 'smooth' });
    }, [location.pathname]);

    useEffect(() => {
        if(lang === 'ru') {
            setIsPosition(true);
            setIsViewVacancies(true);
            return;
        }

        setIsPosition(false);
        setIsViewVacancies(false);
    }, [lang]);

    const getVacancies = () => {
        setIsViewVacancies(true);
        setIsPosition(true);
        dispatch(setLocaleAC('ru'));
        searchParams.set('lang', 'ru')
        setSearchParams(searchParams, {replace: true});
    }
    

    return (
        <>
            {!isPosition ? (
                <div className="career__frame">
                    <a 
                        name="open_positions" 
                        className="anchor-offset frame-title"
                        href="#positions"
                    >
                        <FormattedMessage
                        id="career.menu.ch3"
                        defaultMessage="Open positions"
                        />
                    </a>
                    <Text as="p" className="frame-content">
                        <FormattedMessage
                        id="career.content.ch3.pr2"
                        defaultMessage="We are a united distributed team. Our 
                                        employees live in different countries: 
                                        Poland, Ukraine, Belarus, Russia, Armenia, 
                                        Moldova, Turkey, Israel and other parts of 
                                        the world. We all speak Russian, so at the 
                                        moment we are only considering Russian-speaking 
                                        applicants. You can find job vacancies in the 
                                        Russian version of our site. Thank you for 
                                        understanding."
                        />
                    </Text>
                    <Button 
                        size="lg"
                        onClick={getVacancies}
                    >
                        View vacancies
                    </Button>
                </div>
            ) : (
                <div className="career__frame">
                    <a 
                        name="open_positions" 
                        className="anchor-offset frame-title"
                        href="#position"
                    >
                        <FormattedMessage
                        id="career.menu.ch3"
                        defaultMessage="Open positions"
                        />
                    </a>
                    <Text as="p" className="frame-content">
                        <FormattedMessage
                        id="career.content.ch3.pr1"
                        defaultMessage="Determine what is important for your position and, based on this, 
                            show us a well-designed portfolio. If you apply to be a vector 
                            illustrator and send a raster portfolio, this will not help you 
                            become part of the team."
                        />
                    </Text>
                </div>
            )}

            {isViewVacancies && (
                <div className="vacancies__container">
                    {VACANCIES.map((item, index) => { 
                        const url = item.url.pathname + '?lang=' + lang;
                        return (
                            <div key={index} className="vacancies__item">
                                <Link 
                                    to={url}
                                    theme="link-color" 
                                    size="xxl" 
                                    className="item-title"
                                >
                                    <FormattedMessage 
                                        id={`${item.id}title`}
                                        defaultMessage={item.title}
                                    />
                                </Link>
                                <Text as="h5">
                                    <FormattedMessage 
                                        id={`${item.id}description`}
                                        defaultMessage={item.description}
                                    />
                                </Text>
                                <div className="item-conditions d-flex">
                                    <div className="item-salary d-flex">
                                    <Icon src={DollarIcon}/>
                                    <Text as="h5">{item.salary}</Text>
                                    </div>
                                    <div className="item-time d-flex">
                                    <Icon src={ClockIcon} />
                                    <Text as="h5">
                                        <FormattedMessage 
                                            id={`${item.id}time`}
                                            defaultMessage={item.time}
                                        />
                                    </Text>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </>
    );
};

export default Vacancies;