const SLIDER_TOP = [
  {
    id: "tropical-island-beach-with-old-pirate-ship",
    name: "tropical-island-beach-with-old-pirate-ship",
    pathToBrowse:
      "2208.w026.n002.2325B.p1.2325/browse.2208.w026.n002.2325B.p1.2325.jpg",
    pathToPreview:
      "2208.w026.n002.2325B.p1.2325/preview.2208.w026.n002.2325B.p1.2325.jpg",
    alt: "slide1",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "tropical-island-beach-with-old-pirate-ship",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "woman-working-at-loft-office-or-coworking",
    name: "woman-working-at-loft-office-or-coworking",
    pathToBrowse:
      "2110.w023.n001.1204B.p1.1204/browse.2110.w023.n001.1204B.p1.1204.jpg",
    pathToPreview:
      "2110.w023.n001.1204B.p1.1204/preview.2110.w023.n001.1204B.p1.1204.jpg",
    alt: "slide2",
    price: 2,
    userId: 2,
    isFree: true,
    linkId: "woman-working-at-loft-office-or-coworking",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "cartoon-city-street-with-nice-houses-and",
    name: "cartoon-city-street-with-nice-houses-and",
    pathToBrowse:
      "2212.w020.n002.1894B.p15.1894/browse.2212.w020.n002.1894B.p15.1894.jpg",
    pathToPreview:
      "2212.w020.n002.1894B.p15.1894/preview.2212.w020.n002.1894B.p15.1894.jpg",
    alt: "slide3",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "cartoon-city-street-with-nice-houses-and",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "little-black-girl-cartoon-character-for-animation",
    name: "little-black-girl-cartoon-character-for-animation",
    pathToBrowse:
      "2207.w020.n002.1548.MotionCharacter.p15.1548/browse.2207.w020.n002.1548.MotionCharacter.p15.1548.jpg",
    pathToPreview:
      "2207.w020.n002.1548.MotionCharacter.p15.1548/preview.2207.w020.n002.1548.MotionCharacter.p15.1548.jpg",
    alt: "slide4",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "little-black-girl-cartoon-character-for-animation",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "little-girl-cartoon-character-for-animation-front",
    name: "little-girl-cartoon-character-for-animation-front",
    pathToBrowse:
      "2209.w020.n002.1683B.p15.1683/browse.2209.w020.n002.1683B.p15.1683.jpg",
    pathToPreview:
      "2209.w020.n002.1683B.p15.1683/preview.2209.w020.n002.1683B.p15.1683.jpg",
    alt: "slide5",
    price: 2,
    userId: 2,
    isFree: true,
    linkId: "little-girl-cartoon-character-for-animation-front",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "cartoon-beautiful-girl-with-blonde-hair-wear",
    name: "cartoon-beautiful-girl-with-blonde-hair-wear",
    pathToBrowse:
      "2211.w020.n002.1822A.p30.1822/browse.2211.w020.n002.1822A.p30.1822.jpg",
    pathToPreview:
      "2211.w020.n002.1822A.p30.1822/preview.2211.w020.n002.1822A.p30.1822.jpg",
    alt: "slide6",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "cartoon-beautiful-girl-with-blonde-hair-wear",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "happy-boy-waiting-school-bus-in-city",
    name: "happy-boy-waiting-school-bus-in-city",
    pathToBrowse:
      "2207.w023.n003.2770B.p1.2770/browse.2207.w023.n003.2770B.p1.2770.jpg",
    pathToPreview:
      "2207.w023.n003.2770B.p1.2770/preview.2207.w023.n003.2770B.p1.2770.jpg",
    alt: "slide7",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "happy-boy-waiting-school-bus-in-city",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "fishing-boats-at-pier-in-lake-river",
    name: "fishing-boats-at-pier-in-lake-river",
    pathToBrowse:
      "2212.w015.n003.1103B.p15.1103/browse.2212.w015.n003.1103B.p15.1103.jpg",
    pathToPreview:
      "2212.w015.n003.1103B.p15.1103/preview.2212.w015.n003.1103B.p15.1103.jpg",
    alt: "slide8",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "fishing-boats-at-pier-in-lake-river",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "pink-magic-castle-on-floating-island-in",
    name: "pink-magic-castle-on-floating-island-in",
    pathToBrowse:
      "2207.w020.n002.1566B.p15.1566/browse.2207.w020.n002.1566B.p15.1566.jpg",
    pathToPreview:
      "2207.w020.n002.1566B.p15.1566/preview.2207.w020.n002.1566B.p15.1566.jpg",
    alt: "slide9",
    price: 2,
    userId: 2,
    isFree: true,
    linkId: "pink-magic-castle-on-floating-island-in",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "open-space-office-workplace-interior-with-tables",
    name: "open-space-office-workplace-interior-with-tables",
    pathToBrowse:
      "2210.w015.n003.1011B.p15.1011/browse.2210.w015.n003.1011B.p15.1011.jpg",
    pathToPreview:
      "2210.w015.n003.1011B.p15.1011/preview.2210.w015.n003.1011B.p15.1011.jpg",
    alt: "slide10",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "open-space-office-workplace-interior-with-tables",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "space-game-background-night-alien-fantasy-landscape",
    name: "space-game-background-night-alien-fantasy-landscape",
    pathToBrowse:
      "2004.w020.n001.244B.p15.244/browse.2004.w020.n001.244B.p15.244.jpg",
    pathToPreview:
      "2004.w020.n001.244B.p15.244/preview.2004.w020.n001.244B.p15.244.jpg",
    alt: "slide11",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "space-game-background-night-alien-fantasy-landscape",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "motel-at-night-highway-roadside-old-building",
    name: "motel-at-night-highway-roadside-old-building",
    pathToBrowse:
      "2207.w054.n005.76B.p1.76/browse.2207.w054.n005.76B.p1.76.jpg",
    pathToPreview:
      "2207.w054.n005.76B.p1.76/preview.2207.w054.n005.76B.p1.76.jpg",
    alt: "slide12",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "motel-at-night-highway-roadside-old-building",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "cute-cartoon-astronaut-flying-on-rocket-in",
    name: "cute-cartoon-astronaut-flying-on-rocket-in",
    pathToBrowse:
      "2301.w026.n002.3073B.p1.3073/browse.2301.w026.n002.3073B.p1.3073.jpg",
    pathToPreview:
      "2301.w026.n002.3073B.p1.3073/preview.2301.w026.n002.3073B.p1.3073.jpg",
    alt: "slide13",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "cute-cartoon-astronaut-flying-on-rocket-in",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "game-icons-with-heart-lightning-key-crown",
    name: "game-icons-with-heart-lightning-key-crown",
    pathToBrowse:
      "2108.w032.n003.51A.p1.51/browse.2108.w032.n003.51A.p1.51.jpg",
    pathToPreview:
      "2108.w032.n003.51A.p1.51/preview.2108.w032.n003.51A.p1.51.jpg",
    alt: "slide14",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "game-icons-with-heart-lightning-key-crown",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "gold-award-badges-for-win-in-game",
    name: "gold-award-badges-for-win-in-game",
    pathToBrowse:
      "2109.w032.n003.61A.p1.61/browse.2109.w032.n003.61A.p1.61.jpg",
    pathToPreview:
      "2109.w032.n003.61A.p1.61/preview.2109.w032.n003.61A.p1.61.jpg",
    alt: "slide15",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "gold-award-badges-for-win-in-game",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "set-cartoon-of-casino-poker-game-slot",
    name: "set-cartoon-of-casino-poker-game-slot",
    pathToBrowse:
      "2302.w020.n002.1999A.p30.1999/browse.2302.w020.n002.1999A.p30.1999.jpg",
    pathToPreview:
      "2302.w020.n002.1999A.p30.1999/preview.2302.w020.n002.1999A.p30.1999.jpg",
    alt: "slide16",
    price: 2,
    userId: 2,
    isFree: true,
    linkId: "set-cartoon-of-casino-poker-game-slot",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "cartoon-set-of-cute-princess-character-with",
    name: "cartoon-set-of-cute-princess-character-with",
    pathToBrowse:
      "2301.w020.n002.1941B.p15.1941/browse.2301.w020.n002.1941B.p15.1941.jpg",
    pathToPreview:
      "2301.w020.n002.1941B.p15.1941/preview.2301.w020.n002.1941B.p15.1941.jpg",
    alt: "slide17",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "cartoon-set-of-cute-princess-character-with",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "police-inspector-or-private-detective-on-background",
    name: "police-inspector-or-private-detective-on-background",
    pathToBrowse:
      "2207.w023.n003.2653B.p1.2653/browse.2207.w023.n003.2653B.p1.2653.jpg",
    pathToPreview:
      "2207.w023.n003.2653B.p1.2653/preview.2207.w023.n003.2653B.p1.2653.jpg",
    alt: "slide17",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "police-inspector-or-private-detective-on-background",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
];

const SLIDER_BOTTOM = [
  {
    id: "modern-living-room-with-purple-furniture-and",
    name: "modern-living-room-with-purple-furniture-and",
    pathToBrowse:
      "2206.w015.n003.877B.p15.877/browse.2206.w015.n003.877B.p15.877.jpg",
    pathToPreview:
      "2206.w015.n003.877B.p15.877/preview.2206.w015.n003.877B.p15.877.jpg",
    alt: "slide1",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "modern-living-room-with-purple-furniture-and",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "fast-food-retro-cafe-interior-with-tables",
    name: "fast-food-retro-cafe-interior-with-tables",
    pathToBrowse:
      "2211.w020.n002.1809B.p15.1809/browse.2211.w020.n002.1809B.p15.1809.jpg",
    pathToPreview:
      "2211.w020.n002.1809B.p15.1809/preview.2211.w020.n002.1809B.p15.1809.jpg",
    alt: "slide2",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "fast-food-retro-cafe-interior-with-tables",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "tropical-island-with-treasure-chest-and-broken",
    name: "tropical-island-with-treasure-chest-and-broken",
    pathToBrowse:
      "2105.w026.n002.415B.p1.415/browse.2105.w026.n002.415B.p1.415.jpg",
    pathToPreview:
      "2105.w026.n002.415B.p1.415/preview.2105.w026.n002.415B.p1.415.jpg",
    alt: "slide3",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "tropical-island-with-treasure-chest-and-broken",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "wild-african-animals-zoo-characters-vector-cartoon",
    name: "wild-african-animals-zoo-characters-vector-cartoon",
    pathToBrowse:
      "2205.w054.n004.30A.p1.30/browse.2205.w054.n004.30A.p1.30.jpg",
    pathToPreview:
      "2205.w054.n004.30A.p1.30/preview.2205.w054.n004.30A.p1.30.jpg",
    alt: "slide4",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "wild-african-animals-zoo-characters-vector-cartoon",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "smartphone-lock-screens-with-underwater-animals-cartoon",
    name: "smartphone-lock-screens-with-underwater-animals-cartoon",
    pathToBrowse:
      "2108.w026.n002.723B.p0.723/browse.2108.w026.n002.723B.p0.723.jpg",
    pathToPreview:
      "2108.w026.n002.723B.p0.723/preview.2108.w026.n002.723B.p0.723.jpg",
    alt: "slide5",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "smartphone-lock-screens-with-underwater-animals-cartoon",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "white-bear-and-penguin-fishing-cute-wild",
    name: "white-bear-and-penguin-fishing-cute-wild",
    pathToBrowse:
      "2111.w015.n001.584B.p30.584/browse.2111.w015.n001.584B.p30.584.jpg",
    pathToPreview:
      "2111.w015.n001.584B.p30.584/preview.2111.w015.n001.584B.p30.584.jpg",
    alt: "slide6",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "white-bear-and-penguin-fishing-cute-wild",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "cryonics-laboratory-empty-interior-with-equipment-and",
    name: "cryonics-laboratory-empty-interior-with-equipment-and",
    pathToBrowse:
      "2010.w020.n001.789B.p15.789/browse.2010.w020.n001.789B.p15.789.jpg",
    pathToPreview:
      "2010.w020.n001.789B.p15.789/preview.2010.w020.n001.789B.p15.789.jpg",
    alt: "slide7",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "cryonics-laboratory-empty-interior-with-equipment-and",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "young-couple-looking-on-night-city-from",
    name: "young-couple-looking-on-night-city-from",
    pathToBrowse:
      "2301.w030.n003.568B.p1.568/browse.2301.w030.n003.568B.p1.568.jpg",
    pathToPreview:
      "2301.w030.n003.568B.p1.568/preview.2301.w030.n003.568B.p1.568.jpg",
    alt: "slide8",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "young-couple-looking-on-night-city-from",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "open-and-closed-magic-books-of-spells",
    name: "open-and-closed-magic-books-of-spells",
    pathToBrowse:
      "2211.w054.n005.219B.p1.219/browse.2211.w054.n005.219B.p1.219.jpg",
    pathToPreview:
      "2211.w054.n005.219B.p1.219/preview.2211.w054.n005.219B.p1.219.jpg",
    alt: "slide9",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "open-and-closed-magic-books-of-spells",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "young-couple-looking-on-night-city-from",
    name: "young-couple-looking-on-night-city-from",
    pathToBrowse:
      "2211.w020.n002.1816B.p15.1816/browse.2211.w020.n002.1816B.p15.1816.jpg",
    pathToPreview:
      "2211.w020.n002.1816B.p15.1816/preview.2211.w020.n002.1816B.p15.1816.jpg",
    alt: "slide10",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "young-couple-looking-on-night-city-from",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "girl-with-flying-books-with-magic-glow",
    name: "girl-with-flying-books-with-magic-glow",
    pathToBrowse:
      "2108.w023.n001.896B.p1.896/browse.2108.w023.n001.896B.p1.896.jpg",
    pathToPreview:
      "2108.w023.n001.896B.p1.896/preview.2108.w023.n001.896B.p1.896.jpg",
    alt: "slide11",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "girl-with-flying-books-with-magic-glow",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "senior-writer-work-in-cabinet-on-typewriter",
    name: "senior-writer-work-in-cabinet-on-typewriter",
    pathToBrowse:
      "2209.w020.n002.1671B.p15.1671/browse.2209.w020.n002.1671B.p15.1671.jpg",
    pathToPreview:
      "2209.w020.n002.1671B.p15.1671/preview.2209.w020.n002.1671B.p15.1671.jpg",
    alt: "slide12",
    price: 2,
    userId: 2,
    isFree: true,
    linkId: "senior-writer-work-in-cabinet-on-typewriter",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },
    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
  {
    id: "mom-or-housewife-speaking-by-smartphone-with",
    name: "mom-or-housewife-speaking-by-smartphone-with",
    pathToBrowse:
      "2108.w023.n001.859B.p1.859/browse.2108.w023.n001.859B.p1.859.jpg",
    pathToPreview:
      "2108.w023.n001.859B.p1.859/preview.2108.w023.n001.859B.p1.859.jpg",
    alt: "slide13",
    price: 2,
    userId: 2,
    isFree: false,
    linkId: "mom-or-housewife-speaking-by-smartphone-with",
    user: {
      firstName: "BriefStock",
      lastName: "",
      role: "briefstock",
      ava: "2_avatar.jpg",
    },

    illustration_formats: ["EPS", "JPEG"],
    compatibility_draw: ["illustrator"],
  },
];

export const GALLERY_SLIDERS_QA = {
  SLIDER_TOP,
  SLIDER_BOTTOM,
};
