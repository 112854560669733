import React from "react";
import './empty-project.scss';

import { Text } from '@ui-kit';

const EmptyProject = () => {
    return (
        <div className="new-project__preview">
        <Text as="h3">New</Text>
      </div>
    );
}

export default EmptyProject;