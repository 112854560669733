import { Validation } from '@shared/lib/utils';

const onDeleteFormValid = (formData) => {
  let errorsState = {};

  const validationReason = new Validation({
    field: formData,
    title: 'Reason',
  })
    .isRequired()
    .isValidTextarea()
    .maxLenght(500)
    .getErrors();


  if (validationReason.length) {
    errorsState = {
      ...errorsState,
      textarea: validationReason[0],
    };
  }

  return errorsState;
};

export default onDeleteFormValid;
