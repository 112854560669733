import { Validation } from '@shared/lib/utils';

const onSignUpFormValid = (formData) => {
  let errorsState = {};

  const validationFullName = new Validation({
    field: formData.firstName,
    title: 'Full name',
  })
    .isRequired()
    .maxLenght(30)
    .getErrors();

  const validationEmail = new Validation({
    field: formData.email,
    title: 'Email',
  })
    .isRequired()
    .isEmail()
    .maxLenght(64)
    .getErrors();

  const validationPassword = new Validation({
    field: formData.password,
    title: 'Password',
  })
    .isRequired()
    .minLenght(8)
    .maxLenght(32)
    .containLettersAndNumbers()
    .getErrors();

  const validationConfirmAgreement = new Validation({
    field: formData.isConfirmAgreement,
    title: "Please accept terms & conditions and privacy policy"
  })
  .canNotBeZero()
  .getErrors();

  if (validationFullName.length) {
    errorsState = {
      ...errorsState,
      firstName: validationFullName[0],
    };
  }

  if (validationEmail.length) {
    errorsState = {
      ...errorsState,
      email: validationEmail[0],
    };
  }

  if (validationPassword.length) {
    errorsState = {
      ...errorsState,
      password: validationPassword[0],
    };
  }

  if (validationConfirmAgreement.length) {
    errorsState = {
      ...errorsState,
      isConfirmAgreement: validationConfirmAgreement[0],
    };
  }

  return errorsState;
};

export default onSignUpFormValid;
