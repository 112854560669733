import React, { useContext, useState } from 'react';
import './dropdown-menu.scss';

import { SearchFormContext } from '@shared/hooks';
import { Popover } from 'antd';
import { Text, Icon } from '@ui-kit';
import { SearchFormDefault } from '@features';


const DropdownMenu = (props) => {
    const { 
        children = () => {},
        onVisibleChange = () => {},
        isVisible = false,
        menu_list = [],
        placement = "bottom",
        classes = '',
        style = {},
    } = props;
    const searchContext = useContext(SearchFormContext);
    const [open, setOpen] = useState(isVisible);

    const hideMenu = (event) => {
        setOpen(false);
        onVisibleChange(event);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
        onVisibleChange();
    };

    const renderMenuContent = (item) => {
        if (item.component && item.icon) {
            return (
                <div className="d-flex ai-center menu__item-component" onClick={hideMenu}>
                    <Icon src={item.icon} className="menu__item-icon" />
                    <Text as="h6">{item.component}</Text>
                </div>
            );
        };
        
        if (item.component) {
            return (
                <div className="menu__item-component" onClick={hideMenu}>
                    {item.component}
                </div>
            );
        }

        if (item.isSearch) {
            return (
                <SearchFormDefault
                    onSubmit={(e)=> { hideMenu(); searchContext.onSubmitSearch(e)}}
                    onChange={searchContext.onChangeSearch}
                    placeholder="Search"
                    value={searchContext.searchValue}
                />
            );
        }
    };

    const items = menu_list.map((item, index) => {
        const itemClasses = ['menu__item'];

        if (item.isDivider) {
            itemClasses.push('divider');
        }

        if (item.component) {
            itemClasses.push('item__wrapper-component');
        }

        if (item.mobile) {
            itemClasses.push('mobile');
        }

        return (
            <div 
                key={`item-${index}`} 
                className={itemClasses.join(' ')}
            >
                {renderMenuContent(item, index)}
            </div>
        );
    });

    const content = (
        <div className='menu-media__content'>{items}</div>
    )

    return (
        <Popover
            overlayStyle={style}
            overlayClassName={classes}
            trigger="click"
            content={content}
            placement={placement}
            open={open}
            arrow={false}
            onOpenChange={handleOpenChange}
            destroyTooltipOnHide
        >
            <>
                {children}
            </>
        </Popover>
    );
};

export default DropdownMenu;
