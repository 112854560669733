import { Validation } from '@shared/lib/utils';

const onBillingFormValid = (formData) => {
  let errorsState = {};

  const validationAddress = new Validation({
    field: formData.address,
    title: 'Address',
  })
    .isRequired()
    .maxLenght(256)
    .isAddress()
    .getErrors();

  const validationPostCode = new Validation({
    field: formData.postCode,
    title: 'Postal code',
  })
    .isRequired()
    .maxLenght(10)
    .getErrors();

  let validationCompany, validationTax;

  if (formData.hasOwnProperty('company')) {
    validationCompany = new Validation({
      field: formData.company,
      title: 'Company',
    })
      .isRequired()
      .maxLenght(20)
      .getErrors();
  }

  if (formData.hasOwnProperty('tax')) {
    validationTax = new Validation({
      field: formData.tax,
      title: 'Tax',
    })
      .isRequired()
      .maxLenght(30)
      .isValidVAT(formData.country)
      .getErrors();
  }

  if (validationAddress.length) {
    errorsState = {
      ...errorsState,
      address: validationAddress[0],
    };
  }

  if (validationPostCode.length) {
    errorsState = {
      ...errorsState,
      postCode: validationPostCode[0],
    };
  }

  if (validationCompany?.length) {
    errorsState = {
      ...errorsState,
      company: validationCompany[0],
    };
  }

  if (validationTax?.length) {
    errorsState = {
      ...errorsState,
      tax: validationTax[0],
    };
  }

  return errorsState;
};

export default onBillingFormValid;
