import { v4 as uuidv4 } from 'uuid';


export class Notification {
    id;
    message;
    type;
    markType;
    ico;
  
    constructor(option) {
        this.id = option?.id || uuidv4();
        this.message = option.message;
        this.type = option.type;
        this.markType = option.markType;
        this.ico = option.ico;
    }
  }
  