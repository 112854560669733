import { MessageDTO } from './messageDTO';
import { BotMessageDTO } from './botMessageDTO';
import { MessageImgDTO } from './messageImgDTO';

class MessageFabrica {
  static list = {
    message: MessageDTO,
    img: MessageImgDTO,
    botMessage: BotMessageDTO,
  };

  create(message, type) {
    const Message = MessageFabrica.list[type] || MessageFabrica.list.message;
    const instance = new Message(message);

    instance.messageType = type || 'real';

    return instance;
  }
}

const messageInstance = new MessageFabrica();
export { messageInstance };
