import {
  SET_LOADING,
  SET_PRODUCT,
  SET_EDITED_PRODUCT,
  SET_CART_TO_ACTIVE_PRODUCT,
  SET_CART_TO_SIMILAR_PRODUCTS,
  SET_SIMILAR_IMAGES,
} from './product.constants';
import { SET_CART_PRODUCT } from '@entities/user/model/user.constants';

const initialState = {
  isLoading: true,
  product: {},
  similarProducts: [],
  editedProduct: {},
};

const productReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case SET_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case SET_PRODUCT: {
      return {
        ...state,
        product: payload,
      };
    }

    case SET_EDITED_PRODUCT: {
      return {
        ...state,
        editedProduct: payload,
      };
    }

    case SET_CART_TO_ACTIVE_PRODUCT: {
      if (!state.product.id || state.product.id !== payload.productId) {
        return state;
      }

      return {
        ...state,
        product: {
          ...state.product,
          inCart: payload.flag,
        },
      };
    }

    case SET_CART_PRODUCT: {
      const isProductInCart = payload.cart.find(
        (item) => item.id === state.product.id
      );

      return {
        ...state,
        product: {
          ...state.product,
          inCart: isProductInCart ? payload.flag : false,
        },
      };
    }

    case SET_SIMILAR_IMAGES: {
      return {
        ...state,
        similarProducts: payload,
      };
    }
    
    case SET_CART_TO_SIMILAR_PRODUCTS: {
      return {
        ...state,
        similarProducts: state.similarProducts.map((item) => {
          const isPresent = payload.cart.find(
            (product) => product.id === item.id
          );

          if (isPresent) {
            item.inCart = payload.flag;
          }

          return item;
        }),
      };
    }

    default:
      return state;
  }
};

export default productReducer;
