import './product-message.scss';
import { BROWSE_URL } from '@app/routes';

const ProductMessage = (product = {}, type = 'message') => {
    if (type === 'bot') {
        return (
            `Edit this illustration for $23/hour
            <div class="product-message">
                <a href="${process.env.REACT_APP_URL}${BROWSE_URL.pathname}/${product?.id}" target="_blank">
                    ${product?.title}<br/>
                    <img style="width: 100%;" src="${process.env.REACT_APP_PRODUCTS_URL}/${product?.pathToBrowse}" class="product-message__img" />
                </a>
            </div>`
        );
    };

    return (`
        Edit this illustration for $23/hour<br/>
        <a href="${process.env.REACT_APP_URL}${BROWSE_URL.pathname}/${product?.id}" target="_blank">
            ${product?.title}<br/>
            <img style="width: 100%;" src="${process.env.REACT_APP_PRODUCTS_URL}/${product?.pathToBrowse}" />
        </a>
        <br/>
    `);
};

export default ProductMessage;