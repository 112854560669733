import { SET_ALERT, REMOVE_ALERT } from './notifications.constants';

//ACTIONS
export const setAlertAC = (payload) => {
  return {
    type: SET_ALERT,
    payload,
  };
};

export const removeAlertAC = (payload) => {
  return {
    type: REMOVE_ALERT,
    payload,
  };
};
